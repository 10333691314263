<div style="text-align: right">Приложение №____</div>
<div style="text-align: right">к протоколу №___ общего собрания <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span> "Вернисаж" от "___" ______________ 2020 года.</div>
<h2>Таблица распределения голосов <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span> по вопросам, поставленным на голосование</h2>
<div>Общее количество голосов <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span> в многоквартирном доме по состоянию на {{data.endDate | date:'dd.MM.yyyy'}}г. - {{data.totalVotes.toFixed(2)}} голосов (метров квадратных), что составляет {{data.totalHomeVotes.toFixed(2)}} голосов от всех собсвенников МКД.</div>
<br>
<div>Всего приняли участие в голосовании <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span> на общем собрании {{data.voted.toFixed(2)}} голосов (метров квадратных), что составляет {{data.votedOfTotal.toFixed(2)}} всех <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span> МКД по состоянию на {{data.endDate | date:'dd.MM.yyyy'}}г.</div>
<h3>Распределение голосов <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span> по вопросам повестки дня:</h3>
<table cellspacing="0" cellpadding="4" border="1">
  <thead>
  <tr>
    <th rowspan="2" class="">Вопросы повестки дня общего собрания <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span></th>
    <th colspan="3">
      кв. м площади помещений <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span> (принявших участие в голосовании);<hr>
      % голосов <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span> от принявших участие в голосовании;<hr>
      % голосов <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span> от общего кол-ва <span *ngIf="data.isTsnMember">членов ТСН</span><span *ngIf="!data.isTsnMember">собственников</span> в МКД по состоянию на 28 июня 2020 года"
    </th>

  </tr>
  <tr>
    <th class="answer-col">
      ЗА
    </th>
    <th class="answer-col">
      ПРОТИВ
    </th>
    <th class="answer-col">
      ВОЗДЕРЖАЛИС
    </th>
  </tr>
  </thead>
  <tbody>
   <ng-container *ngFor="let q of data.questions; let i = index;">
     <tr>
       <th rowspan="3" class="agenda">{{q.number}}&nbsp;&nbsp;<b>{{q.agenda}}</b><br><i>{{q.decision}}</i></th>
       <td  class="summ">{{q.kvm.f.toFixed(2)}}</td>
       <td  class="summ">{{q.kvm.a.toFixed(2)}}</td>
       <td  class="summ">{{q.kvm.r.toFixed(2)}}</td>
     </tr>
     <tr>
       <td  class="summ">{{q.tookPart.f.toFixed(2)}}</td>
       <td  class="summ">{{q.tookPart.a.toFixed(2)}}</td>
       <td  class="summ">{{q.tookPart.r.toFixed(2)}}</td>
     </tr>
     <tr>
       <td  class="summ">{{q.totalNumber.f.toFixed(2)}}</td>
       <td  class="summ">{{q.totalNumber.a.toFixed(2)}}</td>
       <td  class="summ">{{q.totalNumber.r.toFixed(2)}}</td>
     </tr>
   </ng-container>
  </tbody>
</table>

