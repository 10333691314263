<h1 mat-dialog-title>
  Все собственники помещения {{data.cown.room__name}}
</h1>
<div mat-dialog-content>
<mat-spinner style="margin: auto;" *ngIf="!owns"></mat-spinner>
  <div style="max-height: 500px; overflow-y: auto;">
<table style="width: 100%;" *ngIf="owns">
  <tr style="font-weight: bold;">
    <td>
      {{data.human.fio}}
    </td>
    <td>
      {{data.cown.own_part_title}}
    </td>
    <td>
      {{data.cown.own_part_square}}
    </td>
  </tr>
  <tr *ngFor="let own of owns" >
    <td>
      {{own[0]}}
    </td>
    <td>
      {{own[1]}}
    </td>
    <td>
      {{own[2]}}
    </td>
  </tr>
</table>
  </div>
</div>
