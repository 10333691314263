import {Component, Inject, OnInit} from '@angular/core';
import {Human} from '../../models/human';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../main.service';
import {ShowFilesListComponent} from '../../forms/show-files-list/show-files-list.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-show-human-dialog',
  templateUrl: './show-human-dialog.component.html',
  styleUrls: ['./show-human-dialog.component.css']
})
export class ShowHumanDialogComponent implements OnInit {


  newNote = [0, 'note', '', '', '', []];
  // private human_id = 0;
  public phones = [];
  public mails = [];
  public links = [];
  public files = [];
  public notes = [];
  public human: any;
  currentLink = '';
  showHumanCopyBulletinUrl = false;
  showQr = false;
  editFile: any[];
  fio = '';

  get addFiles(): any[] {
    return this.newNote[5] as [];
  }

  set addFiles(value: any[]) {
    if (this.newNote != null) {
      this.newNote[5] = value;
    }
  }


  constructor(public dialogRef: MatDialogRef<DialogData>, private http: HttpClient, public main: MainService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialog: MatDialog, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.getResidentData();
  }

  uploadFile(files: FileList) {
    if (files.length > 0) {

      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        if (element.size > 30000000) {
          this.snackBar.open('Файл "' + element.name + '" больше 30 мегабайт;', 'Ok', {
            verticalPosition: 'top',
            panelClass: 'exception-panel',
            duration: 5000,
          });
          continue;
        }
        const reader = new FileReader();
        const ef = [0, element.name, []];
        reader.readAsDataURL(element);
        reader.onload = () => {
          ef[2] = [
            element.name,
            reader.result
          ];
          console.log(this.addFiles);
        };
        if (this.editFile == null){
          this.editFile = ef;
        }
        if (this.newNote[4] === ''){
          this.newNote[4] = element.name;
        }
        console.log(this.newNote);

        this.addFiles.push(ef);
      }

    }
  }

  getResidentData() {

    this.http.get(this.main.server + '/stuff/resident?' +
      '&human_id=' + this.data.human_id +
      '&is_actual_oss=' + true,
      this.main.httpOptions).subscribe(
      data => {
        this.human = data;
        for (const o of this.human.oss) {
          o.note_index = 0;
        }
        this.fio = this.human.fio;
        this.getNotes();

      },
      err => {

      }
    );
  }

  getNotes() {
    let lastId = 0;
    if (this.notes && this.notes.length > 0) {
      lastId = this.notes[0][0];
    }
    this.http.get(this.main.server + '/stuff/resident/get-notes?' +
      'after_id=' + lastId +
      '&human_id=' + this.data.human_id,
      this.main.httpOptions).subscribe(
      data => {
        const dataAny = data as any;
        const n = dataAny.notes;
        if (dataAny.phones) {
          this.phones = dataAny.phones.concat(this.phones);
        }
        if (dataAny.mails) {
          this.mails = dataAny.mails.concat(this.mails);
        }
        if (dataAny.links) {
          this.links = dataAny.links.concat(this.links);
        }
        if (dataAny.files) {
          this.files = dataAny.files.concat(this.files);
        }
        if (n) {
          this.notes = n.concat(this.notes);
        }
      },
      err => {
      }
    );
  }

  setStatus(voteId: number, statusKey: string, ossId: number, humanId: number, statusId: number) {
    this._setStatus(statusKey, Object.assign([], this.human.oss));
  }

  _setStatus(statusKey: string, ossList: any[]) {
    this.main.setStatus(ossList, statusKey, false, (data, oss) => {
      this.main.getItemVote(oss.vote_id, (voteData: any) => {
        if (voteData.result === 'ok') {
          // const oss_ind = this.human.oss.findIndex(x => x.oss_id == vote_data.oss.oss_id);
          // if (oss_ind >= 0) {
            oss.note_index = 0;
            Object.assign(oss, voteData.oss);
            delete oss.newNote;
         // }
            console.log(this.human);
        }
      });
      // this.getNotes();
    });

  }

  sendMessage(event, message: any[]) {
    event.stopPropagation();
    if (message) {
      const mess = {
        id: message[0],
        type: message[1],
        human_id: this.data.human_id,
        content: message[4],

      };
      if (message.length > 5) {
        // noinspection TypeScriptUnresolvedVariable
        // tslint:disable-next-line:no-string-literal
        mess['files'] = message[5];
      }
      this.http.post(this.main.server + '/stuff/resident/add-note', mess, this.main.httpOptions).subscribe(
        data => {
          const dataAny = data as any;
          const res = dataAny.result;
          const id = message[0];
          if (res === 'ok') {
            message[4] = '';
            message[0] = 0;
            this.addFiles = [];
            if (id > 0) {
              this.notes = [];
              this.snackBar.open('Заметка сохранена', 'Ok', {
                verticalPosition: 'top',
                panelClass: 'ok-panel',
                duration: 5000,
              });
            } else {
              this.snackBar.open('Заметка добавлена', 'Ok', {
                verticalPosition: 'top',
                panelClass: 'ok-panel',
                duration: 5000,
              });
            }
            this.getNotes();

          }


        },
        err => {

        }
      );
    } else {
      this.snackBar.open('Заметка пустая', 'Ok', {
        verticalPosition: 'top',
        panelClass: 'warning-panel',
        duration: 10000,
      });
    }
  }

  deleteAttachment(index, files) {
    const file = files[index];
    if (this.editFile === file) {
      this.editFile = null;
    }
    files.splice(index, 1);
  }


  dropOssFile(oss: any, event: any) {
    this.addOssNote(oss);
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      if (element.size > 30000000) {
        this.snackBar.open('Файл "' + element.name + '" больше 30 мегабайт;', 'Ok', {
          verticalPosition: 'top',
          panelClass: 'exception-panel',
          duration: 5000,
        });
        continue;
      }
      const reader = new FileReader();
      const ef = [0, element.name, ''];
      reader.readAsDataURL(element);
      reader.onload = () => {
        ef[2] = [
          element.name,
          reader.result
        ];
        console.log(this.addFiles);
      };
      // if(oss.newNote[5] == null){
      //   this.editFile = ef;
      // }
      if (oss.newNote[4] === '') {
        oss.newNote[4] = element.name;
      }

      console.log(this.newNote);
      oss.newNote[5].push(ef);
    }
  }

  getElementById(id: string) {
    return document.getElementById(id);
  }

  dropHumanFile(event) {
    if (event.length > 0) {

      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        if (element.size > 30000000) {
          this.snackBar.open('Файл "' + element.name + '" больше 30 мегабайт;', 'Ok', {
            verticalPosition: 'top',
            panelClass: 'exception-panel',
            duration: 5000,
          });
          continue;
        }
        const reader = new FileReader();
        const ef = [0, element.name, ''];
        reader.readAsDataURL(element);
        reader.onload = () => {
          ef[2] = [
            element.name,
            reader.result
          ];
          console.log(this.addFiles);
        };
        if (this.editFile == null) {
          this.editFile = ef;
        }
        if (this.newNote[4] === '') {
          this.newNote[4] = element.name;
        }

        console.log(this.newNote);
        this.addFiles.push(ef);
      }

    }
  }

  sendOssMessage(event: MouseEvent, oss: any) {
    this.main.addVoteNote(oss.vote_id, oss.newNote[4], oss.status_key, oss.newNote[5], (data) => {
      this.main.getItemVote(oss.vote_id, (voteData: any) => {
        if (voteData.result === 'ok') {
          const ossInd = this.human.oss.findIndex(x => x.oss_id === voteData.oss.oss_id);
          if (ossInd >= 0) {
            // this.human.oss[oss_ind].notes = vote_data.oss.notes;
            this.human.oss[ossInd].note_index = 0;
            Object.assign(this.human.oss[ossInd], voteData.oss);
            delete this.human.oss[ossInd].newNote;
          }
          console.log(this.human);
        }
      });
    });
  }


  addOssNote(oss: any) {
    if (!('newNote' in oss)) {
      oss.newNote = [0, 'note', '', '', '', []];
    }
  }

  showFilesIds(filesId: []) {
    this.dialog.open(ShowFilesListComponent, {
      data: {
        filesList: filesId
      }

    });
  }
  showFiles(files: any[]) {
    const filesId = files.map(x => x[0]);
    this.dialog.open(ShowFilesListComponent, {
      data: {
        filesList: filesId
      }

    });
  }

  // allowVote(oss: any) {
  //   if (oss) {
  //     return this.human.human_id !== 0 && (this.human.is_tsn_member || !oss.is_tsn_member)
  //   }
  //   // else {
  //   //   return this.info && this.info.oss_info && this.info.oss_info.current_oss
  //   && this.info.oss_info.current_oss.length > 0 && this.human.id !== 0 && (this.human.is_tsn_member
  //   || !this.info.oss_info.current_oss[0].is_tsn_member)
  //   // }
  // }
  //
  // generateBulletin(view: boolean, oss: any) {
  //   if (oss) {
  //     const osss = [oss.oss_guid];
  //     if (!this.human.human_guid){
  //       throw  new Error("no human guid")
  //     }
  //     const human_guid = this.human.human_guid;
  //     this.main.generateBulletin(human_guid, osss, view)
  //   }
  //   // else {
  //   //   const osss = oss.oss_info.current_oss.map(function(x){ return x.guid;});
  //   //   if (!this.human.guid){
  //   //     throw  new Error("no human guid")
  //   //   }
  //   //   const human_guid = this.human.guid;
  //   //   this.main.generateBulletin(human_guid, osss, view)
  //   // }
  // }
  //
  // generateOssLink(view: boolean, oss: any) {
  //   let osss = [];
  //   if (oss) {
  //     osss = [oss.oss_guid];
  //   }
  //   // else {
  //   //   osss = this.info.oss_info.current_oss.map(function(x) {
  //   //     return x.guid;
  //   //   });
  //   // }
  //   if (!this.human.human_guid){
  //     throw  new Error("no human guid")
  //   }
  //   const human_guid = this.human.human_guid;
  //
  //   const link = this.main.genarateOssLink(human_guid, osss, view)
  //   this.current_link = link;
  //   this.showHumanCopyBulletinUrl = true;
  //   const el = document.getElementById('copyToClipboardElement') as HTMLInputElement;
  //   el.value = link
  //   if (true || navigator.clipboard) {
  //     // поддержка имеется, включить соответствующую функцию проекта.
  //     navigator.clipboard.writeText(link)
  //       .then(() => {
  //         this._snackBar.open("Ссылка скопирована в буфер обмена", 'Ок', {
  //           verticalPosition:'top',
  //           panelClass:'ok-panel',
  //           duration: 5000,
  //         });
  //       })
  //       .catch(err => {
  //         this._snackBar.open("Не удалось скопировать ссылку в буфер обмена", 'Ок', {
  //           verticalPosition:'top',
  //           panelClass:'error-panel',
  //           duration: 5000,
  //         });
  //       });
  //   } else {
  //     // поддержки нет. Придётся пользоваться execCommand или не включать эту функцию.
  //
  //     this.main.iosCopyToClipboard(el);
  //     this._snackBar.open("Ссылка скопирована в буфер обмена", 'Ок', {
  //       verticalPosition:'top',
  //       panelClass:'ok-panel',
  //       duration: 5000,
  //     });
  //
  //   }
  // }
  //
  // navigateToOssLink() {
  //   window.open(this.current_link, '_blank');
  // }
}

export interface DialogData {
  entry: any;
  human_id: number;
}


export class HumanData {

  public human: Human;
  public phones: any[];
  public mails: any[];
  public links: any[];
  public actions: any[];
}
