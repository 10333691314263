import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BarcodeFormat} from '../../barcodeFormat';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {Subscription} from 'rxjs';
import {QrCodeReaderService} from './qr-code-reader.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';




@Component({
  selector: 'app-qrreader',
  templateUrl: './qrreader.component.html',
  styleUrls: ['./qrreader.component.sass']
})
export class QRReaderComponent implements OnInit, OnDestroy {

  get selectedCamera(): MediaDeviceInfo {

    return this._selectedCamera;
  }

  set selectedCamera(value: MediaDeviceInfo) {
    this._selectedCamera = value;
    // this.scannerElement.restart()
    if (this._selectedCamera) {
      localStorage.setItem('selectedScannerCameraDeviceId', this._selectedCamera.deviceId);
    } else {
      localStorage.removeItem('selectedScannerCameraDeviceId');
    }
  }


  constructor(private http: HttpClient,
              public main: MainService,
              private route: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private qrReader: QrCodeReaderService) {

  }

  @ViewChild('scanner', {static: true})
  scannerElement: ZXingScannerComponent;
  desiredDevice: any;
  QRScanerrAllowedFormats = [ BarcodeFormat.QR_CODE ];
  enabeQrScan = false;
  locked = '';
  humanGuid = '';
  ossGuid = '';
  bulletin: any;
  doverka = '';
  isDelegate = false;
  fio = 'Неизвестный';
  verbose: boolean;
  private voteStatus: any;
  isGood = false;
  loadStatus = 'loading';
  isBeginFinish: boolean;
  subscription: Subscription;
  getBulletinError = '';
  private _selectedCamera: MediaDeviceInfo = null;
  cameras: MediaDeviceInfo[] = [];
  enableFlash = false;
  allowedFlash = false;

  ngOnInit(): void {
    this.enabeQrScan = true;
  }
  camerasFoundHandler($event: MediaDeviceInfo[]) {
    this.cameras = $event;
    const deviceId = localStorage.getItem('selectedScannerCameraDeviceId');
    if (deviceId) {
      const val = this.cameras.find((x) => x.deviceId === deviceId);
      this._selectedCamera = val;

    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  onFileChange(event) {
    const file = event.target.files[0];
    this.subscription = this.qrReader.decode(file)
      .subscribe(decodedString => console.log(decodedString));
  }

  onTorchCompatible($event: boolean) {
    this.allowedFlash = $event;
  }

  camerasNotFoundHandler($event: any) {
    this.snackBar.open('Камера не найдена', 'Ок', {
      verticalPosition: 'top',
      panelClass: 'error-panel',
      duration: 5000,
    });
    this.enabeQrScan = false;
    this.loadStatus = 'no-camera';

  }

  scanSuccessHandler($event: string) {
    const qr = encodeURI($event);
    this.enabeQrScan = false;
    this.http.get<any>(this.main.server + '/staff/oss/parse-qr?qr=' + qr).subscribe(
      data => {
        this.humanGuid = data.HG;
        this.ossGuid = data.OG;
        this.getBulletin( );
        // this.router.navigate(
        //   ['/bulletin'],
        //   {
        //     relativeTo: this.route,
        //     queryParams: {human_guid: data.HG, oss_guid: data.OG},
        //     queryParamsHandling: 'merge', // remove to replace all query params by provided
        //   }).then(r => {});

      },
      error => {
        this.snackBar.open('Не удалось распарсить даныые QR-кода', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'error-panel',
          duration: 5000,
        });
        this.getBulletinError = error;
        this.loadStatus = 'not-found';
      }
    );
    console.log($event);

  }

  scanErrorHandler($event: Error) {
    console.log($event);

  }

  scanFailureHandler($event: any) {
    // console.log($event);

  }

  scanCompleteHandler($event: any) {

  }

  getBulletin( ){
    this.http.get<any>(this.main.server + '/tenant/bulletin?' +
      'h=' + this.humanGuid +
      '&o=' + this.ossGuid).subscribe(
      data => {
        this.bulletin = data;
        this.locked = data.lock;
        this.fio = data.human.fio;
        if (data.doverka)
        {
          this.isDelegate = true;
          this.doverka = data.doverka;
        }

        this.doverka = data.doverka;

        this.bulletin.qwsts_dict = {};
        let ind = 0;
        for ( const q of this.bulletin.qwsts){
          if (q.answer === 'd') {
            q.answer = '';
          }
          this.bulletin.qwsts_dict[q.id] = ind;
          ind++;
        }
        this.loadStatus = 'exists';
      },
      err => {
        this.getBulletinError = err;
        this.loadStatus = 'not-found';
      }
    );
  }
 clear(){
   this.bulletin = null;
   this.locked = '';
   this.fio = '';


   this.isDelegate = true;
   this.doverka = '';


   this.doverka = '';


   this.loadStatus = 'exists';
 }
  saveDoverka() {
    if (this.locked) { return; }
    const body = {
      humanGuid: this.humanGuid,
      ossGuid: this.ossGuid,
      doverka: this.isDelegate ? this.doverka : '',
    };
    this.http.put(this.main.server + '/tenant/bulletin/set-doverka', body).subscribe(
      data => {
        this.snackBar.open('Данные о представителе собственника сохранены', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'ok-panel',
          duration: 5000,
        });
      },
      error => {
        if (error.status === 403) {
          this.snackBar.open('Бюллетень заблокирован', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 10000,
          });
        } else {
          this.snackBar.open('Не удалось сохранить данные о представителе собственника ', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 100000,
          });
        }
      });
  }

  setAnswer(questionId, answer){
    if (this.locked) { return; }
    const body = {
      humanGuid: this.humanGuid,
      ossGuid: this.ossGuid,
      question_id: questionId,
      answer,
    };
    this.http.put<any>(this.main.server + '/tenant/bulletin/set-answer', body).subscribe(
      data => {
        this.snackBar.open(answer === '' ? 'Голос отменен' : 'Голос сохранен', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'ok-panel',
          duration: 5000,
        });
        answer = data.answer;
        console.log(this.bulletin);
        const qwst = this.bulletin.qwsts[this.bulletin.qwsts_dict[answer.question]];
        qwst.answer = answer.answer;

        const ind = this.bulletin.qwsts.findIndex( e => (e.type !== 'header' && ( e.answer === null || e.answer === '')));
        this.isGood = ind === -1 && (!this.isDelegate || this.doverka.length > 10);
        if (this.isGood && this.bulletin.vote_status.key !== 'fill'){
          this.setStatus( 'fill', 'Бюллетень заполнен');
        }
        else if (!this.isGood && this.bulletin.vote_status.key === 'fill'){
          this.setStatus('take', 'Бюллетень НЕ заполнен');
        }
        // let ind = this.bulletin.qwsts.findIndex(e => e.id === answer.question);
        // console.log(ind);
        // let qwst = this.bulletin.qwsts[ind];
        // qwst.answer = answer.answer;
        // qwst.user = answer.user;
      },
      error => {
        if (error.status === 403) {
          this.snackBar.open('Бюллетень заблокирован', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 10000,
          });
        }
      }
    );
  }

  setStatus(statusKey: string, description: string){
    if (this.locked) { return; }
    const body = {
      humanGuid: this.humanGuid,
      ossGuid: this.ossGuid,
      statusKey,
      description,
    };
    this.http.put<any>(this.main.server + '/tenant/bulletin/set-status', body).subscribe(
      data => {
        this.voteStatus = data.new_status;
        if (this.voteStatus === 'fill') {
          this.snackBar.open('Решение заполнено', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'ok-panel',
            duration: 3000,
          });
        }
      },
      error => {
        if (error.status === 403) {
          this.snackBar.open('Бюллетень заблокирован', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 10000,
          });
        }
      }
    );
  }

  closeBulletin() {
    const body = {
      humanGuid: this.humanGuid,
      ossGuid: this.ossGuid,
    };
    this.http.put<any>(this.main.server + '/staff/bulletin/lock-vote', body).subscribe(
      data => {
        this.locked = data.lock;
        this.snackBar.open('Бюллетень заблокирован', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'ok-panel',
          duration: 3000,
        });

      },
      err => {
        this.snackBar.open('Не удалось заблокировать бюллетень', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'error-panel',
          duration: 3000,
        });
      }
    );
  }

  unLockBulletin() {
    const body = {
      humanGuid: this.humanGuid,
      ossGuid: this.ossGuid,
    };
    this.http.put<any>(this.main.server + '/staff/bulletin/unlock-vote', body).subscribe(
      data => {
        this.locked = data.lock;
        this.snackBar.open('Бюллетень разблокирован', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'ok-panel',
          duration: 3000,
        });
      },
      err => {
        this.snackBar.open('Не удалось разблокировать бюллетень', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'error-panel',
          duration: 3000,
        });
      }
    );
  }
}
