<div style="">
  <!--  <h2 style="text-align: center; vertical-align: center"><mat-icon style="vertical-align: sub; font-size: 40px; margin-right: 15px;">assignment_ind</mat-icon>Информация о жителе</h2>-->
  <h2 *ngIf="human && human.fio && !editHumanFIO"><span>{{ human.fio}}</span>
    <button mat-icon-button (click)="editHumanFIO = true; humanFIO = human.fio">
      <mat-icon>edit</mat-icon>
    </button>
  </h2>
  <h2 *ngIf="human && human.fio && editHumanFIO">
    <mat-form-field class="full-width">
      <input matInput placeholder="Фамилия Имя Отчество" [(ngModel)]="humanFIO">
      <button mat-icon-button matSuffix color="primary" (click)="saveHumanFIO()">
        <mat-icon *ngIf="humanFIO !== human.fio && humanFIO.length > 5">save</mat-icon>
      </button>
      <button mat-icon-button matSuffix color="warn" (click)="editHumanFIO = false; humanFIO = ''">
        <mat-icon>cancel</mat-icon>
      </button>
      <mat-hint style="color: red;">Изменяйте ФИО именно этого жителя. Если речь идет о другом жителе, то воспользуйтесь
        пунктом меню "Добавить Жителя"!
      </mat-hint>
    </mat-form-field>
  </h2>
  <h3>Помещения</h3>
  <div style="width: 100%; overflow-x: auto;">
    <table class="search_result full_width" border="1" cellpadding="5">
      <tr>
        <th>Помещение</th>
        <th>S м2</th>
        <th>Доля</th>
        <th>s м2</th>
        <th>Голос</th>
        <th>Кад/Рег номер</th>
      </tr>
      <ng-container *ngIf="human && human.rooms">
        <tr *ngFor="let own of human.rooms">
          <td>
            {{own.home}}, пом. {{own.owner_title}}
          </td>
          <td>
            {{own.room_square.toFixed(2)}}
          </td>
          <td>
            {{own.own_part_title}}
          </td>
          <td>
            {{ own.own_part_square.toFixed(2) }}
          </td>
          <td>
            {{  (own.own_part_volume * 100).toFixed(2) + '%' }}
          </td>
          <td>
            {{ own.reg_number }}
          </td>
        </tr>
        <tr>
          <th colspan="3">
            <button mat-button color="primary" style="width: 100%;" [routerLink]="['/edit-human-owns']"
                    [queryParams]="{ human_id: human_id }">
              <mat-icon>business</mat-icon>
              <span>Редактировать собственности</span>
            </button>
          </th>

          <th>{{human.total_own_square.toFixed(2)}}</th>
          <th>{{ (human.total_volume * 100).toFixed(2) + '%' }}</th>
          <th></th>
        </tr>
      </ng-container>
    </table>
  </div>
  <div>
    <table *ngIf="human" class="full_width" border="0" cellpadding="5">
      <tr>
        <td colspan="3">
          <mat-chip-listbox>
            <mat-chip *ngFor="let phone of phones" (click)="main.navigateTo('tel:'+main.getPhone(phone[4]), '_blank')">
              <mat-icon>phone</mat-icon>
              <span [innerHTML]="main.formatPhone(phone[4])"></span>
              <button mat-icon-button (click)="$event.stopPropagation(); currentEditPhone = phone">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-chip>
            <mat-chip *ngFor="let mail of mails" (click)="main.navigateTo('mailto:'+main.getMail(mail[4]), '_blank')">
              <mat-icon>mail</mat-icon>
              <span [innerHTML]="main.formatMail(mail[4])"></span>
              <button mat-icon-button (click)="$event.stopPropagation(); currentEditMail = mail">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-chip>
            <mat-chip *ngFor="let link of links" (click)="main.navigateTo(main.getLink(link[4]), '_blank')"><span
              [innerHTML]="main.formatLink(link[4], false)"></span>
              <button mat-icon-button (click)="$event.stopPropagation(); currentEditLink = link">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-listbox>

          <mat-chip-listbox>
            <mat-chip *ngFor="let file of files;let i= index">
              <mat-icon>attach_file</mat-icon>
              {{ file[4] }}
              <button mat-icon-button color="primary" (click)="$event.stopPropagation(); editFile = file">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-listbox>
        </td>
      </tr>
      <tr>
        <td>
          <div style="display: inline-flex;" class="full-width">
            <mat-form-field class="full-width">
              <input matInput placeholder="Добавить номер" [(ngModel)]="currentEditPhone[4]">
              <button mat-icon-button matSuffix color="accent" (click)="sendMessage($event, currentEditPhone)">
                <mat-icon *ngIf="currentEditPhone[0]==0">add</mat-icon>
                <mat-icon *ngIf="currentEditPhone[0]!=0">save</mat-icon>
              </button>
              <mat-hint>Можно добавлять примечание</mat-hint>
            </mat-form-field>
          </div>
        </td>
        <td>
          <div style="display: inline-flex;" class="full-width">
            <mat-form-field class="full-width">
              <input matInput placeholder="Добавить почту" [(ngModel)]="currentEditMail[4]">
              <button mat-icon-button matSuffix color="accent" (click)="sendMessage($event, currentEditMail)">
                <mat-icon *ngIf="currentEditMail[0]==0">add</mat-icon>
                <mat-icon *ngIf="currentEditMail[0]!=0">save</mat-icon>
              </button>
              <mat-hint>Можно добавлять примечание</mat-hint>
            </mat-form-field>
          </div>
        </td>
        <td>
          <div style="display: inline-flex;" class="full-width">
            <mat-form-field class="full-width">
              <input matInput placeholder="Доавить соцсеть" [(ngModel)]="currentEditLink[4]">
              <button mat-icon-button matSuffix color="accent" (click)="sendMessage($event,  currentEditLink)">
                <mat-icon *ngIf="currentEditLink[0]==0">add</mat-icon>
                <mat-icon *ngIf="currentEditLink[0]!=0">save</mat-icon>
              </button>
              <mat-hint>Можно добавлять примечание</mat-hint>
            </mat-form-field>
          </div>
        </td>
      </tr>

    </table>

  </div>
  <ng-container *ngIf="human">
    <mat-slide-toggle
      class=""
      color="Primary"
      [(ngModel)]="human.is_tsn_member"
      (change)="isTsnMemberChanged($event)"
      name="human-is-tsn-memver">
      Является членом ТСН
    </mat-slide-toggle>
  </ng-container>

  <!--  resident/set-tsn-member-status-->

  <div *ngIf="human && human.oss && human.oss.length > 0">
    <h3>Текущие собрания</h3>
    <table class="search_result full_width" style=" background: #c8ffd6;" border="1" cellpadding="5">
      <tr>
        <th style="width: 100px;">ОСС</th>
        <!--      <th style="width: 190px;">Статус</th>-->
        <th>Заметки</th>
        <th style="width: 190px;">Действия</th>
      </tr>
      <ng-container>
        <tr *ngFor="let oss of human.oss">
          <td style="width: 100px;">
            <mat-icon *ngIf="oss.oss_is_tsn_member" style="color: gold;">account_box</mat-icon>
            {{oss.oss_title}}<br/>
            {{getDate(oss.oss_start_time)}} - {{getDate(oss.oss_end_time)}}<br/>
            <mat-icon style="font-size: 22px;" [style.color]="oss.status_icon_color">{{oss.status_icon}}</mat-icon>
            <br/>
            {{oss.status_title}}

          </td>
          <!--      <td >        {{oss.status_title}}      </td>-->
          <td style="vertical-align: sub;">
            <div style="max-height: 300px; overflow-y: auto;">
              <div *ngFor="let note of oss.notes">
                <span class="note-time">{{getDate(note.date)}}</span> <span class="note-status"
                                                                            [style.color]="note.status__icon_color"> <mat-icon
                style="font-size: 22px;">{{note.status__icon}}</mat-icon>
                {{note.status__title}}</span><br/>
                <span class="note-text">{{note.text}}</span>
                <hr/>

              </div>
            </div>
          </td>

          <td>
            <div>
              <div *ngFor="let status of main.statuses">
                <!--            <button *ngIf="main.allowStatus(status.key, oss.status_key)"  mat-raised-button style="width: 190px; background: #ffffff59;" class="text-left"-->
                <!--                    [style.color]="status.icon_color" (click)="setStatus(oss.vote_id, status.key, oss.oss_id, human.human_id, status.id)">-->
                <!--              <span style="margin-left: 0;">-->
                <!--&lt;!&ndash;                <mat-icon class="vertical-middle">{{status.icon}}</mat-icon> &ndash;&gt;-->
                <!--                <span class="vertical-middle"> {{status.button_title}}</span></span>-->
                <!--            </button>-->
              </div>
            </div>
          </td>

        </tr>
      </ng-container>

    </table>
  </div>
  <div *ngIf="human && human.old_oss && human.old_oss.length > 0">
    <h3>Учавствовал в собранияx</h3>
    <table class="search_result full_width" style="border-collapse: collapse; background: #cac9ff;" border="1"
           cellpadding="5">
      <tr>
        <th style="width: 100px;">ОСС</th>
        <!--      <th style="width: 190px;">Статус</th>-->
        <th>Заметки</th>

      </tr>
      <ng-container>
        <tr *ngFor="let oss of human.old_oss">
          <td style="width: 100px;">
            <mat-icon *ngIf="oss.oss_is_tsn_member" style="color: gold;">account_box</mat-icon>
            {{oss.oss_title}}<br/>
            {{getDate(oss.oss_start_time)}} - {{getDate(oss.oss_end_time)}}<br/>
            <mat-icon style="font-size: 22px;" [style.color]="oss.status_icon_color">{{oss.status_icon}}</mat-icon>
            <br/>
            {{oss.status_title}}
          </td>
          <td style="vertical-align: sub;">
            <div style="max-height: 300px; overflow-y: auto;">
              <div *ngFor="let note of oss.notes">
                <span class="note-time">{{getDate(note.date)}}</span> <span class="note-status"
                                                                            [style.color]="note.status__icon_color"> <mat-icon
                style="font-size: 22px;">{{note.status__icon}}</mat-icon>
                {{note.status__title}}</span><br/>
                <span class="note-text">{{note.text}}</span>
                <hr/>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>

    </table>
  </div>

  <div *ngIf="human && notes" class="notes-container">
    <div class="new-note-container">
      <div class="full_width" addUploadDragDrop (onFileDropped)="uploadFile($event)">
        <table class="full_width">
          <tr>
            <td>
              <mat-form-field class="full-width" style="margin-bottom: -30px; min-height: 70px; margin-top: -35px;">
                <textarea matInput style="min-height: 70px;" [(ngModel)]="newNote[4]" name="new-note-editor"></textarea>
              </mat-form-field>
            </td>
            <td width="1px">
              <button mat-icon-button color="primary" (click)="sendMessage($event, newNote)">
                <mat-icon *ngIf="newNote[0]==0">send</mat-icon>
                <mat-icon *ngIf="newNote[0]!=0">save</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="fileInput.click()">
                <mat-icon>attach_file</mat-icon>
              </button>
              <input hidden type="file" multiple #fileInput (change)="uploadFile($event.target.files)">
            </td>
          </tr>
        </table>
        <ng-container *ngIf="addFiles && addFiles.length>0">
          <div *ngFor="let editFile of addFiles;let i= index ">
            <!--          <a *ngIf="editFile[0] != 0" routerLink="." (click)="main.downloadFile(editFile)"  >{{editFile[1]}}</a>-->
            <span *ngIf="editFile[0] == 0">
  <!--             *ngIf="editFile.length>=2 && editFile[2] && editFile[2].length>0"-->
              <a routerLink=".">{{editFile[1]}}</a>
              <button mat-icon-button color="accent" (click)="$event.stopPropagation(); deleteAttachment(i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </span>
            <mat-icon *ngIf="editFile.length > 2 && editFile[2].length == 0">play_for_work</mat-icon>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="note" *ngFor="let n of notes" (click)="$event.stopPropagation(); newNote = n">
      <div>
        <!--        <button mat-icon-button class="note-edit-button"><mat-icon>edit</mat-icon></button>-->
        <span class="note-user">{{n[2]}}</span><span class="note-time">{{getDateTime(n[3])}}</span><br/><span
        class="note-content">{{n[4]}}</span>
        <div *ngIf="n[5] && n[5].length > 0" class="files-container">
          <ul>
            <li *ngFor="let file of n[5]">
              <button mat-stroked-button routerLink="."
                      (click)="$event.stopPropagation(); main.downloadFile(file)">{{file[1]}}</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>
