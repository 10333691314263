import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

import {MainService} from '../main.service';
import {UserService} from '../user.service';
//import {AuthenticationService} from '../_services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: UserService, public main: MainService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    console.log('JwtInterceptor.intercept');
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = !!currentUser && !!currentUser.token;
    const isApiUrl = request.url.startsWith(this.main.server);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `JWT ${currentUser.token}`
        }
      });
    }

    return next.handle(request);
  }
}
