<header class="header header_sub" >
  <mat-toolbar>
    <button class="home-button" style="display: inline !important;" mat-button (click)="openBurger($event)" >
      <div class="button-content" style="">
        <mat-icon style="">{{main.currentMatIcon}}</mat-icon>
        <span style="overflow-x: hidden;" [innerText]="main.currentHeader" ></span>
        <!--    </button>-->
        <span class="example-spacer"></span>
        <!--    <button mat-icon-button class="hamburger" id="burger" >-->
        <mat-icon>more_vert</mat-icon>
      </div>

    </button>
  </mat-toolbar>
<!--  <div class="header__inner">-->
<!--    <div class="header_top">-->
<!--      <div class="left">-->
<!--        <div class="logo">-->

<!--&lt;!&ndash;              <a routerLink="/" class="tr_link"><img src="assets/img/logo.png" alt=""></a>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="right">-->
<!--        <div class="burger" >-->
<!--         -->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</header>



<!--<header class="header header_sub" >-->
<!--  <form style="z-index: 10000;">-->
<!--  <div class="header__inner">-->
<!--    <div class="header_top" >-->


<!--        <div  class="main-menu">-->


<!--        </div>-->


<!--      <button style="font-size: xx-large" mat-menu-item [routerLink]="['/home']" >-->
<!--        <mat-icon style="    color: black;    font-size: xx-large;    vertical-align: text-top;">{{main.currentMatIcon}}</mat-icon>-->
<!--        <span style="font-size: xx-large" [innerText]="main.currentHeader"></span>-->
<!--      </button>-->
<!--        <div   class="login-block" >-->
<!--          <button mat-icon-button [matMenuTriggerFor]="menu" style="z-index: 1000;" aria-label="Example icon-button with a menu">-->
<!--            <mat-icon>more_vert</mat-icon>-->
<!--          </button>-->
<!--          <mat-menu #menu="matMenu">-->
<!--            <button mat-menu-item [routerLink]="['/home']" >-->
<!--              <mat-icon>home</mat-icon>-->
<!--              <span>Главная</span>-->
<!--            </button>-->

<!--            <button mat-menu-item [routerLink]="['/vote']" >-->
<!--              <mat-icon>search</mat-icon>-->
<!--              <span>Поиск жителя</span>-->
<!--            </button>-->
<!--            <button mat-menu-item [routerLink]="['/add-human']" >-->
<!--              <mat-icon>person_add</mat-icon>-->
<!--              <span>Добавить жителя</span>-->
<!--            </button>-->
<!--&lt;!&ndash;            <button *ngIf="main.selectedOss" mat-menu-item [routerLink]="['/oss']"  >&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-icon>how_to_vote</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;              <span>Редактировать ОСС</span>&ndash;&gt;-->
<!--&lt;!&ndash;            </button>&ndash;&gt;-->
<!--            <button mat-menu-item [routerLink]="['/oss']" >-->
<!--              <mat-icon>how_to_vote</mat-icon>-->
<!--              <span>Создать ОСС</span>-->
<!--            </button>-->
<!--            <button mat-menu-item [routerLink]="['/bulletin']" >-->
<!--              <mat-icon>ballot</mat-icon>-->
<!--              <span>Заполнить бюллетень</span>-->
<!--            </button>-->
<!--            <button mat-menu-item [routerLink]="['/edit-human-owns']" >-->
<!--              <mat-icon>business</mat-icon>-->
<!--              <span>Редактировать собственности</span>-->
<!--            </button>-->
<!--            <div style="margin: 10px;" *ngIf="userService && userService.currentUserValue && userService.currentUserValue.username">-->
<!--              <mat-icon>account_circle</mat-icon><span style=""> <b>{{userService.currentUserValue.username}}</b> </span>-->
<!--            </div>-->
<!--            <button mat-menu-item class="" (click)="userService.logout()">-->
<!--              <mat-icon>exit_to_app</mat-icon>-->
<!--              <span>Выход</span>-->
<!--            </button>-->
<!--          </mat-menu>-->

<!--        </div>-->
<!--        </div>-->


<!--  </div>-->
<!--  </form>-->
<!--</header>-->
