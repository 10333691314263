import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../main.service';
import {DialogData} from '../../residents/show-human-dialog/show-human-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-show-files-list',
  templateUrl: './show-files-list.component.html',
  styleUrls: ['./show-files-list.component.css']
})
export class ShowFilesListComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogData>, private http: HttpClient, public main: MainService,
              @Inject(MAT_DIALOG_DATA) public data: ShowFilesListData) {
  }
  files = [];
  ngOnInit() {
    this.getFilesList(this.data.filesList);
  }
  getFilesList(filesList){
    const arg = filesList.join('|');
    this.http.get(this.main.server + '/stuff/get-files-list?' +
      'ids=' + arg,
      this.main.httpOptions).subscribe(
      data => {
        const anyData = data as any;

        if (anyData.result === 'ok') {
          this.files = anyData.files;
        }
      },
      err => {
      }
    );
  }
}

export  class ShowFilesListData {
  filesList: [];
}
