<div class="main-container"  addUploadDragDrop (onFileDropped)="uploadFile($event)">
  <h1 *ngIf="title">{{title}}</h1>
  <mat-accordion>
      <mat-expansion-panel class="item-file"  *ngFor="let file of files" [(expanded)]="file.expanded">
        <mat-expansion-panel-header>
          <mat-panel-title class="text-ellipsis">
            <span ><mat-icon *ngIf="!readOnly && file.file.is_public">people</mat-icon><mat-icon *ngIf="!readOnly && !file.file.is_public">lock</mat-icon><b style="vertical-align: super">{{file.file.name}}</b></span>
          </mat-panel-title>
          <mat-panel-description class="text-ellipsis">
            {{file.file.description}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <div  *ngIf="!readOnly">
            <div><mat-icon>person</mat-icon><span style="vertical-align: super">{{file.file.user}}</span></div>
            <mat-form-field class="file-name" >
              <mat-label>Имя</mat-label>
              <input matInput placeholder="имя файла" [(ngModel)]="file.file.name" (ngModelChange)="fileNameChange(file)">
              <mat-hint>Расширение файла поменять не возможно</mat-hint>
            </mat-form-field>
            <br>
            <mat-form-field class="file-description">
              <mat-label>Описание</mat-label>
              <textarea matInput placeholder="описание файла" [(ngModel)]="file.file.description" (ngModelChange)="fileDescriptionChange(file)"></textarea>
            </mat-form-field>
            <br>
            <mat-slide-toggle (change)="fileIsPublicChange(file)"
              class="file-is-public"
              color="warn" [(ngModel)]="file.file.is_public">
              Общедоступный файл
            </mat-slide-toggle>
          </div>
          <div *ngIf="readOnly && file.file.description">
            {{file.file.description}}
          </div>
          <button *ngIf="!readOnly" mat-raised-button color="primary" (click)="changeFile(file)" [disabled]="!file.changed">
            <mat-icon>save</mat-icon>
          </button>
          <button mat-raised-button color="primary" *ngIf="['.jpg', '.png', '.gif', '.tiff', '.jpeg', '.png', '.pdf'].indexOf(file.file.type.toLowerCase()) > -1"
                  title="Просмотр файла"
                  [disabled]="!file.file.guid || file.imgContent || file.pdfContent" (click)="downloadFile(file, showImage)">
            <mat-icon>preview</mat-icon>
          </button>
          <button mat-raised-button color="primary" [disabled]="!file.file.guid" (click)="downloadFile(file, browserDownload)">
            <mat-icon>cloud_download</mat-icon>
          </button>
          <button *ngIf="!readOnly" mat-raised-button color="warn" [disabled]="!file.file.guid" (click)="askToDelete(file)">
            <mat-icon>delete_forever</mat-icon>
          </button>
          <button mat-raised-button color="warn" style="margin-left: 30px" *ngIf="!readOnly && file.askDelete" (click)="deleteFile(file)">
            Удалить навсегда!
          </button>
          <br>
          <img *ngIf="file.imgContent"
               [src]="file.imgContent">
          <pdf-viewer *ngIf="file.pdfContent"
                      [src]="file.pdfContent"
                      [show-borders]="true"
                      [render-text]="true"
                      style="display: block;"></pdf-viewer>
          <br>

        </div>
      </mat-expansion-panel>
  </mat-accordion>
  <mat-progress-bar *ngIf="updateProcess"  mode="query" class="full-width"></mat-progress-bar>
  <mat-progress-bar *ngIf="fileDeleting" color="accent"  mode="query" class="full-width"></mat-progress-bar>
  <mat-progress-bar *ngIf="fileSaving" color="primary"  mode="query" class="full-width"></mat-progress-bar>

<br>
  <button mat-raised-button color="primary" (click)="refreshFiles()">
    <mat-icon>refresh</mat-icon>
    обновить список
  </button>



    <button mat-raised-button color="primary" (click)="fileInput.click()"  *ngIf="!readOnly">
      <mat-icon>attach_file</mat-icon> добавить файл
    </button>
    <input hidden type="file" multiple #fileInput (change)="uploadFile($event.target.files)">
  <h2 *ngIf="uploadFiles && uploadFiles.length > 0" >Отправка файлов:</h2>
  <div class="upload-files" *ngFor="let file of uploadFiles">
    <div class="upload-name"><mat-icon color="primary" >attach_file</mat-icon><span style="vertical-align: super">{{file.file.name}}</span></div>
    <div class="upload-status">{{file.status}}</div>
    <mat-progress-bar *ngIf="file.progress!==0" class="upload-progress-bar"
                      [mode]="file.progress===0.00001?'indeterminate':'determinate'"
                      [value]="file.progress"></mat-progress-bar>
  </div>

  <h2 *ngIf="downloadFiles && downloadFiles.length > 0" >Загрузки файлов:</h2>
  <div class="upload-files" *ngFor="let file of downloadFiles">
    <div class="upload-name"><mat-icon color="primary" >cloud_download</mat-icon><span style="vertical-align: super">{{file.file.name}}</span></div>
    <div class="upload-status">{{file.status}}</div>
    <mat-progress-bar *ngIf="file.progress!==0" class="upload-progress-bar"
                      [mode]="file.progress===0.00001?'indeterminate':'determinate'"
                      [value]="file.progress"></mat-progress-bar>
  </div>

</div>
