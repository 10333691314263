<div class="dialog">
  <h2 mat-dialog-title>
    Файлы
  </h2>
  <div mat-dialog-content style="padding: 10px 24px 0 24px;">

      <div class="full-width" *ngFor="let file of files" ><button mat-stroked-button (click)="$event.stopPropagation(); main.downloadFile([file.id, file.name])"  >
        {{file.name}}</button>
      </div>

  </div>
</div>
