import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-add-human',
  templateUrl: './add-human.component.html',
  styleUrls: ['./add-human.component.css']
})
export class AddHumanComponent implements OnInit {

  data = new Data();
  error: string;
  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute,
              public dialog: MatDialog, private router: Router) {
    this.main.currentMatIcon = 'person_add';
    this.main.currentHeader = 'Добавление жителя';

  }

  ngOnInit() {
     this.data.fio = this.route.snapshot.queryParamMap.get('fio');
     if (this.data.fio){
       this.createHuman();
     }
  }


  createHuman() {
    this.http.post(this.main.server + '/stuff/add-new-human', this.data, this.main.httpOptions).subscribe(
      data => {
        const anyData = data as any;

        if (anyData.result === 'exists'){
          this.error = anyData.error;
        }
        else{
          this.data = (Object.assign(new Data(), anyData.human) as Data);
          this.error = '';
        }

      },
      err => {

      }
    );
  }
}



export class Data{
  human_id = 0;
  fio = '';
  key = '';

}
