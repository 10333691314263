import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-get-description-dialog',
  templateUrl: './get-description-dialog.component.html',
  styleUrls: ['./get-description-dialog.component.css']
})
export class GetDescriptionDialogComponent implements OnInit {



  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<GetDescriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GetDescriptionDialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}


export class GetDescriptionDialogData {
  title: string;
  description: string;
  buttons: string;
  result: string;
  isEditBox: boolean;
  editValue: string;
}


//
// @Component({
//   selector: 'dialog-overview-example-dialog',
//   templateUrl: 'dialog-overview-example-dialog-overview-example-dialog.html',
// })
// export class DialogOverviewExampleDialog {
//
//
//
// }
