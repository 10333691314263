import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../main.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-field-votes',
  templateUrl: './field-votes.component.html',
  styleUrls: ['./field-votes.component.css']
})
export class FieldVotesComponent implements OnInit {

  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute, private router: Router) {
    this.main.currentMatIcon = "grid_on";
    this.main.currentHeader = "Таблица Результатов";
  }
  fetchedColumns = [];
  fetchedRows = [];
  colnum = 0;
  baseColumns =  ['owner'];
  displayedColumns =
    ['number', 'owner', 'square', 'volume'];
  dataSource: answerRow[] = [];
  oss_id = 0;
  home_square = 1;
  total_votes = 1;
  votes_f = [];
  votes_a = [];
  votes_r = [];
  votes_d = [];
  votes_summ = [];
  ngOnInit() {
    this.oss_id = parseInt(this.route.snapshot.queryParamMap.get("oss_id"), 10)
    //this.getColumns();
    this.getRows();
  }

  // getColumns(){
  //   this.fetchedColumns = ['1', '1.1', '1.2', '2', '2.1', '2.2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'];
  //   this.displayedColumns = this.baseColumns.concat(this.fetchedColumns)
  //
  // }
  sortedBy = 'fio';
  inPercentage = true;
  lockedTenant = true;
  lockedAdmin = true;
  unLocked = true;
  orderBy(field: string) {
    this.sortedBy = field;
    switch (field) {
      case 'rooms':
        this.dataSource = this.dataSource.sort((a, b) => a.rooms > b.rooms ? 1 : (a.rooms === b.rooms ? 0 : -1));
        break;
      case 'square':
        this.dataSource = this.dataSource.sort((a, b) => a.square > b.square ? 1 : (a.square === b.square ? 0 : -1));
        break;
      case 'fio':
      default:
        this.dataSource = this.dataSource.sort((a, b) => a.fio > b.fio ? 1 : (a.fio === b.fio ? 0 : -1));
        break;
    }
  }

  getRows(){

    this.http.get(this.main.server +
      '/stuff/field-votes?' +
      'oss_id=' + this.oss_id,
      this.main.httpOptions).subscribe(
      data => {
        this.fetchedColumns = data['questions'];
        this.dataSource = data['answers'];
        this.votes_f = data['votes_f'];
        this.votes_a = data['votes_a'];
        this.votes_r = data['votes_r'];
        this.votes_d = data['votes_d'];
        this.votes_summ = data['votes_summ'];
        this.displayedColumns = this.baseColumns.concat(this.fetchedColumns);
        this.home_square = data['home_square'];
        this.total_votes = data['total_votes'];

      },
      err => {

      }
    )

  }
}

export interface answerRow {
  id: number;
  fio: string;
  locked: string;
  key: string;
  rooms: string;
  square: number;
  answers: string;
}
