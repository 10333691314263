<!--<div class="" style="height: 100vh;" >-->
<!--  <app-header></app-header>-->

<!--  <div class="box">-->
<!--    <div style="flex-grow: 1;">-->
<!--      <router-outlet  (activate)="activated()"></router-outlet>-->
<!--    </div>-->
<!--  </div>-->
<!--&lt;!&ndash;  <app-footer></app-footer>&ndash;&gt;-->
<!--&lt;!&ndash;  <div class="right" >&ndash;&gt;-->
<!--&lt;!&ndash;    <app-login ></app-login>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->

<!--  <app-burger-menu></app-burger-menu>-->
<!--</div>-->

<div class="page-container page-main" [class.menu_opened]="main.IsMenuOpened" style="height: 100vh;" >
  <app-header></app-header>

  <div class="main-container" style="">
    <router-outlet (activate)="activated()"></router-outlet>
  </div>
  <app-footer></app-footer>

  <app-burger-menu></app-burger-menu>
</div>
