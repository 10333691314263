import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MainService} from '../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {

  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute,
              public dialog: MatDialog, private router: Router, private snackBar: MatSnackBar) { }

  links: any[];
  link: string;
  url: string;

  ngOnInit() {
    this.getLinks();
    this.genKey();
  }

  getLinks(){
    this.http.get<any[]>(this.main.server + '/stuff/links',
      {headers: new HttpHeaders({'Content-Type': 'application/json'}), observe: 'body'}).subscribe(
      data => {
        this.links = data;
      },
      err => {

      }
    );
  }

  genKey(){
    const url = this.main.server + '/stuff/links/key';
    console.log(url);
    this.http.get<any[]>(url, {headers: new HttpHeaders({'Content-Type': 'application/json'}), observe: 'body'}).subscribe(
      data => {
        const adata = data as any;
        this.link = adata.key;
      },
      err => {

      }
    );
  }


  createLink(){
    const sdata = {
      key: this.link,
      url: this.url
    };
    this.http.post(this.main.server + '/stuff/links/create', sdata, this.main.httpOptions).subscribe(
      data => {
        const adata = data as any;
        if (adata.result === 'ok'){
          this.snackBar.open('Ссылка создана', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'ok-panel',
            duration: 5000,
          });
        }
        this.getLinks();
        this.genKey();

      },
      err => {

      }
    );


  }

}
