import {Component, Inject, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../main.service';

import {DialogData} from '../../residents/show-human-dialog/show-human-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-vote-menu',
  templateUrl: './vote-menu.component.html',
  styleUrls: ['./vote-menu.component.css']
})
export class VoteMenuComponent implements OnInit {

  get oss(): any {
    return this._oss;
  }
  @Input('oss')
  set oss(value: any) {
    this._oss = value;
    if (this._human && this._oss) {
      this.allowVote = this._human.human_id !== 0 && (this._human.is_tsn_member || !this._oss.is_tsn_member);
    } else{
      this.allowVote = false;
    }
  }
  get human(): any {
    return this._human;
  }
  @Input('human')
  set human(value: any) {
    this._human = value;
    if (this._human && this._oss) {
      this.allowVote = this._human.human_id !== 0 && (this._human.is_tsn_member || !this._oss.is_tsn_member);
    } else{
      this.allowVote = false;
    }
  }
  currentLink = '';
  showHumanCopyBulletinUrl = false;
  showQr = false;
  allowVote = false;
  showQrScan = false;

  private _human: any;
  private _oss: any;
  constructor(private http: HttpClient, public main: MainService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }


  generateBulletin(view: boolean) {
    // if (!this._human) return;
    if (this._oss && this._human) {
      let osss = [];
      if (this._oss.oss_guid) {
        osss = [this._oss.oss_guid];
      } else if (this._oss.guid) {
        osss = [this._oss.guid];
      } else { return; }
      if (!this._human.human_guid){
        throw  new Error('no human guid');
      }
      const human_guid = this._human.human_guid;
      this.main.generateBulletin(human_guid, osss, view);
    }
    // else {
    //   const osss = oss.oss_info.current_oss.map(function(x){ return x.guid;});
    //   if (!this.human.guid){
    //     throw  new Error("no human guid")
    //   }
    //   const human_guid = this.human.guid;
    //   this.main.generateBulletin(human_guid, osss, view)
    // }
  }

  generateOssLink(view: boolean) {
    if (!this._human) { return; }
    let osss = [];
    if (this._oss.oss_guid) {
      osss = [this._oss.oss_guid];
    } else if (this._oss.guid) {
      osss = [this._oss.guid];
    } else { return; }
    // else {
    //   osss = this.info.oss_info.current_oss.map(function(x) {
    //     return x.guid;
    //   });
    // }
    if (!this._human.human_guid){
      throw  new Error('no human guid');
    }
    const human_guid = this._human.human_guid;

    const link = this.main.genarateOssLink(human_guid, osss, view);
    this.currentLink = link;
    this.showHumanCopyBulletinUrl = true;

  }

  navigateToOssLink() {
    window.open(this.currentLink, '_blank');
  }

  copylinkToClipboard() {
    const el = document.getElementById('copyToClipboardElement') as HTMLInputElement;
    el.value = this.currentLink;
    if (true || navigator.clipboard) {
      // поддержка имеется, включить соответствующую функцию проекта.
      navigator.clipboard.writeText(this.currentLink)
        .then(() => {
          this.snackBar.open('Ссылка скопирована в буфер обмена', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'ok-panel',
            duration: 5000,
          });
        })
        .catch(err => {
          this.snackBar.open('Не удалось скопировать ссылку в буфер обмена', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 5000,
          });
        });
    } else {
      // поддержки нет. Придётся пользоваться execCommand или не включать эту функцию.

      this.main.iosCopyToClipboard(el);
      this.snackBar.open('Ссылка скопирована в буфер обмена', 'Ок', {
        verticalPosition: 'top',
        panelClass: 'ok-panel',
        duration: 5000,
      });

    }
  }

  scanBarcode() {
    this.showQrScan = true;

  }
}
