import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {ResidentsComponent} from './residents/residents.component';
import {UserService} from './user.service';
import {MainService} from './main.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {RouterModule} from '@angular/router';
import {HeaderComponent} from './blocks/header/header.component';
import {FooterComponent} from './blocks/footer/footer.component';
import {BurgerMenuComponent} from './blocks/burger-menu/burger-menu.component';
import {MatRippleModule} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ResidentComponent} from './resident/resident.component';
import {OssComponent} from './oss/oss.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {BulletinComponent} from './bulletin/bulletin.component';
import {GetDescriptionDialogComponent} from './forms/get-description-dialog/get-description-dialog.component';
import {EditHumanOwnsComponent} from './edit-human-owns/edit-human-owns.component';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {AuthGuard} from './_guards/auth.guard';
// import {LoginComponent} from './login/login.component';
import {LoginComponent} from './forms/login/login.component';
import {MatBadgeModule} from '@angular/material/badge';
import {OtherOwnersDialogComponent} from './edit-human-owns/other-owners-dialog/other-owners-dialog.component';
import {HomeComponent} from './home/home.component';
import {AddHumanComponent} from './add-human/add-human.component';
import {FieldVotesComponent} from './field-votes/field-votes.component';
import {RoomComponent} from './room/room.component';
import {AddUploadFileDirective} from './directives/upload-file/add-upload-file.directive';
import {ShowHumanDialogComponent} from './residents/show-human-dialog/show-human-dialog.component';
import {LinksComponent} from './links/links.component';
import {ShowFilesListComponent} from './forms/show-files-list/show-files-list.component';
import {VotesComponent} from './evote/votes/votes.component';
import {QuestionsComponent} from './evote/questions/questions.component';
import {MatSortModule} from '@angular/material/sort';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {MatExpansionModule} from '@angular/material/expansion';
import {BulletinLinkComponent} from './home/bulletin-link/bulletin-link.component';
import {QRCodeModule} from 'angularx-qrcode';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {QRReaderComponent} from './bulletin/qrreader/qrreader.component';
import {DistributionOfVotesComponent} from './distribution-of-votes/distribution-of-votes.component';
import {DocumentGeneratorComponent} from './document-generator/document-generator.component';
import {VoteMenuComponent} from './forms/vote-menu/vote-menu.component';
import {FilesComponent} from './forms/files/files.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatChipsModule} from '@angular/material/chips';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [
    AppComponent,
    ResidentsComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    BurgerMenuComponent,
    ResidentComponent,
    OssComponent,
    BulletinComponent,
    GetDescriptionDialogComponent,
    EditHumanOwnsComponent,
    OtherOwnersDialogComponent,
    HomeComponent,
    AddHumanComponent,
    FieldVotesComponent,
    RoomComponent,
    AddUploadFileDirective,
    ShowHumanDialogComponent,
    LinksComponent,
    ShowFilesListComponent,
    VotesComponent,
    QuestionsComponent,
    BulletinLinkComponent,
    QRReaderComponent,
    DistributionOfVotesComponent,
    DocumentGeneratorComponent,
    VoteMenuComponent,
    FilesComponent,

  ],
  // entryComponents: [ResidentComponent, OtherOwnersDialogComponent, ShowHumanDialogComponent,
  // ShowFilesListComponent, GetDescriptionDialogComponent],
  imports: [
    BrowserModule,
    FormsModule, BrowserAnimationsModule, MatBadgeModule,
    HttpClientModule, ReactiveFormsModule, EditorModule, PdfViewerModule,
    RouterModule.forRoot([
      {path: 'short-links', component: LinksComponent, canActivate: [AuthGuard]},
      {path: 'room', component: RoomComponent, canActivate: [AuthGuard]},
      {path: 'add-human', component: AddHumanComponent, canActivate: [AuthGuard]},
      {path: 'edit-human-owns', component: EditHumanOwnsComponent, canActivate: [AuthGuard]},
      {path: 'bulletin', component: BulletinComponent, canActivate: [AuthGuard]},
      {path: 'evotes', component: VotesComponent, canActivate: [AuthGuard]},
      {path: 'equestions', component: QuestionsComponent, canActivate: [AuthGuard]},
      {path: 'oss', component: OssComponent, canActivate: [AuthGuard]},
      {path: 'login', component: LoginComponent},
      {path: 'vote', component: ResidentsComponent, canActivate: [AuthGuard]},
      {path: 'human', component: ResidentComponent, canActivate: [AuthGuard]},
      {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
      {path: 'field-votes', component: FieldVotesComponent, canActivate: [AuthGuard]},
      {path: 'distribution-of-votes', component: DistributionOfVotesComponent, canActivate: [AuthGuard]},
      {path: 'document-generator', component: DocumentGeneratorComponent, canActivate: [AuthGuard]},
      {path: '**', redirectTo: '/home'}
    ]), MatIconModule, MatSidenavModule, MatDatepickerModule, MatNativeDateModule, MatToolbarModule, MatDividerModule,
    MatRippleModule, MatSortModule, MatExpansionModule, QRCodeModule, ZXingScannerModule, MatFormFieldModule, // MatFormFieldModule,
    MatProgressSpinnerModule, MatProgressBarModule, MatSlideToggleModule, MatSelectModule, MatPaginatorModule,
    MatCheckboxModule, MatAutocompleteModule, MatMenuModule, MatButtonModule, MatTooltipModule, MatTableModule,
    MatChipsModule, MatDialogModule, MatInputModule, MatSnackBarModule,
  ],
  providers: [
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    UserService,
    MainService,
    //   fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

