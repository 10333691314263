<form class="example-form" style="">

  <div class="resident-search">
    <mat-form-field class="example-full-width" style="width: 100%;">
      <input matInput placeholder="Поиск" [formControl]="main.searchvalue" name="searchvalue">
      <mat-hint align="start"><strong>Введите номер квартиры, или ФИО, или кадастровый номер</strong></mat-hint>
    </mat-form-field>
  </div>
  <mat-progress-bar style=" position: absolute; margin-top: -20px; width: calc(100% - 20px);" *ngIf="searchProcess"
                    mode="query"></mat-progress-bar>
  <mat-toolbar color="primary" class="full-width">
    <mat-toolbar-row>
      <mat-slide-toggle
        color="Primary"
        [(ngModel)]="main.filter_is_tsn_member"
        (change)="main.residents_list = []; main.pageIndex = 0; main.offset = 0; isTsnMemberChanged($event)"
        name="filter_is_tsn_member" matTooltip="Отфильтрует членов ТСН">
        <mat-icon>account_box</mat-icon>
      </mat-slide-toggle>
<!--      <ng-container *ngIf="main.group_type==1">-->
      <ng-container>
        <div class="vertical-splitter"></div>
        <mat-slide-toggle
          class=""
          color="Primary"
          [(ngModel)]="main.filter_showPhones"
          (change)="main.residents_list = []; main.pageIndex = 0; main.offset = 0; selectData(main.group_type)"
          name="filter_showPhones"
          matTooltip="Покажет номера телефонов в таблице. Номера можно добавлять на странице жителя.">
          <mat-icon>phone</mat-icon>
        </mat-slide-toggle>
      </ng-container>
      <div class="vertical-splitter"></div>
      <mat-form-field style="margin-top: 0; margin-left: 5px; width: 110px;" matTooltip="Отфильтрует по секции">
        <mat-select [(value)]="main.selectedSection" (selectionChange)="main.residents_list = []; main.pageIndex = 0; main.offset = 0; selectData(main.group_type)">
          <!--        <mat-option aria-selected="true" value="0">Все секции</mat-option>-->
          <mat-option *ngFor="let sect of main.allSections" [value]="sect[0]">{{sect[1] + " - " + sect[2]}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="vertical-splitter"></div>
      <mat-form-field style="margin-top: 0; margin-left: 5px; width: 310px; flex-grow: 1;" matTooltip="Отфильтрует по статусам">
        <mat-select [(value)]="main.selectedStatusesSection" (selectionChange)="main.residents_list = []; main.pageIndex = 0; main.offset = 0;  selectData(main.group_type)" multiple>
          <mat-select-trigger>
            <span  *ngFor="let status of main.selectedStatusesSection" [style.color]="status.icon_color" ><mat-icon style="vertical-align: sub;">{{status.icon}}</mat-icon> {{status.title}};</span>
          </mat-select-trigger>
          <!--        <mat-option aria-selected="true" value="0">Все секции</mat-option>-->
          <mat-option  *ngFor="let status of main.statuses" [value]="status" [style.color]="status.icon_color" ><mat-icon>{{status.icon}}</mat-icon> {{status.title}}</mat-option>
        </mat-select>
      </mat-form-field>

    </mat-toolbar-row>
  </mat-toolbar>
  <div class="animated human-copy-bulletin-url" [class.show]="showHumanCopyBulletinUrl">
    <button mat-mini-fab (click)="navigateToOssLink()" color="primary" style="margin-top: -7px;" ><mat-icon>launch</mat-icon></button>
    <button mat-mini-fab (click)="showQr=true;" color="primary" style="margin-top: -7px;" ><mat-icon>qr_code</mat-icon></button>
    <input id="copyToClipboardElement" style="" >
  </div>

  <div style="">
    <div style="display: flex; height: 100%; flex-direction: column; height: calc(100vh - 170px);">

      <div style=" flex-grow: 1; /*height: calc(100vh - 270px);*/ overflow-y: auto; overflow-x: auto;"
           (scroll)="scrollItems($event)"
           *ngIf="main.residents_list">
        <ng-container *ngIf="main.group_type == 2">
          <div class=" item-group" *ngFor="let group of main.residents_list">
            <div class="item-group-name">{{group.group_name}}</div>
            <div class="item-group-items">
              <div *ngFor="let entry of group.entryes" class="item-entry " >
                <div class="main-data"
                     (click)="showHumanInfo(entry.human_id, entry)" matRipple (dragenter)="startShowHumanInfo(entry.human_id, entry)"
                     (dragleave)="endShowHumanInfo(entry.human_id)">
                  <div class="item-room">
                    <a routerLink="." matTooltip="Кликните для просмотра информации о помещении" [routerLink]="['/room']"
                       [queryParams]="{ room_id: entry.room_id }">
                      {{ getRoomNamer(entry) }}
                    </a>
                  </div>
                  <div class="item-fio">{{entry.human}}</div>
                  <div class="item-tsn-member">
                    <mat-icon *ngIf="entry.is_tsn_member" style="font-size: 22px;">account_box</mat-icon>
                  </div>
                  <div class="item-statuses">
                    <mat-icon *ngFor="let oss of splitStatuses(entry.oss)" style="font-size: 22px;" [style.color]="oss[3]"
                              title="{{oss[4]}}: {{oss[5]}}">
                      {{oss[2]}}
                    </mat-icon>
                  </div>
                  <div style="flex-grow: 50;">

                  </div>
                  <div class="item-entry-own-data">
                    {{entry.room_square}} м2; {{entry.own}}; {{ (entry.vote_vol_com * 100).toFixed(2) + '%'  }}
                  </div>


                </div>
<!--                <div *ngIf=" main.filter_showPhones && entry.osss">-->
<!--                  <div *ngFor="let oss of entry.osss">-->
<!--                    {{main.generateBulletinLink(entry.human_guid, [oss.g])}}-->
<!--                  </div>-->
<!--                </div>-->
                <div *ngIf=" main.filter_showPhones && (entry.phones || entry.mails || entry.links || entry.osss)" >
                  <br/>
                  <mat-chip-listbox>
                    <ng-container *ngFor="let oss of entry.osss">
                      <span style="font-weight: bold">&nbsp;&nbsp;{{oss.ot}}:</span>
                      <mat-chip  (click)="main.navigateTo(generateBulletinLink(entry.human_guid, [oss.g], true), '_blank')"  matTooltip="Открыть / скачать бюллетень" >
                        <mat-icon style="color: #0400ff">print</mat-icon>
                      </mat-chip>
                      <mat-chip (click)="copyToBufferPDFLink(entry.human_guid, [oss.g], true)" matTooltip="Скопировать ссылку" >
                        <mat-icon style="color: #ff6a00">link</mat-icon>
                      </mat-chip>
                      <mat-chip  [routerLink]="['/bulletin']" [queryParams] = "{human_id: entry.human_id, oss_id: oss.id}" matTooltip="Заполнить решение"  >
                        <mat-icon style="color: #ff4081">ballot</mat-icon>
                      </mat-chip>
                      <mat-chip  [routerLink]="['/bulletin']" [queryParams] = "{human_id: entry.human_id, oss_id: oss.id}" matTooltip="Уведомить по почте"  >
                        <mat-icon style="color: #ff4081">ballot</mat-icon>
                      </mat-chip>
                    </ng-container>

                    <ng-container *ngIf="entry.phones" >
                      <mat-chip *ngFor="let phone of (entry.phones!=null?entry.phones.split(' | '):[])" >
                        <mat-icon>phone</mat-icon> <span [innerHTML]="main.formatPhone(phone)"></span>
                      </mat-chip>
                    </ng-container>
                    <ng-container *ngIf="entry.mails" >
                      <mat-chip *ngFor="let mail of (entry.mails!=null?entry.mails.split(' | '):[])">
                        <mat-icon>mail</mat-icon><span [innerHTML]="main.formatMail(mail)"></span>
                      </mat-chip>
                    </ng-container>
                    <ng-container *ngIf="entry.links" >
                      <mat-chip *ngFor="let link of (entry.links!=null?entry.links.split(' | '):[])"
                              (click)="main.navigateTo(main.getLink(link), '_blank'); $event.stopPropagation();"><span
                      [innerHTML]="main.formatLink(link, true)"></span>
                      </mat-chip>
                    </ng-container>

                  </mat-chip-listbox>
                </div>
              </div>
            </div>

          </div>
          <button mat-stroked-button class="full-width" (click)="showNext()" [disabled]="searchProcess">Показать
            еще...
          </button>
        </ng-container>
        <ng-container *ngIf="main.group_type == 1">
          <div style="display: inline-flex; vertical-align: center;">
            <div style="margin: auto 0;">найдено: {{main.found_count}}</div>
            <mat-paginator [length]="main.entry_count"
                           [pageSize]="main.pagesize"
                           [pageSizeOptions]="pageSizeOptions"
                           [pageIndex]="main.pageIndex"
                           (page)="selectPage($event)">
            </mat-paginator>
          </div>
          <table *ngIf="main.residents_list" class="search_result full_width" border="1" cellpadding="5">
            <tr>
              <th class="col_header">Пом.</th>
              <th class="col_header">S-пом.</th>
              <th class="col_header">Доля</th>
              <th class="col_header">%</th>
              <th class="col_header">Собственник</th>
              <th class="col_header"></th>
            </tr>

            <tr *ngFor="let entry of main.residents_list">
              <th *ngIf="entry._type == 'group'" class="col_header" colspan="6">
                {{entry.header}}
              </th>
              <ng-container *ngIf="entry._type == 'entry' && !entry.room_common">

                <!--              {{own.room__name}}</button> (click)="searchvalue.setValue('='+getRoomNamer(entry))">-->
                <td class="col_value">
                  <a routerLink="." matTooltip="Кликните для просмотра информации о помещении" [routerLink]="['/room']"
                     [queryParams]="{ room_id: entry.room_id }">
                    {{ getRoomNamer(entry) }}
                  </a>
                </td>
                <td class="col_value">
                  {{entry.room_square}}
                </td>
                <td class="col_value">
                  {{entry.owner_type}} {{entry.own_part}}
                </td>
                <td class="col_value">
                  {{ (entry.vote_vol_com * 100).toFixed(2) + '%'  }}

                </td>
                <td class="col_value">
                  <mat-icon *ngFor="let oss of splitStatuses(entry.oss)" style="font-size: 22px;" [style.color]="oss[3]"
                            title="{{oss[4]}}: {{oss[5]}}">{{oss[2]}}</mat-icon>
                  <mat-icon *ngIf="entry.is_tsn_member" style="font-size: 22px;">account_box</mat-icon>
                  <button mat-stroked-button class="get_content" (click)="showHumanInfo(entry.human_id, entry)">
                    {{entry.human}}
                  </button>
                  <ng-container *ngIf="entry.phones || entry.mails || entry.links">
                    <br/>
                    <mat-chip-listbox>
                      <mat-chip *ngFor="let phone of (entry.phones!=null?entry.phones.split(' | '):[])"
                                (click)="main.navigateTo('tel:'+main.getPhone(phone), '_blank')">
                        <mat-icon>phone</mat-icon>
                        <span [innerHTML]="main.formatPhone(phone)"></span></mat-chip>
                      <mat-chip *ngFor="let mail of (entry.mails!=null?entry.mails.split(' | '):[])"
                                (click)="main.navigateTo('mailto:'+main.getMail(mail), '_blank')">
                        <mat-icon>mail</mat-icon>
                      </mat-chip>
                      <mat-chip *ngFor="let link of (entry.links!=null?entry.links.split(' | '):[])"
                                (click)="main.navigateTo(main.getLink(link), '_blank')"><span
                        [innerHTML]="main.formatLink(link, true)"></span></mat-chip>
                    </mat-chip-listbox>
                  </ng-container>
                </td>
                <td class="summary">
                  <a class="get_content" matTooltip="Кликните для просмотра информации о жителе"
                     [routerLink]="['/human']"
                     [queryParams]="{room_id: entry.room_id, human_id:entry.human_id}" routerLinkActive="active">
                    <mat-icon>assignment_ind</mat-icon>
                  </a>
                </td>
              </ng-container>
              <ng-container *ngIf="entry._type == 'entry' && entry.room_common">

                <!--              {{own.room__name}}</button> (click)="searchvalue.setValue('='+getRoomNamer(entry))">-->
                <td class="col_value">
                  <a routerLink="." matTooltip="Кликните для просмотра информации о помещении" [routerLink]="['/room']"
                     [queryParams]="{ room_id: entry.room_id }">
                    {{ getRoomNamer(entry) }}
                  </a>
                </td>
                <td class="col_value">
                  {{entry.room_square}}
                </td>

                <td class="col_value" colspan="4" style="background: #bbbbbb;
              font-style: italic; color: #393d3b; text-align: center; font-weight: bold;">
                  Общее имущество
                </td>

              </ng-container>
            </tr>

          </table>
          <div style="display: inline-flex; vertical-align: center;">
            <!--      <div style="margin: auto 0;">найдено: {{length}}</div>-->
            <mat-paginator [length]="main.entry_count"
                           showFirstLastButtons="true"
                           [pageSize]="main.pagesize"
                           [pageSizeOptions]="pageSizeOptions"
                           [pageIndex]="main.pageIndex"
                           (page)="selectPage($event)">
            </mat-paginator>
          </div>
          <!--      <mat-spinner style=" margin: auto"  *ngIf="!residents_list"></mat-spinner>-->
        </ng-container>
      </div>


    </div>
    <!--    <div style="height: 100%; width: 100%; background: rgba(255, 255, 255, .9);"></div>-->
  </div>
  <div *ngIf="showQr" style="position: fixed; background: white; width: 100vw; height: 100vh; top: 0; z-index: 1000;" >
    <button mat-icon-button (click)="showQr=false" color="warn" style="position: fixed; right: 10px; top: 10px;" ><mat-icon>close</mat-icon></button>
    <qrcode [qrdata]="currentLink" width="200px" style="position: fixed; left: 50vw; top: 50vh; transform: translate(-100px, -100px)"></qrcode>
  </div>
  <!--  </mat-drawer-container>-->
</form>

