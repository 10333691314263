<mat-form-field class="full-width">
  <div matPrefix class="link" style="margin-right: 5px">{{link + ": "}}</div>
  <input matInput placeholder="URL" [(ngModel)]="url">
  <button mat-button color="primary" [disabled]="!url" matSuffix (click)="createLink()"><mat-icon>send</mat-icon></button>
</mat-form-field>


<table border="1" cellpadding="5" class="full-width">
  <thead>
  <tr>
    <th>
      Короткая ссылка
    </th>
    <th>
      Целевая ссылка
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let link of links">
    <td>
      <a href="{{'https://vernissage.life/l/'+link.key}}" target="_blank">{{'vernissage.life/l/'+link.key}}</a>

    </td>
    <td>
      <a href="{{link.target}}" target="_blank"><img src="{{main.getLocation(link.url) + '/favicon.ico'}}"> {{link.url}}</a>
    </td>
  </tr>
  </tbody>
</table>

