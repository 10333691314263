<div>
  <table mat-table [dataSource]="data" style="width: 100%" class="example-table"
         matSort matSortActive="updated" matSortDisableClear matSortDirection="desc">
    <!-- Number Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header disableClear>ОСС</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>
    <!--    ['id', 'fio', 'webCode', 'rooms', 'time', 'partStep', 'questionStep', 'locked'];-->
    <!-- Title Column -->
    <ng-container matColumnDef="fio">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header disableClear>Собственник</th>
      <td mat-cell *matCellDef="let row">{{row.fio}}</td>
    </ng-container>

    <ng-container matColumnDef="delegate">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header disableClear>Представитель</th>
      <td mat-cell *matCellDef="let row">{{row.delegate}}</td>
    </ng-container>

    <!-- State Column -->
    <ng-container matColumnDef="webCode">
      <th mat-header-cell *matHeaderCellDef>Код</th>
      <td mat-cell *matCellDef="let row">{{row.webCode}}</td>
    </ng-container>

    <!-- Created Column -->
    <ng-container matColumnDef="rooms">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Помещения</th>
      <td mat-cell *matCellDef="let row">{{row.rooms}}</td>
    </ng-container>

    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Обновлен</th>
      <td mat-cell *matCellDef="let row">{{row.updated}}</td>
    </ng-container>
    <ng-container matColumnDef="partStep">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Раздел</th>
      <td mat-cell *matCellDef="let row">{{row.partStep}}</td>
    </ng-container>
<!--    <ng-container matColumnDef="questionStep">-->
<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Вопрос</th>-->
<!--      <td mat-cell *matCellDef="let row">{{row.questionStep}}</td>-->
<!--    </ng-container>-->
    <ng-container matColumnDef="locked">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Блокирован</th>
      <td mat-cell *matCellDef="let row">{{row.locked}}</td>
    </ng-container>

    <ng-container matColumnDef="votedTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Блокирован</th>
      <td mat-cell *matCellDef="let row">{{row.locked}}</td>
    </ng-container>

    <ng-container matColumnDef="sign">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Завершил</th>
      <td mat-cell *matCellDef="let row">{{row.sign}}</td>
    </ng-container>

    <ng-container matColumnDef="final_action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Вариант</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.final_action.indexOf('p')>-1">print</mat-icon>
        <mat-icon *ngIf="row.final_action.indexOf('w')>-1">directions_run</mat-icon>
        <mat-icon *ngIf="row.final_action.indexOf('h')>-1">home_work</mat-icon>
        <mat-icon *ngIf="row.final_action.indexOf('c')>-1">perm_phone_msg</mat-icon>
        {{row.final_action}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
