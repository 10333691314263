import { Component, OnInit } from '@angular/core';
import {MainService} from '../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {Observable, Subject, Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatSelectChange} from '@angular/material/select';


@Component({
  selector: 'app-bulletin',
  templateUrl: './bulletin.component.html',
  styleUrls: ['./bulletin.component.css']
})
export class BulletinComponent implements OnInit {

  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute,
              private router: Router, private snackBar: MatSnackBar) {
    this.main.currentMatIcon = 'ballot';
    this.main.currentHeader = 'Заполнение решения';

    this.subscription = this.searchvalue.valueChanges
      .pipe(
        debounceTime(this.debounceTime), // Waiting for 1 sec while you are typing
        distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
      )
      .subscribe(value => {
        console.log(value);
        if (this.searchValue !== value) {

          this.searchValue = value;
          this.selectData();
        }
        // TODO: call BE here with this.httpClient...
      }
      );

  }
  get autocompleteValue(){
    console.log('get');
    console.log(this._autocompleteValue);
    return this._autocompleteValue;
  }

  set autocompleteValue(value){
    console.log('set');
    console.log(value);
    this._autocompleteValue = value;
  }
  public ossList: any[];

  debounceTime = 1000;
  bulletin: any ;
  doverka = '';
  voteId = 0;

  verbose = false;
  humanId = 0;
  humans: any[];
  searchValue = '';
  selectedOSSid = 0;





  public searchvalue = new UntypedFormControl('');
  private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  // tslint:disable-next-line:variable-name
  _autocompleteValue: any;
  selectedOss: any;

  // selectAutoconplete() {
  //   console.log('select')
  // }
  //
  // callSomeFunction() {
  //   console.log('select2')
  // }
  locked = '';
  isBeginFinish = false;
  isBeginStart = false;

  getDateTime(value: string){
    return new Date(value).toLocaleDateString();
  }
  addOwn(){
    this.router.navigate(['/edit-human-owns'], { queryParams: { human_id: this.bulletin.human.id } });
  }


  setAnswer(questionId, voteId, answer){
    console.log('answer');
    this.http.post(this.main.server + '/stuff/bulletin/set-answer',
      {question_id: questionId, vote_id: voteId,  answer }, this.main.httpOptions).subscribe(
      data => {
        const anyData = data as any;

        answer = anyData.answer;
        console.log(this.bulletin);
        const qwst = this.bulletin.qwsts[this.bulletin.qwsts_dict[answer.question]];
        console.log(qwst);
        qwst.answer = answer.answer;
        qwst.user = answer.user;
        const ind = this.bulletin.qwsts.findIndex( e => (e.type !== 'header' && ( e.answer === null || e.answer === '')));
        const voted = ind === -1;
        if (voted && this.bulletin.vote_status.key !== 'fill'){
          this._setStatus(this.bulletin.oss.id, this.bulletin.human.id, 'fill', 'Бюллетень заполнен');
        }
        else if (!voted && this.bulletin.vote_status.key === 'fill'){
          this._setStatus(this.bulletin.oss.id, this.bulletin.human.id, 'take', 'Бюллетень НЕ заполнен');
        }
        // let ind = this.bulletin.qwsts.findIndex(e => e.id === answer.question);
        // console.log(ind);
        // let qwst = this.bulletin.qwsts[ind];
        // qwst.answer = answer.answer;
        // qwst.user = answer.user;
      },
      err => {

      }
    );
  }

  _setStatus(ossId: number, humanId: number, statusKey: string, description: string){
    // @ts-ignore
    this.http.post(this.main.server + '/stuff/resident/set-status',
      {oss_id: ossId, human_id: humanId, status_key: statusKey, description}, this.main.httpOptions).subscribe(
      data => {
        const adata = data as any;
        this.bulletin.vote_status = adata.new_status;
      },
      err => {

      }
    );
  }

  ngOnInit() {
    this.voteId = parseInt(this.route.snapshot.queryParamMap.get('vote_id'), 10);
    this.selectedOSSid = parseInt(this.route.snapshot.queryParamMap.get('oss_id'), 10);
    this.humanId = parseInt(this.route.snapshot.queryParamMap.get('human_id'), 10);
    this.getBulleten(this.voteId, this.humanId, this.selectedOSSid);
  }



  // getHumans(){
  //   this.http.get(this.main.server + '/stuff/bulletin?' +
  //     '&vote_id=' + this.voteId+
  //     '&human_id=' + this.humanId,
  //     this.main.httpOptions).subscribe(
  //     data => {
  //       this.bulletin = data;
  //       this.humanId = this.bulletin.human.id;//['human']['id'];
  //       this.voteId = this.bulletin.vote.id;//['vote']['id'];
  //
  //     },
  //     err => {
  //
  //     }
  //   )
  // }

  displayFn(human?: any): string | undefined {
    console.log('displayFn');
    console.log(human);
    return human.human;
  }

  selectData(){


    this.http.get(this.main.server +
      '/stuff/search-owners?' +
      'search=' + encodeURI(this.searchValue),
      this.main.httpOptions).subscribe(
      data => {
        const adata = data as any;
        this.humans = adata.data;
      },
      err => {

      }
    );
  }

   getBulleten(voteId: number, humanId: number, ossId: number){

     if (isNaN(voteId)) { voteId = 0; }
     if (isNaN(humanId) ) { humanId = 0 ; }
     if ( isNaN(ossId)) { ossId = 0; }

     this.http.get<any>(this.main.server + '/stuff/bulletin?' +
       '&vote_id=' + voteId +
       '&human_id=' + humanId +
       '&oss_id=' + ossId,
       this.main.httpOptions).subscribe(
       data => {
         this.bulletin = data;
         this.locked = this.bulletin.vote.lock;
         this.doverka = this.bulletin.vote.doverka;
         this.bulletin.qwsts_dict = {};
         let ind = 0;
         for (const q of this.bulletin.qwsts){
           this.bulletin.qwsts_dict[q.id] = ind;
           ind++;
         }
         console.log(this.bulletin);
         // this.humanId = this.bulletin['human']['id'];
         // this.voteId = this.bulletin['vote']['id'];
         // this.searchValue = this.bulletin['human']['fio'];
         this.humanId = this.bulletin.human.id; // ['human']['id'];
         this.voteId = this.bulletin.vote.id; // ['vote']['id'];
         const curTime = Date();
         this.ossList = this.main.ossItems.filter(x =>
           (x.is_tsn_member === 0 || x.is_tsn_member === this.bulletin.human.is_tsn_member) );

        // this.searchValue = this.bulletin.human.fio;//['human']['fio'];
         this.searchvalue.setValue(`${this.bulletin.human.fio}`);
         if (this.bulletin.oss) {
           this.selectedOSSid = this.bulletin.oss.id;
           this.selectedOss = this.bulletin.oss;
         }
         else{
           this.selectedOSSid = 0;
           this.selectedOss = null;
         }
       },
       err => {

       }
     );

   }

  humanSelected($event: MatAutocompleteSelectedEvent) {
    console.log($event);
    const human = $event.option.value;
    this.humanId =  human[0];
    this.voteId =  0;
    this.searchValue = human[1];
    this.searchvalue.setValue(human[1]);

    this.searchvalue.setValue(human.human);
    // console.log(this.getBulleten);

    this.getBulleten(this.voteId, this.humanId, this.selectedOSSid);
  }

  selectOSS($event: MatSelectChange) {
    console.log($event);
    console.log(this.selectedOSSid);
    this.getBulleten(0, this.humanId, this.selectedOSSid);
  }

  saveDoverka() {
    const body = {
      vote_id: this.voteId,
      doverka: this.doverka
    };
    this.http.put(this.main.server + '/stuff/bulletin/set-doverka', body).subscribe(
      data => {
        this.snackBar.open('Данные о представителе сохранены', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'ok-panel',
          duration: 5000,
        });
      },
      error => {
        this.snackBar.open('Не удалось сохранить данные о представителе', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'error-panel',
          duration: 100000,
        });
      });
  }


  lockBulletin() {
      const body = {
      vote_id: this.voteId,
    };
      this.http.put<any>(this.main.server + '/staff/bulletin/lock-vote', body).subscribe(
      data => {
        this.locked = data.lock;
        this.snackBar.open('Бюллетень заблокирован', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'ok-panel',
          duration: 3000,
        });
      },
      err => {
        this.snackBar.open('Не удалось заблокировать бюллетень', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'error-panel',
          duration: 3000,
        });
      }
    );
  }

  unlockBulletin() {
    const body = {
      vote_id: this.voteId,
    };
    this.http.put<any>(this.main.server + '/staff/bulletin/unlock-vote', body).subscribe(
      data => {
        this.locked = data.lock;
        this.snackBar.open('Бюллетень разблокирован', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'ok-panel',
          duration: 3000,
        });
      },
      err => {
        this.snackBar.open('Не удалось разблокировать бюллетень', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'error-panel',
          duration: 3000,
        });
      }
    );
  }

  generateBulletinLink(humanGuid, osss, view: boolean) {
    // const osss = this.info.oss_info.current_oss.map(function(x){ return x.guid;});
    // const human_guid = this.humanGuid;
    return this.main.generateBulletinLink(humanGuid, osss, view);


  }
}


