import { Component, OnInit , OnDestroy } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../main.service';
import {ActivatedRoute, Router} from '@angular/router';

import {UntypedFormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {OtherOwnersDialogComponent} from './other-owners-dialog/other-owners-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSelectChange} from '@angular/material/select';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';


export interface DialogData {
  owns: any[];
  cown: Own;
  human: Human;


}

@Component({
  selector: 'app-edit-human-owns',
  templateUrl: './edit-human-owns.component.html',
  styleUrls: ['./edit-human-owns.component.css']
})
export class EditHumanOwnsComponent implements OnInit, OnDestroy {

  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute,
              private router: Router, public dialog: MatDialog, private snackBar: MatSnackBar) {
    this.main.currentMatIcon = 'business';
    this.main.currentHeader = 'Редактирование собственностей';

    this.subscription = this.searchvalue.valueChanges
      .pipe(
        debounceTime(this.debounceTime), // Waiting for 1 sec while you are typing
        distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
      )
      .subscribe(value => {
          console.log(value);
          if (this.searchValue !== value) {
          this.searchValue = value;
          this.human_id = 0;
        }
          this.selectData();
        }
      );

    this.room_subscription = this.room_searchvalue.valueChanges
      .pipe(
        debounceTime(this.room_debounceTime), // Waiting for 1 sec while you are typing
        distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
      )
      .subscribe(value => {
          console.log(value);
          if (this.room_searchValue !== value) {
            this.room_searchValue = value;
            this.selected_room = null;
          }
          this.selectRoomsData();
        }
      );



  }

  public searchvalue = new UntypedFormControl('');
  debounceTime = 1000;
  private subscription: Subscription;
  human_id = 0;
  humans: any[];
  searchValue = '';

  public room_searchvalue = new UntypedFormControl('');
  room_debounceTime = 1000;
  private room_subscription: Subscription;
  selected_room: any;
  free_rooms: any[];
  room_searchValue = '';

  session: Session;
  server_message = '';
  // updateSessionTimerId;
  error_message = '';
  entry_human_id = 0;


  ngOnInit() {
    this.entry_human_id = parseInt(this.route.snapshot.queryParamMap.get('human_id'), 10);
    if ( isNaN(this.entry_human_id) ||  this.entry_human_id ===  null)
    {
      this.entry_human_id = 0;

    }
    this.human_id = this.entry_human_id;
    this.getHumanOwns(this.entry_human_id);

  }

  ngOnDestroy(){
    this.breakSession();
    // clearTimeout(this.updateSessionTimerId);
  }



  selectData(){


    this.http.get(this.main.server +
      '/stuff/search-human?' +
      'search=' + encodeURI(this.searchValue),
      this.main.httpOptions).subscribe(
      data => {
        const anyData = data as any;

        this.humans = anyData.data;
      },
      err => {

      }
    );
  }

  humanSelected($event: MatAutocompleteSelectedEvent) {
    console.log($event);
    const human = $event.option.value;
    this.human_id = human[0];
    this.entry_human_id = this.human_id;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {human_id: this.entry_human_id},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    this.searchValue = human[1];
    this.searchvalue.setValue(human[1]);
    this.getHumanOwns(this.human_id);
  }

  selectRoomsData(){


    this.http.get(this.main.server +
      '/stuff/owns/get-free-rooms?' +
      'search=' + encodeURI(this.room_searchValue),
      this.main.httpOptions).subscribe(
      data => {
        const anyData = data as any;

        this.free_rooms = anyData.free_rooms;
      },
      err => {

      }
    );
  }

  roomSelected($event: MatAutocompleteSelectedEvent) {
    console.log($event);
    const room = $event.option.value;
    this.selected_room = room;
    this.room_searchValue = room.cadastral_number;
    this.room_searchvalue.setValue(room.cadastral_number);
    // this.getFreeRooms(this.room_id);
  }

  updateSession(This: EditHumanOwnsComponent){
    return; // Заглушил функцию
    // clearTimeout(This.updateSessionTimerId);
    console.log((This.session));
    if (This.session) {
      This.http.post(this.main.server + '/stuff/owns/update', this.session, this.main.httpOptions).subscribe(
        data => {
          const anyData = data as any;

          This.server_message = anyData.message;
          // This.updateSessionTimerId = setTimeout(() => {
          //   This.updateSession(This)
          // }, 10000);
        },
        err => {

        }
      );
    }
    else {
      // This.updateSessionTimerId = setTimeout(() => {
      //   This.updateSession(This)
      // }, 10000);
    }
  }


  getHumanOwns(human_id: number){
    // clearTimeout(this.updateSessionTimerId);
    this.http.get(this.main.server + '/stuff/owns/get?' +
      '&human_id=' + this.human_id,
      this.main.httpOptions).subscribe(
      data => {
        const anyData = data as any;

        this.session = (Object.assign(new Session(), anyData.session) as Session);
        this.server_message = anyData.message;
        // this.updateSessionTimerId = setTimeout( ()=>{this.updateSession(this)}, 10000);
        this.searchvalue.setValue(this.session.human.fio);
      },
      err => {

      }
    );
  }



  ownPartTitleChanged($event: KeyboardEvent, own: Own) {
    // console.log($event);
    let errr = false;
    let nnm = '';
    for (const n of own.own_part_title){
      if (n === '/' || (n >= '0' && n <= '9') ) {
        nnm += n;
      }
      else{
        errr = true;
      }
    }
    if (!errr){
      if (own.own_part_title !== nnm) { own.own_part_title = nnm; }
      this.calculatePartSquare(own);
    }
    else{


    }
  }

  calculatePartSquare(own: Own){
    try{
      const res = eval(own.own_part_title) as number;
      const val = own.room__square * res;
      own.own_part_square = val;
      let sq = val;
      for ( const ro of own.rel_owns){
        sq += ro[2];
      }
      if (sq > own.room__square){
        this.error_message = 'Сумма долей жильцов больше площади квартиры!';
      }
      else{
        this.error_message = '';
      }
    }
    catch (e) {


    }
  }

  breakSession() {
    // clearTimeout(this.updateSessionTimerId);
    this.http.get(this.main.server + '/stuff/owns/break',
      this.main.httpOptions).subscribe(
      data => {
        this.session = null; // (<Session> Object.assign(new Session(), data['session']));
        this.server_message = 'Редактор сброшен..';
        this.searchvalue.setValue('');
        if (this.entry_human_id === this.human_id) {
          this.entry_human_id = 0;
          this.router.navigate(
            [],
            {
              relativeTo: this.route,
              queryParams: {human_id: 0},
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
        }
        if (this.entry_human_id > 0) {
          this.human_id = this.entry_human_id;
          this.getHumanOwns(this.human_id);
        }

      },
      err => {

      }
    );
  }

  showOtherOwners(own: Own){

    this.dialog.open(OtherOwnersDialogComponent, {
      data: {
        owns: own.rel_owns,
        human: this.session.human,
        cown: own,

      }
    });
  }

  // selectOwnType($event: Event, own: Own) {
  //   console.log($event);
  //   if(own.owner_type == 'Л'){
  //     own.own_part_title = '1';
  //     this.calculatePartSquare(own);
  //   }
  // }
  selectOwnType($event: MatSelectChange, own: Own) {
    console.log($event);
    if (own.owner_type === 'Л'){
        if ((own.rel_owns.length + 1) < 2) {
          own.own_part_title = '1';
          this.calculatePartSquare(own);
        }
        else
        {
          alert('В помещении зарегистрированы другие лица, поэтому личная собственность не может выть выбрана.');
          own.owner_type = 'Д';
        }
      }
    this.sessionChanged();
  }


  addRoom() {
    const own = new Own();
    const sr = this.selected_room;
    own.to_create = true;
    own.room__cadastral_number = sr.cadastral_number;
    own.room__name = sr.name;
    own.owner_title = sr.name;
    if (sr.items_square > 0) {
      own.owner_type = 'Д';
      own.own_part_square = sr.free_square;
      own.own_part_title = '?/?';
    }
    else{
      own.owner_type = 'Л';
      own.own_part_square = sr.free_square;
      own.own_part_title = '1';
    }
    own.is_own = 1;
    own.room__square = sr.square;
    own.reg_number = sr.cadastral_number + '-';
    own.rel_owns = [];
    this.session.rel_actuals.push(own);
    this.selected_room = null;
  }

  saveSession() {
    // clearTimeout(this.updateSessionTimerId);
    console.log('Save session...');
    console.log((this.session));
    if (this.session) {
      this.http.post(this.main.server + '/stuff/owns/save', this.session, this.main.httpOptions).subscribe(
        data => {
          const anyData = data as any;

          this.server_message = anyData.message;
          this.session = anyData.session;
        },
        err => {

        }
      );
    }

  }

  removeCreated($event: MouseEvent, own: Own) {
    const ind = this.session.rel_actuals.indexOf(own);
    if (ind >= 0){
      this.session.rel_actuals.splice(ind, 1);
      this.sessionChanged();
    }
  }

  sessionChanged() {
    this.updateSession(this);
  }
}



export class Session{
  human: Human;
  rel_actuals: Own[];

  rel_not_actuals: Own[];
}

export class Human{
  id = 0;
  fio = '';
  key = '';
  is_owner = 0;
  is_tsn_member = 0;
}

export class Own {
  id = 0;
  to_delete = false;
  to_create = false;
  owner_title = '';
  owner_type = '';
  reg_number = '';
  reg_date: any;
  add_time: any;
  supdate_time: any;
  remove_time: any;
  own_part_title = '';
  own_part_square = 0.0;
  is_own = 0;
  room__id = 0;
  room__name = '';
  room__square = 0.0;
  room__cadastral_number = '';
  rel_owns: any[][];


}
