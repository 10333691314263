<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <div class="description">
    {{data.description}}
  </div>
  <mat-form-field style="width: 100%;">
    <textarea matInput [(ngModel)]="data.editValue"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button *ngFor="let button of data.buttons.split('|')" mat-button
          (click)="data.result=button; dialogRef.close();">{{button}}</button>
</div>
