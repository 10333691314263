<div style="height: 100%; width: 100%;" >
<form style="margin: auto;">
  <div class="" style="width: min-content; margin: auto;" *ngIf="!userService.token">

      <mat-form-field class="full-width">
        <input matInput placeholder="Логин" [(ngModel)]="user.username" name="login"/>
<!--        <mat-error *ngFor="let error of userService.errors.username"><br />-->
<!--          {{ error }}</mat-error>-->
      </mat-form-field>

      <mat-form-field class="full-width">
        <input matInput placeholder="Пароль" [type]="passwordType?'password':'text'" [(ngModel)]="user.password" name="password" />
        <div  matSuffix  aria-label="Clear" (click)="passwordType = !passwordType">
          <mat-icon style="cursor: pointer; font-size: 16px;"  >{{passwordType?'visibility':'visibility_off'}}</mat-icon>
        </div>
<!--        <mat-error *ngFor="let error of userService.errors.password"><br />-->
<!--          {{ error }}</mat-error>-->
      </mat-form-field>

      <div class="">
        <button mat-button  style="width: 100%;" (click)="login()" class="btn btn-primary">Вход</button>
      </div>


<!--    <div class="col-sm-12">-->
<!--      <mat-error *ngFor="let error of userService.errors.non_field_errors">{{ error }}<br /></mat-error>-->
<!--    </div>-->

  </div>
</form>

</div>

<!--<form>-->
<!--  <div class="login" *ngIf="userService.state === 2" >-->
<!--    <p class="bold">Регистрация в личном кабинете.</p>-->
<!--    <p>Регистрация происходит в личном кабинете жителя расчетного центра.</p>-->
<!--    <p style="color: red" [innerText]="userService.registerErrorMessage"></p>-->
<!--    <input [(ngModel)]="licNumber" class="form__item" type="text" name="licNumber" placeholder="№ лицевого счета">-->
<!--    <input [(ngModel)]="lastName" class="form__item" type="text" name="lastName" placeholder="Фамилия собственника">-->
<!--    <input [(ngModel)]="email" class="form__item" name="login" type="email"  placeholder="Почта (e-mail)">-->
<!--    <div style="display: flex; flex-direction: row;">-->
<!--      <button class="btn" type="submit" (click)="userService.register(email, lastName, licNumber)">Регистрация</button>-->
<!--      <div style="margin-top: 15px; margin-left: 15px;">-->
<!--        <span href="#" (click)="userService.state = 1" style="cursor: pointer;" class="link link_pink">Вход</span><br>-->
<!--        <span href="#" (click)="userService.state = 3"  style="cursor: pointer;" class="link link_pink">Сброс пароля</span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</form>-->

<!--<form>-->
<!--  <div class="login" *ngIf="userService.state === 7">-->
<!--    <p class="bold">Добавить лицевой счет.</p>-->
<!--    <p style="color: red" [innerText]="userService.registerErrorMessage"></p>-->
<!--    <input [(ngModel)]="licNumber" class="form__item" type="text" name="licNumber" placeholder="№ лицевого счета">-->
<!--    <input [(ngModel)]="lastName" class="form__item" type="text" name="lastName" placeholder="Фамилия собственника">-->
<!--    <input [(ngModel)]="email" class="form__item" name="login" type="email"  placeholder="Почта (e-mail)">-->
<!--    <button class="btn" type="submit" (click)="userService.register(email, lastName, licNumber)">Добавить</button>-->
<!--    <button class="btn" style="margin-left: 5px; " (click)="userService.state = 0">Отменить</button>-->
<!--  </div>-->
<!--</form>-->

<!--<form>-->
<!--  <div class="login" *ngIf="userService.state == 3">-->
<!--    <p class="bold">Сброс пароля.</p>-->
<!--    <p>Сброс пароля происходит через расчетный центр.</p>-->
<!--    <p *ngIf="userService.resetErrorMessage" style="color: red">{{ userService.resetErrorMessage }}</p>-->
<!--    <input [(ngModel)]="email" class="form__item" name="login" type="email"  placeholder="Почта (e-mail)">-->

<!--    <div style="display: flex; flex-direction: row;">-->
<!--      <button class="btn" type="submit" (click)="userService.reset(email)">Сбросить</button>-->
<!--      <div style="margin-top: 15px; margin-left: 15px;">-->
<!--        <span href="#" (click)="userService.state = 1" style="cursor: pointer;" class="link link_pink">Вход</span><br>-->
<!--        <span href="#" (click)="userService.state = 2"  style="cursor: pointer;" class="link link_pink">Регистрация</span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</form>-->

<!--<div class="login" *ngIf="userService.state == 4 ">-->
<!--  <p class="bold">На вашу почту <span [innerText]="email"></span>  отправлено письмо в котором есть кнопка подтверждения почтового ящика. Нажав на нее-->
<!--    вы попадете на сайт расчетного центра, где вам будет предложено ввести дополнительную информацию для подтверждения.</p>-->
<!--  <p *ngIf="userService.state == 4">После подтверждения регистрации, сможете войти.</p>-->
<!--  <button *ngIf="userService.state == 4" class="btn" (click)="userService.state = 1">Войти</button>-->
<!--</div>-->

<!--<div class="login" *ngIf="userService.state === 8">-->
<!--  <p class="bold">На вашу почту <span [innerText]="email"></span> отправлено письмо с дальнейшими инструкциями. </p>-->

<!--</div>-->


<!--<div class="login" *ngIf="userService.state == 5">-->
<!--  <p class="bold">На вашу почту <span [innerText]="email"></span> отправлено письмо в котором есть кнопка сброса пароля. Нажав на нее-->
<!--    вы попадете на сайт расчетного центра, после чего на почту будет выслан новый пароль.</p>-->
<!--  <p>Когда получите новый парольи, постарайтесь запомнить его, и после этого можете войти.</p>-->
<!--  <button class="btn" (click)="userService.state = 1">Войти</button>-->
<!--</div>-->
