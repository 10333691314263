<button mat-button [matMenuTriggerFor]="menu"  (click)="generateOssLink(true)"><mat-icon>pan_tool</mat-icon></button>
<mat-menu #menu="matMenu" >
  <button mat-menu-item class="animated"
          [disabled]="false" (click)="generateBulletin(true)"
          matTooltip="Сгенерировать и открыть бюллетень (Решение) для выбранного собственника">
    <mat-icon>print</mat-icon> Открыть..
  </button>
  <button mat-menu-item class="animated" [matMenuTriggerFor]="menu2"
          [disabled]="false"
          matTooltip="Показать / копировать ссылку на голосовалку">
    <mat-icon>link</mat-icon> Сслыка
  </button>

  <button mat-menu-item class="animated" *ngIf="human && oss"

          [disabled]="!allowVote" [routerLink]="['/bulletin']"
          [queryParams]="{human_id: human.human_id, oss_id: oss.oss_id}"
          matTooltip="Заполнить решение">
    <mat-icon>ballot</mat-icon> Заполнить
  </button>
  <button mat-button class="animated"  (click)="scanBarcode()" matTooltip="Сканировать QR-код решения">
    <mat-icon>qr_code</mat-icon> Сканировать ..
  </button>

</mat-menu>
<mat-menu #menu2="matMenu" >
  <button mat-menu-item (click)="copylinkToClipboard()" color="primary" style="margin-top: -7px;" ><mat-icon>content_paste</mat-icon> Копировать </button>
  <button mat-menu-item (click)="navigateToOssLink()" color="primary" style="margin-top: -7px;" ><mat-icon>launch</mat-icon> Перейти ..</button>
  <button mat-menu-item (click)="showQr=true;" color="primary" style="margin-top: -7px;" ><mat-icon>qr_code</mat-icon>  показать QR-код</button>
  <input id="copyToClipboardElement"
         (click)="$event.preventDefault(); $event.stopPropagation();"
         (mouseup)="$event.preventDefault(); $event.stopPropagation();"
         readonly
         style="min-width: 100px; max-width: 300px; margin-left: 10px; margin-right: 10px" value="{{currentLink}}">
</mat-menu>

<div *ngIf="showQr" style="position: absolute; background: white; width: 100vw; height: 100vh; left: 0; top: 0; z-index: 1000;">
  <button mat-fab (click)="showQr=false" color="warn" style="position: fixed; right: 10px; top: 10px;">
    <mat-icon>close</mat-icon>
  </button>
  <qrcode [qrdata]="currentLink" width="200px"
          style="position: fixed; left: 50vw; top: 50vh; transform: translate(-100px, -100px)"></qrcode>
</div>


<div *ngIf="showQrScan" style="position: absolute; background: white; width: 100vw; height: 100vh; left: 0; top: 0; z-index: 1000;">
  <app-qrreader class="full-width"></app-qrreader>
  <button mat-fab (click)="showQrScan=false" color="warn" style="position: fixed; right: 10px; top: 10px;">
    <mat-icon>close</mat-icon>
  </button>
</div>
