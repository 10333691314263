import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../main.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-distribution-of-votes',
  templateUrl: './distribution-of-votes.component.html',
  styleUrls: ['./distribution-of-votes.component.sass']
})
export class DistributionOfVotesComponent implements OnInit {

  private oss_id: number;
  data: Data;

  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute, private router: Router) {
    this.main.currentMatIcon = "insert_chart_outlined";
    this.main.currentHeader = "Распределение голосов";
  }

  ngOnInit(): void {
    this.oss_id = parseInt(this.route.snapshot.queryParamMap.get("oss_id"), 10)
    this.getData();
  }


  getData(){

    this.http.get<any>(this.main.server +
      '/stuff/distribution-of-votes?' +
      'oss_id=' + this.oss_id).subscribe(
      data => {
        this.data = data.data;
      },
      err => {

      }
    )

  }
}

export interface Data {
  isTsnMember: boolean
  homeSquare: any;
  oss_id: number;
  totalVotes: number;
  totalHomeVotes: number;
  voted: number;
  votedOfTotal: number;
  endDate: Date;
  questions: Question[];

}

export interface Question {
  agenda: string;
  decision: string;
  number: string;
  kvm: ItemResult;
  tookPart: ItemResult;
  totalNumber: ItemResult;
}

export interface ItemResult {
  f: number;
  a: number;
  r: number;
}
