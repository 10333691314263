import { Component, OnInit } from '@angular/core';
import {UserService} from '../../user.service';
import {MainService} from '../../main.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public userService: UserService, public main: MainService) { }
  //constructor() { }

  ngOnInit() {
  }

  openBurger($event: MouseEvent) {
    this.main.IsMenuOpened = true;
   // document.getElementsByTagName('body').addClass('menu_opened')
   // $('body').addClass('menu_opened');
    //$('.menu_box').addClass('active_box');
  }


}
