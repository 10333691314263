import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {UserService} from '../user.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MainService} from '../main.service';
import {ShowHumanDialogComponent} from './show-human-dialog/show-human-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PageEvent} from '@angular/material/paginator';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';


@Component({
  selector: 'app-residents',
  templateUrl: './residents.component.html',
  styleUrls: ['./residents.component.css']
})
export class ResidentsComponent implements OnInit, OnDestroy {


  constructor(public userService: UserService, private http: HttpClient, public dialog: MatDialog,
              public main: MainService, private snackBar: MatSnackBar) {
    this.main.currentMatIcon = 'search';
    this.main.currentHeader = 'Поиск';
    this.subscription = this.main.searchvalue.valueChanges
      .pipe(
        debounceTime(this.debounceTime), // Waiting for 1 sec while you are typing
        distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
      )
      .subscribe(value => {
        console.log(value);
        this.main.searchValue = value;
        this.main.residents_list = [];
        this.main.pageIndex = 0;
        main.offset = 0;
        this.selectData(this.main.group_type);
        // TODO: call BE here with this.httpClient...
      });
  }

  // MatPaginator Output
  pageEvent: PageEvent;
  private modelChanged: Subject<string> = new Subject<string>();
  private subscription: Subscription;
  debounceTime = 500;
  show_human_info_timeout: any;
  show_human_info_human_id = 0;
  showHumanCopyBulletinUrl = false;


  public pageSizeOptions: number[] = [10, 20, 50, 100, 200, 500, 1000];


  searchProcess = false;
  currentLink = '';
  showQr = false;
  ngOnInit() {
    if (!this.main.residents_list || this.main.residents_list.length === 0) {
      this.selectData(this.main.group_type);
    }
    this.getSections();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  splitStatuses(value: string) {

    if (value == null) {
      return [];
    }
    const osss = value.split(';');
    // console.log(osss);
    const r = [];
    for (const oss of osss) {
      // console.log(oss);
      const vals = oss.split(',', 6);
      // console.log(vals);
      r.push(vals);
    }
    // console.log(r)
    return r;
  }

  getRoomNamer(entry) {
    if (entry.own_name) {
      return entry.own_name;
    } else {
      return entry.room_name;
    }
  }


  // setSearchValue(value){
  //   this.searchValue = value;
  //   this.searchvalue.setValue(value).
  // }
  getSections() {
    this.http.get(this.main.server + '/stuff/sections',
      this.main.httpOptions).subscribe(
      data => {
        const adata = data as any;
        this.main.allSections = [[0, 'Все секции', '']].concat(adata.data);
      },
      err => {
      }
    );
  }

  selectPage(event?: PageEvent) {
    this.main.pageIndex = event.pageIndex;
    this.main.pagesize = event.pageSize;
    this.main.offset = this.main.pageIndex * this.main.pagesize;
    this.selectData(this.main.group_type);
  }

  selectData(group_type) {
    // console.log(this.main.selectedStatusesSection);
    this.searchProcess = true;
    // this.residents_list = null;
    let statuses = '';
    if (this.main.selectedStatusesSection.length > 0)
    {
      statuses = '&statuses=' + this.main.selectedStatusesSection.map(x => x.key).join(',');
    }
    this.http.get(this.main.server + '/stuff/residents?' +
      '&search=' + encodeURI(this.main.searchValue) +
      '&section=' + this.main.selectedSection +
      '&order=' + encodeURI(this.main.order) +
      '&offset=' + this.main.offset +
      '&show-phones=' + (this.main.filter_showPhones ? '1' : '0') +
      '&tsn-mem=' + (this.main.filter_is_tsn_member ? '1' : '0') +
      '&pagesize=' + this.main.pagesize +
       statuses +
      '&gt=' + group_type,
      this.main.httpOptions).subscribe(
      data => {
        const adata = data as any;
        this.main.group_type = adata['group-type'];
        this.main.found_count = adata.found_count;
        this.main.entry_count = adata.entry_count;
        this.searchProcess = false;
        if (this.main.group_type === 1) {
          this.main.residents_list = adata.data;
        }
        else if (this.main.group_type === 2){
          const items = adata.data;
          if (items.length > 0) {
            if (this.main.residents_list.length > 0) {
              const first_group = items.shift();
              const last_group = this.main.residents_list[this.main.residents_list.length - 1];
              if (first_group.group_name === last_group.group_name) {
                last_group.entryes = last_group.entryes.concat(first_group.entryes);
              } else {
                this.main.residents_list.push(first_group);
              }
            }
            this.main.residents_list = this.main.residents_list.concat(items);
          }
        }

      },
      err => {
        this.searchProcess = false;
      }
    );
  }
  showHumanInfo(human_id: number, entry: any){

    this.dialog.open(ShowHumanDialogComponent, {
      data: {
        entry,
        human_id
      }

    }).afterClosed().subscribe(result => {
      this.http.get(this.main.server + '/stuff/residents?' +
        '&human_id=' + human_id +
        '&gt=' + this.main.group_type,
        this.main.httpOptions).subscribe(
        data => {
          const adata = data as any;
          Object.assign(entry, adata.data[0]);
        },
        err => {
        });

    });
  }

  isTsnMemberChanged($event: MatSlideToggleChange) {
    this.selectData(this.main.group_type);
  }

  showNext() {
    if (this.searchProcess) { return; }
    console.log('show next');
    this.main.pageIndex = this.main.pageIndex + 1;
    this.main.offset = this.main.pageIndex * this.main.pagesize;
    this.selectData(this.main.group_type);
  }

  scrollItems($event: Event) {
    this.showHumanCopyBulletinUrl = false;
    if (this.main.group_type === 1) { return; }
    // console.log($event);
    const scroll_element = $event.target as HTMLElement;
    const top = scroll_element.scrollTop;
    const scroll_height = scroll_element.scrollHeight;
    const client_height = scroll_element.clientHeight;
    const max_top = scroll_height - client_height;
    // console.log(top+ "; "+ max_top);
    if (top === max_top){
      console.log('EVENT');
      this.showNext();
    }

  }

  startShowHumanInfo(humanId: number, entry: any) {
    window.clearTimeout(this.show_human_info_timeout);
    this.show_human_info_human_id = humanId;
    const This = this;
    console.log('start ' + this.show_human_info_human_id);
    this.show_human_info_timeout = window.setTimeout(() => {
      if (This.show_human_info_human_id > 0) {
        const id = This.show_human_info_human_id;
        This.show_human_info_human_id = 0;
        console.log('event ' + id);
        this.showHumanInfo(id, entry);
      }
    }, 2000);
  }

  endShowHumanInfo(human_id: number) {
    console.log('end ' + this.show_human_info_human_id);
    this.show_human_info_human_id = 0;
    window.clearTimeout(this.show_human_info_timeout);
  }

  generateBulletinLink(human_guid, osss, view: boolean) {
    // const osss = this.info.oss_info.current_oss.map(function(x){ return x.guid;});
    // const human_guid = this.humanGuid;
    return this.main.generateBulletinLink(human_guid, osss, view);


  }
  copyToBufferPDFLink(human_guid, osss, view: boolean){
    const link = this.main.genarateOssLink(human_guid, osss, view); // this.main.generateBulletinLink(human_guid, osss, view)
    this.currentLink = link;
    this.showHumanCopyBulletinUrl = true;
    const el = document.getElementById('copyToClipboardElement') as HTMLInputElement;
    el.value = link;
    if (true || navigator.clipboard) {
      // поддержка имеется, включить соответствующую функцию проекта.
      navigator.clipboard.writeText(link)
        .then(() => {
          this.snackBar.open('Ссылка скопирована в буфер обмена', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'ok-panel',
            duration: 5000,
          });
        })
        .catch(err => {
          this.snackBar.open('Не удалось скопировать ссылку в буфер обмена', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 5000,
          });
        });
    } else {
      // поддержки нет. Придётся пользоваться execCommand или не включать эту функцию.

      this.main.iosCopyToClipboard(el);
      this.snackBar.open('Ссылка скопирована в буфер обмена', 'Ок', {
        verticalPosition: 'top',
        panelClass: 'ok-panel',
        duration: 5000,
      });

    }


  }

  navigateToOssLink() {
    window.open(this.currentLink, '_blank');
  }
}
