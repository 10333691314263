import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute,
              private router: Router, public dialog: MatDialog, private snackBar: MatSnackBar) {
    this.main.currentMatIcon = 'home';
    this.main.currentHeader = 'Главная панель';
    this.subscription = this.searchvalue.valueChanges
      .pipe(
        debounceTime(this.debounceTime), // Waiting for 1 sec while you are typing
        distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
      )
      .subscribe(value => {
          // console.log("changed");
          this.showHumanCopyBulletinUrl = false;
          if (this.searchValue !== value) {
            this.searchValue = value;
            this.humanId = 0;
          }
          this.selectData();

        }
      );
  }
  selectedHuman: any;
  human: any;
  humanGuid: string;
  humanIsTsnMember = 0;
  public searchvalue = new UntypedFormControl('');
  humans: any[];
  searchValue = '';
  debounceTime = 1000;
  private subscription: Subscription;
  humanId = 0;
  roomId = 0;

  info: any;
  showHumanCopyBulletinUrl = false;
  showQr = false;
  currentLink = '';
  // showQrScan = false;
  requesting = false;



  ngOnInit() {
    this.getInfo();
  }

  selectData(){
    console.log('select data');


    this.http.get(this.main.server +
      '/stuff/search-human?' +
      'search=' + encodeURI(this.searchValue),
      this.main.httpOptions).subscribe(
      data => {
        console.log('fetch data');
        const dAny = data as any;
        this.humans = dAny.data;
      },
      err => {

      }
    );
  }

  humanSelected($event: MatAutocompleteSelectedEvent) {

    console.log('select');
    const human = $event.option.value;
    this.selectedHuman = human;
    this.humanId = human[0];
    this.searchValue = `${human[1]} - ${human[4]}`;
    this.searchvalue.setValue(`${human[1]} - ${human[4]}`);
    this.humanIsTsnMember = human[3];
    this.roomId = human[5];
    this.humanGuid = human[6];
    const h: any = {};
    h.human_id = this.humanId;
    h.human_guid = this.humanGuid;
    h.is_tsn_member = this.humanIsTsnMember;
    this.human = h;

  }

  getDateTime(value: string){
    return new Date(value).toLocaleDateString();
  }

  getInfo(){
    this.requesting = true;
    this.http.get(this.main.server + '/stuff/home',
      this.main.httpOptions).subscribe(
      data => {
        this.info = data;
        this.requesting = false;
        // console.log(this.info)

      },
      err => {
        console.log('getInfo error');
        this.requesting = false;
        this.snackBar.open('Не удалось получить данные', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'error-panel',
          duration: 50000,
        });
      }
    );
  }

  generateBulletin(view: boolean, inf?: any) {
    if (inf) {
      const osss = [inf.guid];
      if (!this.humanGuid){
        throw  new Error('no human guid');
      }
      const humanGuid1 = this.humanGuid;
      this.main.generateBulletin(humanGuid1, osss, view);
    } else {
      const osss = this.info.oss_info.current_oss.map((x) => x.guid);
      if (!this.humanGuid){
        throw  new Error('no human guid');
      }
      const humanGuid1 = this.humanGuid;
      this.main.generateBulletin(humanGuid1, osss, view);
    }
  }

  generateBulletinLink(view: boolean) {
    const osss = this.info.oss_info.current_oss.map((x) => x.guid);
    const humanGuid1 = this.humanGuid;
    const link = this.main.generateBulletinLink(humanGuid1, osss, view);
    this.showHumanCopyBulletinUrl = true;
    const el = document.getElementById('copyToClipboardElement') as HTMLInputElement;
    el.value = link;
    if (true || navigator.clipboard) {
      // поддержка имеется, включить соответствующую функцию проекта.
      navigator.clipboard.writeText(link)
        .then(() => {
          this.snackBar.open('Ссылка скопирована в буфер обмена', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'ok-panel',
            duration: 5000,
          });
        })
        .catch(err => {
          this.snackBar.open('Не удалось скопировать ссылку в буфер обмена', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 5000,
          });
        });
    } else {
      // поддержки нет. Придётся пользоваться execCommand или не включать эту функцию.

      this.main.iosCopyToClipboard(el);
      this.snackBar.open('Ссылка скопирована в буфер обмена', 'Ок', {
        verticalPosition: 'top',
        panelClass: 'ok-panel',
        duration: 5000,
      });

    }

  }


  generateEmptyBulletin(ossGuid, view) {
    const humanGuid1 = '';
    this.main.generateBulletin(humanGuid1, [ossGuid], view);
  }

  generateOssLink(view: boolean, inf?: any) {
    let osss = [];
    if (inf) {
      osss = [inf.guid];
    } else {
      osss = this.info.oss_info.current_oss.map((x) => {
        return x.guid;
      });
    }
    if (!this.humanGuid){
      throw  new Error('no human guid');
    }
    const humanGuid1 = this.humanGuid;

    const link = this.main.genarateOssLink(humanGuid1, osss, view);
    this.currentLink = link;
    this.showHumanCopyBulletinUrl = true;
    const el = document.getElementById('copyToClipboardElement') as HTMLInputElement;
    el.value = link;
    if (true || navigator.clipboard) {
      // поддержка имеется, включить соответствующую функцию проекта.
      navigator.clipboard.writeText(link)
        .then(() => {
          this.snackBar.open('Ссылка скопирована в буфер обмена', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'ok-panel',
            duration: 5000,
          });
        })
        .catch(err => {
          this.snackBar.open('Не удалось скопировать ссылку в буфер обмена', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'error-panel',
            duration: 5000,
          });
        });
    } else {
      // поддержки нет. Придётся пользоваться execCommand или не включать эту функцию.

      this.main.iosCopyToClipboard(el);
      this.snackBar.open('Ссылка скопирована в буфер обмена', 'Ок', {
        verticalPosition: 'top',
        panelClass: 'ok-panel',
        duration: 5000,
      });

    }
  }

  allowVote(inf?: any) {
    if (inf) {
      return this.humanId !== 0 && (this.humanIsTsnMember || !inf.is_tsn_member);
    } else {
      return this.info && this.info.oss_info && this.info.oss_info.current_oss
        && this.info.oss_info.current_oss.length > 0 && this.humanId !== 0
        && (this.humanIsTsnMember || !this.info.oss_info.current_oss[0].is_tsn_member);
    }
  }

  navigateToOssLink() {
    window.open(this.currentLink, '_blank');
  }




}
