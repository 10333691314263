import {Component, OnInit} from '@angular/core';
import {UserService} from './user.service';
import {throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
//import {AuthenticationService} from './_services/authentication.service';
import {Router} from '@angular/router';
import {User} from './_models/user';
import {MainService} from './main.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  /**
   * An object representing the user for the login form
   */
  currentUser: User;


  constructor(private userService: UserService,
              private router: Router,
              private authenticationService: UserService,
              public main: MainService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.main.onLoggedIn();
  }


  // logout() {
  //   console.log('login app component');
  //   this.authenticationService.logout();
  //   this.router.navigate(['/login']);
  // }

  ngOnInit() {

  }

  activated() {
    //this.mainService.IsMenuOpened = false;
  }



}
//
//
// import { Component } from '@angular/core';
// import { Router } from '@angular/router';
//
//
// import {AuthenticationService} from './_services/authentication.service';
// import {User} from './_models/user';
//
// @Component({ selector: 'app', templateUrl: 'app.component.html' })
// export class AppComponent {
//
//   constructor(
//     private router: Router,
//     private authenticationService: AuthenticationService
//   ) {
//     this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
//   }
//
//   logout() {
//     this.authenticationService.logout();
//     this.router.navigate(['/login']);
//   }
// }

// import { Component } from '@angular/core';
//
// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.css']
// })
// export class AppComponent {
//   title = 'TSNStuff';
// }
