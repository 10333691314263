import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})
export class RoomComponent implements OnInit {

  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute,
              private router: Router, public dialog: MatDialog, private snackBar: MatSnackBar) {
    this.main.currentMatIcon = 'meeting_room';
    this.main.currentHeader = 'Помещение';
  }
  room_id = 0;
  room: any;
  owners = [];
  total_square = 0;
  ngOnInit() {
    this.room_id = parseInt(this.route.snapshot.queryParamMap.get('room_id'), 10);
    this.getRoom();
  }

  getRoom(){
    this.http.get(this.main.server +
      '/stuff/rooms/get-room?' +
      'room_id=' + this.room_id,
      this.main.httpOptions).subscribe(
      data => {
        const adata = data as any;
        if (adata.result === 'ok') {
          this.room = adata.room;
          this.owners = adata.owners;
          this.total_square = adata.total_square;
        }
      },
      err => {

      }
    );
  }

  saveSession() {
    this.http.post(this.main.server +
      '/stuff/rooms/save-room',
      JSON.stringify(this.room),
      this.main.httpOptions).subscribe(
      data => {
        const adata = data as any;
        if (adata.result === 'ok') {
          this.room = adata.room;
          this.owners = adata.owners;
          this.total_square = adata.totaсl_square;
          this.snackBar.open('Сохранено', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'ok-panel',
            duration: 5000,
          });
        }
      },
      err => {

      }
    );
  }
}
