import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-votes',
  templateUrl: './votes.component.html',
  styleUrls: ['./votes.component.css']
})
export class VotesComponent implements OnInit, AfterViewInit, OnDestroy  {

  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute,
              private router: Router, public dialog: MatDialog, private snackBar: MatSnackBar) {
    this.main.currentMatIcon = 'mobile_friendly';
    this.main.currentHeader = 'Электронные бюллетени';
  }
  displayedColumns: string[] = ['id', 'name', 'fio', 'webCode', 'rooms', 'updated', 'partStep', 'locked', 'sign', 'final_action'];

  data: Vote[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  public updateTimer: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  offset = 0;
  pageSize = 30;

  ngOnInit() {
    this.updateAction(this);
  }



  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => {this.paginator.pageIndex = 0;  this.getVotes(); });
    // this.paginator.page.subscribe(() => this.getVotes());

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.getVotes();
        }),
        map((data) => {
          const d: any = data;
          if (d.result === 'ok'){
            const loadedData = Object.assign([], d.votes) as  Vote[];
            this.isLoadingResults = false;
            this.isRateLimitReached = false;
            this.resultsLength = d.total_count;
            return loadedData;
          }
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data =>
      this.data = data
    );

    // this.getVotes();

  }


  getVotes(){

    return this.http.get<any>(this.main.server + '/evote/evotes?' +
      '&offset=' + (this.pageSize * this.paginator.pageIndex) +
      '&psize=' +  this.pageSize +
      '&sort=' +  (this.sort.direction === 'asc' ? '+' : '-') + this.sort.active,
      this.main.httpOptions);
    // .subscribe(
    //  data => {
    //
//
  //    },
    //  err => {

      // }
    // )
  }

  ngOnDestroy(): void {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
  }

  updateAction(This: any) {
    This.updateTimer = setTimeout(() => {
      This.getVotes().subscribe((dt) => {
        const d: any = dt;
        const data = Object.assign([], d.votes) as  Vote[];
        This.isLoadingResults = false;
        This.isRateLimitReached = false;
        This.resultsLength = d.total_count;
        This.data = data;
        if (This)
        {
          This.updateAction(This);
        }
      });
    }, 2000);
  }

}


export class Vote {
  id: number;
  name: string;
  fio: string;
  delegate: string;
  partStep: number;
  questionStep: number;
  attempts: number;
  locked: boolean;
  webCode: string;
  rooms: string;
  sign: boolean;
  update: Date;
  votedTime: Date;
  time: Date;
}


// attempts: 0
// delegate: ""
// fio: "Литвякова Татьяна Александровна"
// id: 88
// locked: false
// name: ""
// partStep: 4
// questionStep: 0
// rooms: "6; "
// sign: false
// time: "2019-12-12T14:49:52.332Z"
// updated: "2019-12-12T14:51:35.329Z"
// votedTime: "1999-12-31T21:00:00Z"
// webCode: "0492581673"
