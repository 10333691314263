import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {MainService} from '../../main.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
// import {filterStackTrace} from 'protractor/built/util';
// import {style} from '@angular/animations';
// import {PdfViewerComponent} from 'ng2-pdf-viewer';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {
  get ownerName(): string {
    return this._ownerName;

  }

  @Input()
  set ownerName(value: string) {
    this._ownerName = value;
    this.refreshFiles();
  }

  get ownerId(): number {
    return this._ownerId;
  }

  @Input()
  set ownerId(value: number) {
    this._ownerId = value;
    this.refreshFiles();
  }

  @Input()
  public files: IFileView[] = [];

  public uploadFiles: IFileView[] = [];

  public downloadFiles: IFileView[] = [];

  @Input()
  public readOnly = false;


  private _ownerName: string;

  private _ownerId: number;

  @Input()
  public title = 'Файлы';

  @ViewChild('fileInput')
  public fileInput: HTMLInputElement;

  updateProcess = false;
  fileDeleting = false;
  fileSaving = false;

  constructor(private http: HttpClient, public main: MainService,
              private route: ActivatedRoute, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    // window.setTimeout(()=>{
    //   this.refreshFiles();
    // }, 500);
  }

  mergeFile(from: IFile, to: IFile) {
    to.is_public = from.is_public;
    to.description = from.description;
    to.name = from.name;
  }

  refreshFiles() {
    this.updateProcess = true;
    this.http.get(this.main.server + '/stuff/files?target=' + encodeURI(this._ownerName) +
      '&id=' + encodeURI('' + this._ownerId)).subscribe(
      next => {
        try {
          const fls = next as IFile[];
          let i = 0;
          // remove files from list
          while (i < this.files.length) {
            const f = this.files[i];

            if (!fls.find((value, index) => {
              if (value.guid === f.file.guid) {
                return true;
              }
              return false;
            })) {
              this.files.splice(i, 1);
            } else {
              i++;
            }
          }

          // update exists
          for (const f of fls) {
            if (f.guid) {
              if (!this.files.find((value, index) => {
                if (value.file.guid === f.guid) {
                  this.mergeFile(f, value.file);
                  f.guid = null;
                  return true;
                }
                return false;
              })) {
                // add new
                const nfv: IFileView = {
                  imgContent: undefined,
                  pdfContent: undefined,
                  progress: 0,
                  status: '*',
                  expanded: false,
                  changed: false,
                  askDelete: false,
                  file: f
                };
                // {
                //   content: f.content,
                //     content_type: f.content_type,
                //   transmit_type: '',
                //   description: f.description,
                //   guid: f.guid,
                //   is_public: f.is_public,
                //   name: f.name,
                //   owner_id: f.owner_id,
                //   owner_name: f.owner_name,
                //   type: f.type,
                //   uri: f.uri,
                //   url: f.url,
                //   user: ''
                // }
                this.files.push(nfv);
              }
            }
          }
        } catch (ex) {
          console.log(ex);
        } finally {
          this.updateProcess = false;
        }


      },
      err => {
        this.updateProcess = false;
        this.snackBar.open('Не удалось обновить файлы', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'exception-panel',
          duration: 5000,
        });
      }
    );
  }

  uploadFileToServer(file: IFileView) {
    file.status = 'отправка...';
    const data: IFile = file.file;
    data.owner_name = this._ownerName;
    data.owner_id = this._ownerId;
    // file.progress = 0.001;
    this.http.post(this.main.server + '/stuff/files', data, {reportProgress: true, observe: 'events'}).subscribe(
      result => {

        if (result.type === HttpEventType.Sent) {
          file.progress = 0.0001;
        } else if (result.type === HttpEventType.DownloadProgress) {
          console.log('download progress');

        } else if (result.type === HttpEventType.Response) {
          file.progress = 0;
          file.status = 'отправлен.';
          const ind = this.uploadFiles.indexOf(file);
          if (ind > -1) {
            this.uploadFiles.splice(ind, 1);
          }
          const fv: IFileView = {
            imgContent: undefined,
            pdfContent: undefined,
            file: result.body as IFile,
            progress: 0,
            changed: false,
            expanded: true,
            askDelete: false,
            status: ''
          };
          this.files.push(fv);
          console.log('Response');
        } else if (result.type === HttpEventType.ResponseHeader) {
          console.log('ResponseHeader');
        } else if (result.type === HttpEventType.UploadProgress) {
          console.log('UploadProgress');
          file.progress = 100 * result.loaded / result.total;
        } else {
          console.log('Event');
          console.log(result);
        }
      }
      // error => {
      //   file.status = 'ошибка';
      //   this._snackBar.open("Не удалось отправить файлы", 'Ок', {
      //     verticalPosition:'top',
      //     panelClass:'exception-panel',
      //     duration: 5000,
      //   });
      // }
    );

  }

  uploadFile(files) {

    if (files.length > 0) {

      let errors = '';
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < files.length; index++) {
        // check files
        const file = files[index];
        if (file.size > 30000000) {
          errors += 'Файл "' + file.name + '" больше 30 мегабайт;\r\n';

          continue;
        }
      }
      if (errors) {
        this.snackBar.open(errors, 'Ok', {
          verticalPosition: 'top',
          panelClass: 'exception-panel',
          duration: 5000,
        });
        return;
      }

      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < files.length; index++) {
        const file = files[index];

        const reader = new FileReader();
        const extension = /[^.]+$/.exec(file.name);
        let ef: IFileView;
        ef = {
          imgContent: undefined,
          pdfContent: undefined,
          progress: 0.00001,
          status: 'подготовка...',
          expanded: false,
          changed: false,
          askDelete: false,
          file: {
            content: '',
            content_type: file.type,
            transmit_type: '',
            description: '',
            guid: '',
            is_public: 0,
            name: file.name,
            owner_id: this._ownerId,
            owner_name: this._ownerName,
            type: '.' + extension,
            user: '',
            uri: '',
            url: ''
          }
        };
        this.uploadFiles.push(ef);

        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress: number = ((event.loaded / event.total) * 100);
            ef.progress = progress;
          }
        };

        reader.readAsDataURL(file);
        reader.onload = () => {
          ef.file.transmit_type = 'ArrayBuffer';
          ef.file.content = reader.result.toString();
          this.uploadFileToServer(ef);
        };
      }
    }
  }

  browserDownload = (file: IFileView, body) => {
    const obj = URL.createObjectURL(body); //
    const a = document.createElement('a');
    a.setAttribute('href', obj);
    a.hidden = true;
    a.setAttribute('target', '_self');
    a.setAttribute('download', file.file.name);
    a.innerText = 'FILE';
    document.body.appendChild(a);
    a.click();
  }

  showImage = (file: IFileView, body) => {

    if (file.file.type.toLowerCase() === '.pdf') {
      body.arrayBuffer().then(value => file.pdfContent = value);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(body);

      reader.onloadend = () => {

        const base64data = reader.result;
        // base64data.split(';', 1)[1]
        file.imgContent =  (base64data as string).replace('text/html', file.file.content_type);
      };
      // body.arrayBuffer().then(
      //   (value)=>{
      //     // file.pdfContent = value;
      //     let obj = URL.createObjectURL(value);
      //     file.imgContent = obj.toString();
      //   }
      //   );
    }
  }


  downloadFile(file: IFileView, actionAfter) {
    this.downloadFiles.push(file);
    // TODO: Если файл имеет уровень доступа выше дозволенного, нужно вернуть ошибку 403.
    this.http.get(this.main.server + '/stuff/files?guid=' + file.file.guid,
      {reportProgress: true, observe: 'events', responseType: 'blob'}).subscribe(
      result => {

        if (result.type === HttpEventType.Sent) {
          file.progress = 0.0001;
        } else if (result.type === HttpEventType.DownloadProgress) {
          // console.log('download progress');
          file.progress = 100 * result.loaded / result.total;
        } else if (result.type === HttpEventType.Response) {
          file.progress = 0;
          file.status = 'получен.';
          const ind = this.downloadFiles.indexOf(file);
          if (ind > -1) {
            this.downloadFiles.splice(ind, 1);
          }
          file.progress = 0;
          file.status = '';
          actionAfter(file, result.body);

        } else if (result.type === HttpEventType.ResponseHeader) {
        } else if (result.type === HttpEventType.UploadProgress) {
          // file.progress = 100 * result.loaded / result.total;
        } else {
          console.log('Event');
          console.log(result);
        }
      });
    // ).subscribe(
    //   data => {
    //     let blob = data;//.split(',',2);
    //
    //     let obj = URL.createObjectURL(blob);//
    //     let a = document.createElement('a');
    //     a.setAttribute('href', obj);
    //     a.hidden = true;
    //     a.setAttribute('target', '_self');
    //     a.setAttribute('download', file.file.name);
    //     a.innerText = 'FILE';
    //     document.body.appendChild(a);
    //     a.click();
    //   },
    //   err => {
    //     file.status = 'ошибка';
    //     this._snackBar.open('Не удалось скачать файл', 'Ок', {
    //       verticalPosition: 'top',
    //       panelClass: 'exception-panel',
    //       duration: 5000,
    //     });
    //   });

  }

  deleteFile(file: IFileView) {
    this.fileDeleting = true;
    this.http.delete<any>(this.main.server + '/stuff/files?guid=' + file.file.guid
    ).subscribe(
      data => {
        try {
          const ind = this.files.findIndex(x => x.file.guid === data.guid);
          if (ind > -1) {
            this.files.splice(ind, 1);
          }
        } finally {
          this.fileDeleting = false;
        }
      },
      err => {
        file.status = 'ошибка';
        this.fileDeleting = false;
        this.snackBar.open('Не удалось отправить файл', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'exception-panel',
          duration: 5000,
        });
      });

  }

  changeFile(file: IFileView) {
    this.fileSaving = true;
    this.http.put(this.main.server + '/stuff/files', file.file
    ).subscribe(
      data => {
        try {
          this.mergeFile(data as IFile, file.file);
          file.changed = false;
        } finally {
          this.fileSaving = false;
        }
      },
      err => {
        file.status = 'ошибка';
        this.fileSaving = false;
        this.snackBar.open('Не удалось изменить файл', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'exception-panel',
          duration: 5000,
        });
      });

  }


  fileNameChange(file: IFileView) {
    file.changed = true;
  }

  fileDescriptionChange(file: IFileView) {
    file.changed = true;
  }

  fileIsPublicChange(file: IFileView) {
    file.changed = true;
  }


  askToDelete(file: IFileView) {
    file.askDelete = true;
    window.setTimeout(() => {file.askDelete = false; }, 3000);
  }

  showFile(file: IFileView) {

  }
}


export interface IFile {
  type: string;
  name: string;
  description: string;
  content_type: string;
  transmit_type: string;
  content: string; // TODO: may be Blob?
  owner_name: string;
  owner_id: number;
  user: string;
  guid: string;
  uri: string;
  url: string;
  is_public: number; // if 0 - доступен всем, если 1 - доступен всем зарегистрированным, если 2 - доступен администрации

}

export interface IFileView {
  progress: number;
  status: string;
  file: IFile;
  expanded: boolean;
  changed: boolean;
  askDelete: boolean;

  pdfContent: any;
  imgContent: any;

}
