import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-document-generator',
  templateUrl: './document-generator.component.html',
  styleUrls: ['./document-generator.component.css']
})
export class DocumentGeneratorComponent implements OnInit {
  reestrOwnersMembers = 'false';
  reestrOwnersOrders = 'fio';

  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute,
              private router: Router, public dialog: MatDialog, private snackBar: MatSnackBar) {
    this.main.currentMatIcon = 'work';
    this.main.currentHeader = 'Документы';
  }

  ngOnInit(): void {
  }

  generateDoc(params: any) {
    this.http.post<any>(this.main.server + '/stuff/documents/generate', params).subscribe(
      next => {
        const code = next.code;
        const url = this.main.server + '/stuff/documents/get-file?code=' + code;
        window.open(url, '_blank');
      },
      err => {
        this.snackBar.open('Не удалось сгенерировать документ', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'error-panel',
          duration: 5000,
        });
      }
    );

  }
}
