import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MainService} from '../main.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';


import YAML from 'yaml';
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';


import {
  Arguments,
  Context,
  ContextOssQuestion,
  ContextOssRoom,
  IHome,
  Oss,
  QrData,
  QrOssRoom,
  Question,
  Result,
  Rule
} from './models';


import {IFile, IFileView} from '../forms/files/files.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
  selector: 'app-oss',
  templateUrl: './oss.component.html',
  styleUrls: ['./oss.component.sass']
})
export class OssComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  public ossId: number;
  bulletinSendTemplateResult: string;
  createPDFResult: any;
  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute,
              private snackBar: MatSnackBar) {
    this.main.currentMatIcon = 'how_to_vote';
    this.main.currentHeader = 'Собрание';
    this.subscription = this.searchValueControl.valueChanges
      .pipe(
        debounceTime(this.debounceTime), // Waiting for 1 sec while you are typing
        distinctUntilChanged() // Prevents the emitting if the 'start' value and the 'end' value are the same
      )
      .subscribe(value => {
          console.log(value);
          if (this.searchValue !== value) {

            this.searchValue = value;
            this.selectData();
          }
          // TODO: call BE here with this.httpClient...
        }
      );
  }

  get answersCount() {
    if (this.ossValue.bulletin.questions) {
      return this.ossValue.bulletin.questions.filter(x => x.type === 'question').length;
    } else {
      return 0;
    }
  }
  bulletinContextError = '';
  context: Context =  {
    TotalOwnSquare: 0,
    HumanGuid: '',
    OssGuid: '',
    Address: '',
    Answers: '',
    Delegate: '',
    HomeSquare: 0,
    LongDate: '',
    OssForm: '',
    Owner: '',
    Questions: [],
    Rooms: [],
    Rules: [],
    Time: {
      show: {
        from: {
          time: '',
          date: ''
        },
        to: {
          time: '',
          date: ''
        },
      },
      back: {
        from: {
          time: '',
          date: ''
        },
        to: {
          time: '',
          date: ''
        },
      }
    },
    Title: '',
    WebCode: '',
    qrValue: '1234567890'
  };

  searchProcess: boolean;
  public searchValueControl = new UntypedFormControl('');
  humans: any[];
  searchValue = '';
  debounceTime = 1000;
  private subscription: Subscription;

  public ossValue = new Oss();
  nameError: string;
  showStartDateError: string;
  showEndDateError: string;
  backStartDateError: string;
  backEndDateError: string;
  commonError: string;
  isTsnMemberError: string;
  editMode = true;
  homes: IHome[];
  testAnswers = '';
  lastQuestionNumber = 0;
  lastSubQuestionNumber = 0;
  questionOrder = 0;
  voteId = 0;
  loadOss = false;

  humanId = 0;

  selectHomeFormControl = new UntypedFormControl();
  editor1Config = {
    base_url: '/tinymce',
    suffix: '.min',
    height: 500,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help'
  };
  LongDate: string;
  public bulletinContext: string;
  Templates: string[];
  selectedTemplate = '';
  pdfSrc: any = undefined;
  bulletinArguments: any;
  ownerData: any;
  testDelegate: string;
  allowPublish: boolean;
  allowClose: boolean;
  openCloseRequesting = false;
  ossFiles: IFileView[] = [];


   changeBulletinContext() {
    this.bulletinContextError = '';
    try {
      const val = YAML.parse(this.bulletinContext);
      this.context = val;
    } catch (e) {
      this.bulletinContextError = e;
    }

  }

  selectData(){


    this.http.get(this.main.server +
      '/stuff/search-owners?' +
      'search=' + encodeURI(this.searchValue),
      this.main.httpOptions).subscribe(
      data => {
        const dataAny = data as any;
        this.humans = dataAny.data;


      },
      err => {

      }
    );
  }


  ngOnInit() {
    let ossIdStr = this.route.snapshot.queryParamMap.get('id');
    this.getTemplates();
    if (ossIdStr) {
      this.editMode = false;
    } else {
      ossIdStr = '0';
    }
    this.ossId = parseInt(ossIdStr, 10);
    console.log('oss_id=' + this.ossId);
    if (this.ossId > 0) {
      this.loadOss = true;
      this.http.get(this.main.server + '/stuff/oss?id=' + this.ossId, this.main.httpOptions).subscribe(
        data => {
          try {
            // alert(data)
            const dataAny = data as any;
            const item = dataAny.data[0];
            this.ossValue = (Object.assign(new Oss(), item) as Oss);
            this.ossId = this.ossValue.id;
            this.bulletinContext = this.ossValue.bulletin.context;
            this.context = YAML.parse(this.ossValue.bulletin.context).implement;

            this.correctCalculateBulletin();
            // alert(JSON.stringify(this.ossValue))
            // this. = data['data'];//JSON.parse(data.toString());
          } finally {
            this.loadOss = false;
          }
        },
        err => {

        }
      );
    }
    this.getHomes();
  }

  getTemplates() {
    this.http.get<any>(this.main.server + '/stuff/oss/template?name=' + encodeURIComponent(this.selectedTemplate)).subscribe(
      data => {
        this.Templates = data.names;
        if (data.template) {
           this.ossValue.bulletin.definition = data.template.definition;
        }
      },
      err => {

      }
    );
  }

  getHomes(){

    this.http.get(this.main.server + '/stuff/home/get-homes', this.main.httpOptions).subscribe(
      data => {
        const dataAny = data as any;
        this.homes = dataAny.homes;
      },
      err => {

      }
    );
  }

  validate() {
    if (!this.ossValue.title) {
      this.nameError = 'Имя должно быть определено';
      this.snackBar.open('Уникальное имя собрания должно быть определено', 'Ок', {
        verticalPosition: 'top',
        panelClass: 'exception-panel',
        duration: 5000,
      });
      return false;
      // alert(this.nameError + "\'"+ this.ossValue.title+"\'")
    } else {
      this.nameError = null;
    }

    // if (this.ossValue.is_tsn_member == null) {
    //   this.isTsnMemberError = "Вы должны определить, является ли собрание, собранием членов ТСН"
    //   //alert(this.nameError + "\'"+ this.ossValue.title+"\'")
    // } else {
    //   this.isTsnMemberError = null;
    // }


    if (!this.ossValue.show_start) {
      this.showStartDateError = 'Время начала должно быть определено';
      this.snackBar.open('Время начала очной части должно быть определено', 'Ок', {
        verticalPosition: 'top',
        panelClass: 'exception-panel',
        duration: 5000,
      });
      return false;
      // alert(this.startDateError + "\'"+ this.ossValue.start_time+"\'")
    } else {
      this.showStartDateError = null;
    }

    if (!this.ossValue.show_end) {
      this.showEndDateError = 'Время окончания должно быть определено';
      this.snackBar.open('Время окончания очной части должно быть определено', 'Ок', {
        verticalPosition: 'top',
        panelClass: 'exception-panel',
        duration: 5000,
      });

      return false;
      // alert(this.endDateError + "\'"+ this.ossValue.end_time+"\'")
    } else {
      this.showEndDateError = null;
    }

    if (!this.ossValue.back_start) {
      this.backStartDateError = 'Время начала должно быть определено';
      this.snackBar.open('Время начала заочной части должно быть определено', 'Ок', {
        verticalPosition: 'top',
        panelClass: 'exception-panel',
        duration: 5000,
      });
      return false;
      // alert(this.startDateError + "\'"+ this.ossValue.start_time+"\'")
    } else {
      this.backStartDateError = null;
    }

    if (!this.ossValue.back_end) {
      this.backEndDateError = 'Время окончания должно быть определено';
      this.snackBar.open('Время окончания заочной части должно быть определено', 'Ок', {
        verticalPosition: 'top',
        panelClass: 'exception-panel',
        duration: 5000,
      });
      return false;
      // alert(this.endDateError + "\'"+ this.ossValue.end_time+"\'")
    } else {
      this.backEndDateError = null;
    }

    if (!this.showStartDateError && !this.showStartDateError &&  !this.backStartDateError && !this.backEndDateError
      && (this.ossValue.show_start > this.ossValue.show_end || this.ossValue.back_start > this.ossValue.back_end
        || this.ossValue.show_end > this.ossValue.back_start) ) {
      this.showStartDateError = this.showEndDateError = this.backStartDateError = this.backEndDateError = 'Даты не должны быть равны и конец должен быть раньше начала';
      this.snackBar.open(this.showStartDateError, 'Ок', {
        verticalPosition: 'top',
        panelClass: 'exception-panel',
        duration: 5000,
      });
      return false;
    }
    else{
      this.showStartDateError = this.showEndDateError = this.backStartDateError = this.backEndDateError = null;
    }
    this.ossValue.start_time = this.ossValue.show_start;
    this.ossValue.end_time = this.ossValue.back_end;
    if (this.showStartDateError != null || this.showStartDateError != null || this.backStartDateError != null
      || this.backEndDateError != null || this.nameError != null || this.isTsnMemberError != null) {
      return false;
    }

    if (!this.ossValue.oss_form) {
      this.snackBar.open('Не выбрана форма пробещения собрания', 'Ок', {
        verticalPosition: 'top',
        panelClass: 'exception-panel',
        duration: 5000,
      });
      return false;
    }
    if (!this.ossValue.bulletin.questions){
      this.ossValue.bulletin.questions = [];
    }
    for (const q of this.ossValue.bulletin.questions) {
      if (!q.title) {
        this.snackBar.open('У вопроса ' + q.number + ' должен быть заголовок (короткое обозначение, название)', 'Ок', {
          verticalPosition: 'top',
          panelClass: 'exception-panel',
          duration: 5000,
        });
        return false;
      }
      if (q.type === 'question' || q.type === 'sub-question') {

        if (!q.decision) {
          this.snackBar.open('У вопроса ' + q.number + ' должно быть решение', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'exception-panel',
            duration: 5000,
          });
          return false;
        }
        if (!q.quorum_title){

          this.snackBar.open('У вопроса ' + q.number + ' должен быть выбран кворум', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'exception-panel',
            duration: 5000,
          });
          return false;
        }
        if (q.quorum_title === '50%') { q.quorum = 0.5; }
        else if (q.quorum_title === '2/3') { q.quorum = 0.666666666; }
        else if (q.quorum_title === '100%') { q.quorum = 1; }

      } else if (q.type === 'header') {
        if (!q.quorum || !q.quorum_title) {
          q.quorum = 0;
          q.quorum_title = '-';
        }

        if (!q.agenda) {
          this.snackBar.open('У раздела ' + q.number + ' должна быть повестка', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'exception-panel',
            duration: 5000,
          });
          return false;
        }

      } else if (q.type === 'sub-header') {
        if (!q.quorum || !q.quorum_title){
          q.quorum = 0;
          q.quorum_title = '-';
        }
        if (!q.agenda) {
          this.snackBar.open('У раздела ' + q.number + ' должна быть повестка', 'Ок', {
            verticalPosition: 'top',
            panelClass: 'exception-panel',
            duration: 5000,
          });
          return false;
        }

      } else {
        return false;
      }

    }

    return true;
  }

  numberChanged($event: Event, q: Question) {
    // alert("!")
    let errr = false;
    const nm = q.number;
    let nnm = '';

    for (const n of nm){
      if (n === '.' || (n >= '0' && n <= '9') ) {
        nnm += n;
      }
      else{
        errr  = true;
      }
    }
    if (errr){
      if (q.number !== nnm) { q.number = nnm; }

    }
    // this.correctCalculateBulletin();

  }




  correctCalculateBulletin() {
    if (this.ossValue && this.ossValue.bulletin && this.ossValue.bulletin.questions) {

      for (let i = 0; i < this.ossValue.bulletin.questions.length;) {

        const q = this.ossValue.bulletin.questions[i] as Question;
        if (q.title) {
          i++;
        } else {
          this.ossValue.bulletin.questions.splice(i, 1);
          console.log(this.ossValue.bulletin.questions);
        }

      }

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.ossValue.bulletin.questions.length; i++) {

        const q = this.ossValue.bulletin.questions[i] as Question;

        // console.log(q);
        // console.log(q.number);
        const n = q.number;
        const ns = n.split('.');
        let sort = 0.0;
        sort += parseFloat(ns[0]);
        // console.log("0)" + sort)
        // console.log("ns len = " + ns.length)
        let nm = ns[0];
        for (let ind = 1; ind < ns.length; ind++) {
          const iv = ns[ind];
          const v = 0;
          if (iv) {
            // tslint:disable-next-line:no-shadowed-variable
            const v = '0.' + iv.padStart(ind * 2, '0');
            nm += '.' + iv;
            sort += parseFloat(v);
            //  console.log(ind + ")" + sort)

          }

        }

        if (q.number !== nm) { q.number = nm; }
        q.order = sort;

        if (q.quorum_title === '50%') { q.quorum = 0.5; }
        else if (q.quorum_title === '2/3') { q.quorum = 0.666666666; }
        else if (q.quorum_title === '100%') { q.quorum = 1; }
      }

      this.ossValue.bulletin.questions = this.ossValue.bulletin.questions.sort(
        (a, b) => {
          return (a.order - b.order);
        });

      // for (let i = this.ossValue.bulletin.questions.length - 1; i >= 0; i++) {
      //   let q = this.ossValue.bulletin.questions[i];
      //   console.log(q);
      //   console.log(q.number);
      //
      // }

      let lastCount = 0;

      for (let i = this.ossValue.bulletin.questions.length - 1; i >= 0; i--) {
        const q = this.ossValue.bulletin.questions[i];
        // console.log(q);
        // console.log(q.number);
        const ns = q.number.split('.');
        const count = ns.length;
        if (lastCount === 0 || lastCount === count || lastCount < count) {
          if (count > 1) {
            q.type = 'sub-question';
          } else if (count === 1) {
            q.type = 'question';
          }
        } else if (lastCount > count) {
          q.type = 'header';
        }

        lastCount = count;
      }
    }
  }


  createOSS() {
    // alert("!")
    if (!this.validate()) { return; }
    // alert("!")

    this.correctCalculateBulletin();

    // alert(JSON.stringify(this.ossValue.bulletin.questions))

    this.http.post(this.main.server + '/stuff/oss', JSON.stringify(this.ossValue), this.main.httpOptions).subscribe(
      result => {
        this.ossValue = (Object.assign(new Result(), result) as Result).data; // data['data'];
        this.correctCalculateBulletin();
        // alert(JSON.stringify(this.ossValue))
      },
      err => {
        this.commonError = err.exception;
      }
    );

  }

  saveOSS() {
    if (!this.validate()) { return; }
    this.correctCalculateBulletin();
    this.http.post<Result>(this.main.server + '/stuff/oss', JSON.stringify(this.ossValue), this.main.httpOptions).subscribe(
      result => {
        this.ossValue = (Object.assign(new Result(), result) as Result).data[0];
        this.correctCalculateBulletin();
      },
      err => {
        this.commonError = err.exception;
      }
    );
  }

  createBulletin() {

  }

  viewBulletin() {

  }



  addQuestion() {
    this.correctCalculateBulletin();
    const q = new Question();
    if (!this.ossValue.bulletin.questions){
      this.ossValue.bulletin.questions = [];

    }
    const qs = this.ossValue.bulletin.questions;
    if ( qs.length === 0)
    {
      q.number = '1';
//      console.log("create num:" + q.number);
    }
    else
    {
      const lq = qs[qs.length - 1];
 //     console.log("create num: last Q:" );
   //   console.log(lq);
     // console.log("create q.num: " + lq.number);

      const ns = lq.number.split('.');
     // console.log("create num: ns=" + ns);
      const lind = ns.length - 1;
      const lastNum = ns[lind];
     // console.log("create num: last_num" + last_num);
      const num = parseInt(lastNum, 10);
     // console.log("create num: num" + num);
      ns[lind] = '' + (num + 1);
      q.number = ns.join('.');
     // console.log("create num:" + q.number);
    }


    this.ossValue.bulletin.questions.push(q);
    // console.log(qs);
  }

  addHeader() {
    const q = new Question();
    this.lastQuestionNumber = this.lastQuestionNumber + 1;
    this.lastSubQuestionNumber = 0;
    q.number = '' + this.lastQuestionNumber;
    q.type = 'header';
    this.questionOrder = this.questionOrder + 1;
    q.order = this.questionOrder;

    this.ossValue.bulletin.questions.push(q);
    this.addSubQuestion();

  }

  addSubQuestion() {
    const q = new Question();
    this.lastSubQuestionNumber = this.lastSubQuestionNumber + 1;
    q.number = '' + this.lastQuestionNumber + '.' + this.lastSubQuestionNumber + '.';
    q.type = 'sub-question';
    this.questionOrder = this.questionOrder + 1;
    q.order = this.questionOrder;
    this.ossValue.bulletin.questions.push(q);
  }


  selectHomeValuesChanged($event: any) {
    if (this.ossValue.selected_homes.length === 0){
      this.context.Address = '';
    } else {
      const h = this.homes.find(x => x.name === this.ossValue.selected_homes[0]);
      if (h)
      {
        this.context.Address = h.address;
      }
    }
  }
  getLongDate(date: Date) {
    const m = this.main.monthes[date.getMonth() + 1];
    const dd = date.getDate();
    const yyyy = date.getFullYear();
    const hd = '" ' + dd + ' " ' + m + '  ' + yyyy + 'г.';
    return hd;
  }
  makeImplementRaw(): string {
    const selHomes = this.ossValue.selected_homes;
    let square = 0;
    for (const home of selHomes) {
      const h = this.homes.find(x => x.name === home);
      if (h) {
        square += h.ppd_square;
      }
    }
    const datePipe = new DatePipe('en-US');

    const questions = [];
    let ai = 0;
    let answers = '';
    if (this.ossValue.bulletin.questions) {
      for (const q of this.ossValue.bulletin.questions) {

        const contextQuestion: ContextOssQuestion = {
          Type: q.type,
          QuestionNumber: q.number,
          QuestionAgenda: q.agenda,
          QuestionDecision: q.decision,
          AnswerIndex: ai
        };
        questions.push(contextQuestion);
        if (q.type === 'question') {
          ai++;
          answers += '?';
        }
      }
    } else {

    }
    let hd = '"__"________.20____г.';
    if (this.LongDate) {
      const d = new Date(this.LongDate);

      hd = this.getLongDate(d);
    }
    if (!this.context.Time)
    {
      this.context.Time = {
        show: {
          from: {
            time: '',
            date: ''
          },
          to: {
            time: '',
            date: ''
          },
        },
        back: {
          from: {
            time: '',
            date: ''
          },
          to: {
            time: '',
            date: ''
          },
        }
      };
    }
    this.context.Time.show.from.time = datePipe.transform(this.ossValue.show_start, 'HHчас. mmмин.');
    this.context.Time.show.from.date = datePipe.transform(this.ossValue.show_start, 'dd.MM.yyyy');

    this.context.Time.show.to.time = datePipe.transform(this.ossValue.show_end, 'HHчас. mmмин.');
    this.context.Time.show.to.date = datePipe.transform(this.ossValue.show_end, 'dd.MM.yyyy');


    this.context.Time.back.from.time = datePipe.transform(this.ossValue.back_start, 'HHчас. mmмин.');
    this.context.Time.back.from.date = datePipe.transform(this.ossValue.back_start, 'dd.MM.yyyy');

    this.context.Time.back.to.time = datePipe.transform(this.ossValue.back_end, 'HHчас. mmмин.');
    this.context.Time.back.to.date = datePipe.transform(this.ossValue.back_end, 'dd.MM.yyyy');

    this.context.LongDate = hd;
    this.context.OssForm = this.ossValue.oss_form;
    this.context.HomeSquare = square;
    this.context.Questions = questions;
    this.context.OssGuid = this.ossValue.guid;

    let cntx: any =  this.context;
    if (cntx.implement){
      cntx = cntx.implement;
    }
    const fullContext: { implement: Context } = {
      implement: cntx,
    };
    const contextString = YAML.stringify(fullContext);
    this.ossValue.bulletin.context = contextString;

    return contextString;
  }
  generatePDF() {
    this.makeImplementRaw();
    if (this.humanId) {
      this.getOwnerData(this.humanId);
    }
    let value = this.ossValue.bulletin.definition + '\r\n' + this.ossValue.bulletin.context;
    if (this.bulletinArguments) {
      value += '\r\n' + this.bulletinArguments;
    }
    const sendData = {
      type: 'application/yaml',
      value
    };
    this.bulletinSendTemplateResult = value;
    this.http.post(this.main.server + '/stuff/oss/bulletin', sendData, { responseType: 'blob'}).subscribe(
      data => {
        // const blob = new Blob([data], { type: 'application/pdf' });
        // const url= window.URL.createObjectURL(data);
        if (data.size < 10000){
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const text = e.target.result;
            this.createPDFResult = text;
          };

          reader.readAsText(data);
        } else {
          // tslint:disable-next-line:no-shadowed-variable
         data.arrayBuffer().then(value => this.pdfSrc = value);
         this.createPDFResult = '';
        }
        // console.log(this.pdfSrc);
        // window.open(url, '_blank');

      },
      error => {
        console.log(error);
      });
    }

  downloadPDF() {
    this.makeImplementRaw();
    if (this.humanId) {
      this.getOwnerData(this.humanId);
    }
    let value = this.ossValue.bulletin.definition + '\r\n' + this.ossValue.bulletin.context;
    if (this.bulletinArguments) {
      value += '\r\n' + this.bulletinArguments;
    }
    const sendData = {
      type: 'application/yaml',
      value
    };
    this.http.post(this.main.server + '/stuff/oss/bulletin', sendData, { responseType: 'blob'}).subscribe(
      data => {
        // const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(data);
        // data.arrayBuffer().then(value=>this.pdfSrc = value);
        // console.log(this.pdfSrc);
        window.open(url, '_blank');

      },
      error => {
        console.log(error);
      });
  }

  changeShowStart() {
    const d = new Date(this.ossValue.show_start);
    const z = d.getTimezoneOffset() / 60;

    this.ossValue.show_end = new Date(d.getTime() + (-z * 3600000) + 3600000).toISOString().substr(0, 16);
    this.ossValue.back_start = new Date(d.getTime() + (-z * 3600000) + 3600000).toISOString().substr(0, 16);
    this.ossValue.back_end = new Date(d.getTime() + (-z * 3600000) + 2592000000).toISOString().substr(0, 16);
  }

  addRule() {
    if (!this.context.Rules) {
      this.context.Rules = [];
    }
    this.context.Rules.push(new Rule());
  }

  removeLastRule() {
    if (!this.context.Rules) {
      this.context.Rules = [];
    }
    this.context.Rules.splice(this.context.Rules.length - 1, 1);
  }

  humanSelected($event: MatAutocompleteSelectedEvent) {
    console.log($event);
    const human = $event.option.value;

    this.humanId =  human[0];
    this.voteId =  0;
    this.searchValue = human[1];
    this.searchValueControl.setValue(human[1]);

    // this.searchValueControl.setValue(human.human);
    // console.log(this.getBulleten);

    this.getOwnerData(this.humanId);
  }
  private getOwnerData(humanId: number) {
    this.http.get(this.main.server +
      '/stuff/resident?' +
      'human_id=' + humanId,
      this.main.httpOptions).subscribe(
      data => {
        this.ownerData = data;
        const d: any = data;

        this.formatArguments();

      },
      err => {

      }
    );
  }

  formatArguments() {
    const datePipe = new DatePipe('en-US');
    const rooms: ContextOssRoom[] = [];
    const qrrooms: QrOssRoom[] = [];
    if (this.ownerData.rooms) {
      for (const r of this.ownerData.rooms) {
        const room: ContextOssRoom = {
          EGRN: r.reg_number,
          EGRNDate: datePipe.transform(r.reg_date, 'dd.MM.yyyyг.'),
          RoomName: r.owner_title,
          RoomOwn: r.own_part_title,
          RoomSquare: r.room_square,
          OwnSquare: r.own_part_square,

        };

        const qrroom: QrOssRoom = {
          E: r.reg_number,
          ED: datePipe.transform(r.reg_date, 'dd.MM.yyyyг.'),
          RN: r.owner_title,
          RO: r.own_part_title,
          RS: r.room_square,
          OS: r.own_part_square,
        };
        rooms.push(room);
        qrrooms.push(qrroom);
      }
    }
    const  d = new Date();

    let hd = '"__"________.2020г.';
    if (this.LongDate) {
      // tslint:disable-next-line:no-shadowed-variable
      const d = new Date(this.LongDate);

      hd = this.getLongDate(d);
    }
    const qrData: QrData = {
      OG: this.ossValue.guid,
      HG: this.ownerData.human_guid,
      OW: this.ownerData.fio,
      TOS: this.ownerData.total_own_square,
      RS: qrrooms,
      ANS: this.testAnswers,
      DL: this.testDelegate,
      D: hd,
      CD: '-----------'
    };
    const qr = JSON.stringify(qrData);
    const cntx: Arguments = {
      OssGuid: this.ossValue.guid,
      HumanGuid: this.ownerData.human_guid,
      qrValue: qr,
      Owner: this.ownerData.fio,
      TotalOwnSquare: this.ownerData.total_own_square,
      Rooms: rooms,
      Answers: this.testAnswers,
      Delegate: this.testDelegate,
      LongDate: hd,
      WebCode: '-----------'
    };



    const arg = {
      arguments: cntx
    };
    const args = YAML.stringify(arg);
    this.bulletinArguments = args;
  }
  publishOSS() {
    this.openCloseRequesting = true;
    this.http.put(this.main.server + '/stuff/oss/publish', { oss_id: this.ossValue.id }).subscribe(
      data => {
        this.ossValue = (Object.assign(new Result(), data) as Result).data[0];
        this.correctCalculateBulletin();
        this.allowPublish = false;
        this.openCloseRequesting = false;
      }, error => {

    });
  }

  closeOSS() {
    this.openCloseRequesting = true;
    this.http.put(this.main.server + '/stuff/oss/close', { oss_id: this.ossValue.id }).
    subscribe(
      data => {
        this.ossValue = (Object.assign(new Result(), data) as Result).data[0];
        this.correctCalculateBulletin();
        this.allowClose = false;
        this.openCloseRequesting = false;
    }, error => {

    });
  }
}
