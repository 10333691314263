import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';
//https://medium.com/@mariemchabeni/angular-7-drag-and-drop-simple-file-uploadin-in-less-than-5-minutes-d57eb010c0dc
@Directive({
  selector: '[addUploadDragDrop]'
})
export class AddUploadFileDirective {

  @Output()
  onFileDropped = new EventEmitter<any>();
  @Input()
  dragOverTitle='Перетащите сюда файлы';

  @HostBinding('style.background-color')
  private background = '';
  @HostBinding('style.opacity')
  private opacity = '1';
  @HostBinding('style.border')
  private border = '';
  @HostBinding('style.border-radius')
  private border_radius = "10px";
  //Dragover listener
  @HostListener('dragover', ['$event'])
  onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#9ecbec';
    this.border = 'aqua 3px dashed';
    this.opacity = '0.8'
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';
    this.border = '';
    this.opacity = '1'
  }

  //Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';
    this.opacity = '1';
    this.border = '';
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files)
    }
  }

}
