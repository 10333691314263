<div>
  <form style="">
    <!--  <h2 style="text-align: center; vertical-align: center"><mat-icon style="vertical-align: sub; font-size: 40px; margin-right: 15px;">home</mat-icon>Главная панель</h2>-->

    <mat-form-field class="example-full-width" style="width: 100%;  margin-top: 0;font-family: inherit; ">
      <mat-label>Ф.И.О. или номер помещения</mat-label>
      <input style="font-size:x-large; font-weight: bold; " placeholder="Ф.И.О. или номер помещения" matInput
             [matAutocomplete]="auto" [formControl]="searchvalue" name="searchvalue">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="humanSelected($event)">
        <mat-option *ngFor="let entry of humans" [value]="entry">
          <span>{{entry[1]}}
            <ng-container *ngIf="entry[2]">({{entry[2]}})</ng-container> <ng-container
              *ngIf="entry[4]">- {{entry[4]}}</ng-container></span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="full-width" style="z-index: 100;">

        <mat-toolbar color="primary" style="min-width: 100%">
          <mat-toolbar-row>

            <button mat-button class="animated" [disabled]="humanId!=0 || !searchValue" [routerLink]="['/add-human']"
                    [queryParams]="{fio: searchValue}" matTooltip="Добавить жителя">
              <mat-icon>person_add</mat-icon>
            </button>
            <button mat-button class="animated" [disabled]="humanId==0" [routerLink]="['/edit-human-owns']"
                    [queryParams]="{human_id: humanId}" matTooltip="Редактировать собственности">
              <mat-icon>business</mat-icon>
            </button>

            <ng-container *ngIf="info && info.oss_info && info.oss_info.current_oss && info.oss_info.current_oss.length>0">
              <app-vote-menu *ngFor="let oss of info.oss_info.current_oss" [oss]="oss" [human]="human"></app-vote-menu>
            </ng-container>
<!--            <button mat-button [matMenuTriggerFor]="menu" *ngIf="info && info.oss_info && info.oss_info.current_oss && info.oss_info.current_oss.length>0"><mat-icon>pan_tool</mat-icon></button>-->
<!--            <mat-menu #menu="matMenu" >-->
<!--              <button mat-button class="animated"-->
<!--                      *ngIf="info && info.oss_info && info.oss_info.current_oss && info.oss_info.current_oss.length>0"-->
<!--                      [disabled]="!allowVote()" (click)="generateBulletin(true)"-->
<!--                      matTooltip="Сгенерировать и открыть бюллетень (Решение) для выбранного собственника">-->
<!--                <mat-icon>print</mat-icon>-->
<!--              </button>-->
<!--              <button mat-button class="animated"-->
<!--                      *ngIf="info && info.oss_info && info.oss_info.current_oss && info.oss_info.current_oss.length>0"-->
<!--                      [disabled]="!allowVote()" (click)="generateOssLink(true)"-->
<!--                      matTooltip="Показать / копировать ссылку на голосовалку">-->
<!--                <mat-icon>link</mat-icon>-->
<!--              </button>-->
<!--              <button mat-button class="animated"-->
<!--                      *ngIf="info && info.oss_info && info.oss_info.current_oss && info.oss_info.current_oss.length>0"-->
<!--                      [disabled]="!allowVote()" [routerLink]="['/bulletin']"-->
<!--                      [queryParams]="{human_id: human_id, oss_id: info.oss_info.current_oss[0].oss_id}"-->
<!--                      matTooltip="Заполнить решение">-->
<!--                <mat-icon>ballot</mat-icon>-->
<!--              </button>-->
<!--              <button mat-button class="animated"  *ngIf="info && info.oss_info && info.oss_info.current_oss && info.oss_info.current_oss.length>0" (click)="scanBarcode()" matTooltip="Сканировать QR-код решения">-->
<!--                <mat-icon>qr_code</mat-icon>-->
<!--              </button>-->
<!--            </mat-menu>-->

            <button mat-button class="animated" [disabled]="humanId==0" [routerLink]="['/human']"
                    [queryParams]="{human_id: humanId}" matTooltip="Посмотреть инфо о жителе">
              <mat-icon>assignment_ind</mat-icon>
              <span></span></button>
            <button mat-button class="animated" [disabled]="roomId==0" [routerLink]="['/room']"
                    [queryParams]="{room_id: roomId}" matTooltip="Посмотреть инфо о помещении">
              <mat-icon>meeting_room</mat-icon>
            </button>


          </mat-toolbar-row>
        </mat-toolbar>

  </div>
  <div class="animated human-copy-bulletin-url" [class.show]="showHumanCopyBulletinUrl">
    <button mat-mini-fab (click)="navigateToOssLink()" color="primary" style="margin-top: -7px;" ><mat-icon>launch</mat-icon></button>
    <button mat-mini-fab (click)="showQr=true;" color="primary" style="margin-top: -7px;" ><mat-icon>qr_code</mat-icon></button>
    <input id="copyToClipboardElement" style="margin-left: 0; " >

  </div>

  <div *ngIf="info && info.errors" >
    <mat-error *ngFor="let er of info.errors"><mat-icon>error</mat-icon> {{er}}</mat-error>
  </div>

<!--  ================================================ CURRENT OCC ==============================-->

  <ng-container *ngIf="info && info.oss_info.current_oss && info.oss_info.current_oss.length>0">
    <h4>Текущие голосования</h4>
    <div style="width: 100%; overflow-x: auto;">
      <table *ngIf="info" class="search_result full_width oss-table" style=" background: #c8ffd6;" border="1" cellpadding="5" >
      <tr *ngFor="let inf of info.oss_info.current_oss">
        <td style="width: 150px;">
          <p *ngIf="!inf.is_tsn_member">ОС собственников:</p>
          <p *ngIf="inf.is_tsn_member">ОС членов ТСН:</p>
          <b>{{inf.title}}</b>
          <div *ngIf="inf.description"><hr/>{{inf.description}}</div>
        </td>
        <td>
          c {{getDateTime(inf.start_time)}}<br/>по {{getDateTime(inf.end_time)}}
        </td>
        <td>
          <table *ngIf="!inf.is_tsn_member" class="vote-table">
            <tr>
              <td>
                Всего зарегистрированных голосов от полезной площади дома (ППД)
              </td>
              <td>
                <b>{{inf.total_common_votes.toFixed(2)}}</b>м2
              </td>
            </tr>
            <tr>
              <td>
                Всего зарегистрированных голосов % от ППД
              </td>
              <td>
                <b>{{ (100*inf.total_common_votes/inf.passport_votes).toFixed(2)}}</b>%
              </td>
            </tr>
            <tr>
              <td>
                Всего проголосовало
              </td>
              <td>
                <b>{{inf.total_common_voted.toFixed(2)}}</b>м2
              </td>
            </tr>
            <tr>
              <td>
                Кворум всего дома
              </td>
              <td>
                <b>{{ (100*inf.total_common_voted/inf.passport_votes).toFixed(2)}}</b>%
              </td>
            </tr>
            <tr>
              <td>
                Потенциальный кворум всего дома
              </td>
              <td>
                <b>{{ (100*inf.total_common_potential_voted/inf.passport_votes).toFixed(2)}}</b>%
              </td>
            </tr>
            <tr>
              <td>
                Кворум от зарегистрированных
              </td>
              <td>
                <b>{{ (100*inf.total_common_voted/inf.total_common_votes).toFixed(2)}}</b>%
              </td>
            </tr>
          </table>

          <table *ngIf="inf.is_tsn_member" class="vote-table">
            <tr>
              <td>
                Всего голосов членов ТСН от полезной площади дома (ППД)
              </td>
              <td>
                <b>{{inf.total_tsn_votes.toFixed(2)}}</b>м2
              </td>
            </tr>
            <tr>
              <td>
                Всего голосов членов ТСН % от ППД
              </td>
              <td>
                <b>{{ (100*inf.total_tsn_votes/inf.passport_votes).toFixed(2)}}</b>%
              </td>
            </tr>
            <tr>
              <td>
                Всего проголосовало членов ТСН
              </td>
              <td>
                <b>{{inf.total_tsn_voted.toFixed(2)}}</b>м2
              </td>
            </tr>
            <tr>
              <td>
                Всего проголосовало членов ТСН из  ППД
              </td>
              <td>
                <b>{{ (100*inf.total_tsn_voted/inf.passport_votes).toFixed(2)}}</b>%
              </td>
            </tr>
            <tr >
              <td>
                Кворум членов ТСН из всех членов ТСН
              </td>
              <td>
                <b>{{ (100*inf.total_tsn_voted/inf.total_tsn_votes).toFixed(2)}}</b>%
              </td>
            </tr>
            <tr>
              <td>
                Потенциальный кворум членов ТСН из всех членов ТСН
              </td>
              <td>
                <b>{{ (100*inf.total_tsn_potential_voted/inf.total_tsn_votes).toFixed(2)}}</b>%
              </td>
            </tr>
          </table>


        </td>
        <td>
          <button mat-raised-button color="primary" style="width: 100%;" [routerLink]="['/bulletin']"  [queryParams] = "{oss_id: inf.oss_id}" >
            <mat-icon>ballot</mat-icon>
            <span>Заполнить бюллетень</span>
          </button>
          <button mat-raised-button color="primary" style="width: 100%;"  [routerLink]="['/oss']" [queryParams] = "{id: inf.oss_id}" >
            <mat-icon>how_to_vote</mat-icon>
            <span>Открыть ОСС</span>
          </button>
          <button mat-raised-button color="primary" style="width: 100%;"  (click)="generateEmptyBulletin(inf.guid, true)" >
            <mat-icon>print</mat-icon>
            <span>Открыть бюллетень</span>
          </button>

          <button mat-raised-button color="primary" style="width: 100%;"  [routerLink]="['/field-votes']" [queryParams] = "{oss_id: inf.oss_id}" >
            <mat-icon>grid_on</mat-icon>
            <span>Таблица результатов</span>
          </button>
          <button mat-raised-button color="primary" style="width: 100%;"  [routerLink]="['/distribution-of-votes']" [queryParams] = "{oss_id: inf.oss_id}" >
            <mat-icon>insert_chart_outlined</mat-icon>
            <span>Распределение голосов</span>
          </button>
        </td>
      </tr>
    </table>
    </div>
  </ng-container>

<!--  =====================================================NEXT OSS ====================================-->

  <ng-container *ngIf="info && info.oss_info.next_oss && info.oss_info.next_oss.length>0">
  <h4>Предстоящие голосования</h4>
    <div style="width: 100%; overflow-x: auto;">
      <table class="search_result full_width" style="border-collapse: collapse; background: #fffec9;" border="1" cellpadding="5">
    <tr *ngFor="let inf of info.oss_info.next_oss">
      <td style="width: 150px;">
        <p *ngIf="!inf.is_tsn_member">ОС собственников:</p>
        <p *ngIf="inf.is_tsn_member">ОС членов ТСН:</p>
        <b>{{inf.title}}</b>
        <div *ngIf="inf.description"><hr/>{{inf.description}}</div>
      </td>
      <td>
        c {{getDateTime(inf.start_time)}}<br/>по {{getDateTime(inf.end_time)}}
      </td>
      <td>
        <table *ngIf="!inf.is_tsn_member" class="vote-table">
          <tr>
            <td>
              Всего зарегистрированных голосов от полезной площади дома (ППД)
            </td>
            <td>
              <b>{{inf.total_common_votes.toFixed(2)}}</b>м2
            </td>
          </tr>
          <tr>
            <td>
              Всего зарегистрированных голосов % от ППД
            </td>
            <td>
              <b>{{ (100*inf.total_common_votes/inf.passport_votes).toFixed(2)}}</b>%
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало
            </td>
            <td>
              <b>{{inf.total_common_voted.toFixed(2)}}</b>м2
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало из  ППД
            </td>
            <td>
              <b>{{ (100*inf.total_common_voted/inf.passport_votes).toFixed(2)}}</b>%
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало из зарегистрированных
            </td>
            <td>
              <b>{{ (100*inf.total_common_voted/inf.total_common_votes).toFixed(2)}}</b>%
            </td>
          </tr>
        </table>

        <table *ngIf="inf.is_tsn_member" class="vote-table">
          <tr>
            <td>
              Всего голосов членов ТСН от полезной площади дома (ППД)
            </td>
            <td>
              <b>{{inf.total_tsn_votes.toFixed(2)}}</b>м2
            </td>
          </tr>
          <tr>
            <td>
              Всего голосов членов ТСН % от ППД
            </td>
            <td>
              <b>{{ (100*inf.total_tsn_votes/inf.passport_votes).toFixed(2)}}</b>%
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало членов ТСН
            </td>
            <td>
              <b>{{inf.total_tsn_voted.toFixed(2)}}</b>м2
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало членов ТСН из  ППД
            </td>
            <td>
              <b>{{ (100*inf.total_tsn_voted/inf.passport_votes).toFixed(2)}}</b>%
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало членов ТСН из всех членов ТСН
            </td>
            <td>
              <b>{{ (100*inf.total_tsn_voted/inf.total_tsn_votes).toFixed(2)}}</b>%
            </td>
          </tr>
        </table>


      </td>
      <td>
        <button mat-raised-button color="primary" style="width: 100%;"  [routerLink]="['/oss']" [queryParams] = "{id: inf.oss_id}" >
          <mat-icon>how_to_vote</mat-icon>
          <span>Редактировать ОСС</span>
        </button>
      </td>
    </tr>
  </table>
    </div>
  </ng-container>
<!--======================================================== OLD OSS ===========================================-->
  <ng-container *ngIf="info && info.oss_info.old_oss && info.oss_info.old_oss.length>0">
  <h4>Прошедшие голосования</h4>
    <div style="width: 100%; overflow-x: auto;">
      <table *ngIf="info" class="search_result full_width" style="border-collapse: collapse; background: #cac9ff;" border="1" cellpadding="5">
    <tr *ngFor="let inf of info.oss_info.old_oss">
      <td style="width: 150px;">
        <p *ngIf="!inf.is_tsn_member">ОС собственников:</p>
        <p *ngIf="inf.is_tsn_member">ОС членов ТСН:</p>
        <b>{{inf.title}}</b>
        <div *ngIf="inf.description"><hr/>{{inf.description}}</div>
      </td>
      <td>
        c {{getDateTime(inf.start_time)}}<br/>по {{getDateTime(inf.end_time)}}
      </td>
      <td>
        <table *ngIf="!inf.is_tsn_member" class="vote-table">
          <tr>
            <td>
              Всего зарегистрированных голосов от полезной площади дома (ППД)
            </td>
            <td>
              <b>{{inf.total_common_votes.toFixed(2)}}</b>м2
            </td>
          </tr>
          <tr>
            <td>
              Всего зарегистрированных голосов % от ППД
            </td>
            <td>
              <b>{{ (100*inf.total_common_votes/inf.passport_votes).toFixed(2)}}</b>%
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало
            </td>
            <td>
              <b>{{inf.total_common_voted.toFixed(2)}}</b>м2
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало из  ППД
            </td>
            <td>
              <b>{{ (100*inf.total_common_voted/inf.passport_votes).toFixed(2)}}</b>%
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало из зарегистрированных
            </td>
            <td>
              <b>{{ (100*inf.total_common_voted/inf.total_common_votes).toFixed(2)}}</b>%
            </td>
          </tr>
        </table>

        <table *ngIf="inf.is_tsn_member" class="vote-table">
          <tr>
            <td>
              Всего голосов членов ТСН от полезной площади дома (ППД)
            </td>
            <td>
              <b>{{inf.total_tsn_votes.toFixed(2)}}</b>м2
            </td>
          </tr>
          <tr>
            <td>
              Всего голосов членов ТСН % от ППД
            </td>
            <td>
              <b>{{ (100*inf.total_tsn_votes/inf.passport_votes).toFixed(2)}}</b>%
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало членов ТСН
            </td>
            <td>
              <b>{{inf.total_tsn_voted.toFixed(2)}}</b>м2
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало членов ТСН из  ППД
            </td>
            <td>
              <b>{{ (100*inf.total_tsn_voted/inf.passport_votes).toFixed(2)}}</b>%
            </td>
          </tr>
          <tr>
            <td>
              Всего проголосовало членов ТСН из всех членов ТСН
            </td>
            <td>
              <b>{{ (100*inf.total_tsn_voted/inf.total_tsn_votes).toFixed(2)}}</b>%
            </td>
          </tr>
        </table>


      </td>
      <td>
        <button mat-raised-button color="primary" style="width: 100%;"  [routerLink]="['/oss']" [queryParams] = "{id: inf.oss_id}" >
          <mat-icon>how_to_vote</mat-icon>
          <span>Открыть ОСС</span>
        </button>
        <button mat-raised-button color="primary" style="width: 100%;"  (click)="generateEmptyBulletin(inf.guid, true)" >
          <mat-icon>print</mat-icon>
          <span>Открыть бюллетень</span>
        </button>

        <button mat-raised-button color="primary" style="width: 100%;"  [routerLink]="['/field-votes']" [queryParams] = "{oss_id: inf.oss_id}" >
          <mat-icon>grid_on</mat-icon>
          <span>Таблица результатов</span>
        </button>
        <button mat-raised-button color="primary" style="width: 100%;"  [routerLink]="['/distribution-of-votes']" [queryParams] = "{oss_id: inf.oss_id}" >
          <mat-icon>insert_chart_outlined</mat-icon>
          <span>Распределение голосов</span>
        </button>
        <app-vote-menu [human]="human" [oss]="inf"></app-vote-menu>
<!--        <button mat-button [matMenuTriggerFor]="menu2" [disabled]="!allowVote(inf)"><mat-icon>pan_tool</mat-icon></button>-->
<!--        <mat-menu #menu2="matMenu" >-->
<!--          <button mat-button class="animated"-->

<!--                  [disabled]="!allowVote(inf)" (click)="generateBulletin(true, inf)"-->
<!--                  matTooltip="Сгенерировать и открыть бюллетень (Решение) для выбранного собственника">-->
<!--            <mat-icon>print</mat-icon>-->
<!--          </button>-->
<!--          <button mat-button class="animated"-->

<!--                  [disabled]="!allowVote(inf)" (click)="generateOssLink(true, inf)"-->
<!--                  matTooltip="Показать / копировать ссылку на голосовалку">-->
<!--            <mat-icon>link</mat-icon>-->
<!--          </button>-->
<!--          <button mat-button class="animated"-->

<!--                  [disabled]="!allowVote(inf)" [routerLink]="['/bulletin']"-->
<!--                  [queryParams]="{human_id: human_id, oss_id: inf.oss_id}"-->
<!--                  matTooltip="Заполнить решение">-->
<!--            <mat-icon>ballot</mat-icon>-->
<!--          </button>-->
<!--          <button mat-button class="animated"  *ngIf="info && info.oss_info && info.oss_info.current_oss && info.oss_info.current_oss.length>0" (click)="scanBarcode()" matTooltip="Сканировать QR-код решения">-->
<!--            <mat-icon>qr_code</mat-icon>-->
<!--          </button>-->

<!--        </mat-menu>-->
      </td>

    </tr>
  </table>
    </div>
  </ng-container>

  <ng-container *ngIf="info">
  <h4>Контрольные значения:</h4>
  <div *ngFor="let h of info.homes_info">
    <h4>[{{h.home.name}}] {{h.home.address}} ({{h.home.ppd_square}} м2)</h4>
    <table class="search_result full_width vote-table" style="border-collapse: collapse;" border="1" cellpadding="5" >
      <tr>
        <td>ППД - Полезная площадь дома (константа из паспорта), м2</td>
        <td>{{h.ppd_square.toFixed(2)}}</td>
      </tr>
      <tr>
        <td>Площадь помещений в базе, м2</td>
        <td>{{h.rooms_square.toFixed(2)}}</td>
      </tr>
      <tr>
        <td>Площадь голосов в базе, м2</td>
        <td>{{h.owns_square.toFixed(2)}}</td>
      </tr>
      <tr>
        <td>Помещений с незарегистрированными площадями, штук</td>
        <td>{{h.free_rooms.length}}</td>
      </tr>
      <tr>
        <td>Незарегистрированная площадь в помещениях, м2</td>
        <td>{{h.free_rooms_square.toFixed(2)}}</td>
      </tr>
      <tr>
        <td>Суммарная площадь голосов в базе и свободной площади, м2</td>
        <td>{{h.sum_roms.toFixed(2)}}</td>
      </tr>

    </table>
  </div>
  </ng-container>


</form>
  <p>- Потенциальный кворум это кворум в случае если все розданные бюллетени были бы собраны</p>
  <p>- ППД - полезная площадь дома, или площадь дома учитываемая при подсчете голосов</p>
  <p>- </p>
  <p></p>
  <p></p>
  <p></p>
  <p></p>
  <p></p>
  <p></p>
  <p></p>
</div>
<!--<div *ngIf="showQr" style="position: absolute; background: white; width: 100vw; height: 100vh; top: 0; z-index: 1000;">-->
<!--  <button mat-fab (click)="showQr=false" color="warn" style="position: fixed; right: 10px; top: 10px;">-->
<!--    <mat-icon>close</mat-icon>-->
<!--  </button>-->
<!--  <qrcode [qrdata]="current_link" width="200px"-->
<!--          style="position: fixed; left: 50vw; top: 50vh; transform: translate(-100px, -100px)"></qrcode>-->
<!--</div>-->
<!--<div *ngIf="showQrScan" style="position: absolute; background: white; width: 100vw; height: 100vh; top: 0; z-index: 1000;">-->
<!--  <app-qrreader class="full-width"></app-qrreader>-->
<!--  <button mat-fab (click)="showQrScan=false" color="warn" style="position: fixed; right: 10px; top: 10px;">-->
<!--    <mat-icon>close</mat-icon>-->
<!--  </button>-->
<!--</div>-->
<div *ngIf="requesting" style="
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50vh;
}">
  <mat-progress-spinner
    class="example-margin"
    color="primary"
    mode="indeterminate"  >
  </mat-progress-spinner>
</div>
