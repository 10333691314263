 <div class="menu_box" [class.menu_opened]="main.IsMenuOpened" [class.active_box]="main.IsMenuOpened" style="
    scroll-behavior: revert;
    position: fixed;
    overflow: auto;
    max-width: 1000px;
    min-width: 320px;
    margin: auto;
    left: 50vw;
    transform: translate(-50%, 0);"  (click)="closeBurger($event)" (scroll)="scrollEvent($event)">
  <div class="menu" >
    <div class="menu__inner">
      <div class="close" id="close" >
        <div class="cross"></div>
      </div>
      <div (click)="clickDevNull($event)" class="menu_body">
        <div class="menu_accordion">
          <div >
            <button mat-button class="menu-button" [routerLink]="['/home']" >
              <mat-icon>home</mat-icon>
              <span>Главная</span>
            </button>

            <button mat-button class="menu-button" [routerLink]="['/vote']" >
              <mat-icon>search</mat-icon>
              <span>Поиск</span>
            </button>
            <button mat-button class="menu-button" [routerLink]="['/add-human']" >
              <mat-icon>person_add</mat-icon>
              <span>Добавить жителя</span>
            </button>
            <button mat-button class="menu-button" [routerLink]="['/oss']" >
              <mat-icon>how_to_vote</mat-icon>
              <span>Создать ОСС</span>
            </button>
            <button mat-button class="menu-button"  [routerLink]="['/bulletin']" >
              <mat-icon>ballot</mat-icon>
              <span>Заполнить бюллетень</span>
            </button>
            <button mat-button class="menu-button" [routerLink]="['/edit-human-owns']" >
              <mat-icon>business</mat-icon>
              <span>Редактировать собственности</span>
            </button>
            <button mat-button class="menu-button" [routerLink]="['/document-generator']" >
              <mat-icon>work</mat-icon>
              <span>Документы</span>
            </button>
<!--            <div>-->
<!--              <button mat-button class="menu-button" matTooltip="Электронные бюллетени" [routerLink]="['/evotes']" >-->
<!--                <mat-icon>mobile_friendly</mat-icon>-->
<!--                <span>Электронные бюллетени</span>-->
<!--              </button>-->
<!--            </div>-->
<!--            <div>-->
<!--              <button mat-button class="menu-button" matTooltip="Электронные вопросы" [routerLink]="['/equestions']" >-->
<!--                <mat-icon>contact_support</mat-icon>-->
<!--                <span>Электронные вопросы</span>-->
<!--              </button>-->
<!--            </div>-->
            <span style="padding: 20px;" *ngIf="userService && userService.currentUserValue && userService.currentUserValue.username">
              <mat-icon style="margin: auto 5px auto auto; vertical-align: middle;">account_circle</mat-icon><span style="margin: auto; vertical-align: baseline;"> <b>{{userService.currentUserValue.username}}</b> </span>
              <button mat-icon-button class="" class="" (click)="userService.logout()">
              <mat-icon>exit_to_app</mat-icon>
                <!--              <span>Выход</span>-->
            </button>
            </span>

            <div>
              <button mat-button color="warn" class="menu-button" (click)="ClearAndReload()">
                <mat-icon>cached</mat-icon>
                <span style="    font-size: large;"> Обновить программу</span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

 </div>
