<h3>Помещение</h3>
<table *ngIf="room" class="full-width"  border="1" cellpadding="5">
  <tr>
    <th>
      Наименование
    </th>
    <td>
      {{room.name}}
    </td>
  </tr>
  <tr>
    <th>
      Кадастровый номер
    </th>
    <td>
      {{room.cadastral_number}}
    </td>
  </tr>
  <tr>
    <th>
      Площадь
      <br/>
      <i style="font-weight: normal;">Можно редактировать. Все площади долей собственников пересчитаются автоматически.</i>
    </th>
    <td>
      <mat-form-field class="full-width">
        <input matInput [(ngModel)]="room.square" name="room_square_input"/>
      </mat-form-field>
    </td>
  </tr>
  <tr>
    <th>
      Общее имущество<br/>
      <i style="font-weight: normal;">Общее имущество не должно иметь собственников и не будет учитываться при подсчете голосов</i>
    </th>
    <td>
      <mat-checkbox
        [(ngModel)]="room.is_common"  name="is_common_checkbox">
        общее
      </mat-checkbox>
    </td>
  </tr>

</table>
<!--[disabled]="(owners && owners.length>0 && room && room.is_common)"-->
<button *ngIf="owners && room" mat-raised-button color="primary"  (click)="saveSession()">Сохранить</button>

<mat-error *ngIf="owners && room && owners.length>0 && room.is_common==1"><mat-icon>warning</mat-icon> Помещение общего имущества имеет собственников. Кликните по имени собственника и скорректируйте его собственности.</mat-error>


<h3>Владельцы</h3>
<table *ngIf="owners" class="full-width"  border="1" cellpadding="5">
  <tr *ngFor="let owner of owners">
    <td>
      <button mat-button style="width: 100%;text-align: left;" color="primary" matTooltip="Кликните для перехода к редактору собственностей"  aria-label="Button that displays a tooltip in various positions" [routerLink]="['/edit-human-owns']"  [queryParams]="{ human_id: owner.human.id }"><mat-icon>person</mat-icon> {{owner.human.fio}}</button>
    </td>
    <td>
      {{owner.relation.reg_number}}
    </td>
    <td>
      {{owner.relation.own_part_title}}
    </td>
    <td>
      {{owner.relation.own_part_square.toFixed(2)}} м2
    </td>
  </tr>
  <tr>
    <td colspan="3"></td>
    <th>{{total_square.toFixed(2)}} м2 </th>
  </tr>
</table>
