<div style="padding: 10px">
  <div *ngIf="enabeQrScan" style=" position: fixed; top: 0; left: 0; width: 100vw; height: 100vh;">

    <zxing-scanner style="width: 100%; height: 100%;"
                   #scanner
                   [enable]="enabeQrScan"
                   [torch]="enableFlash"
                   (torchCompatible)="onTorchCompatible($event)"
                   (camerasFound)="camerasFoundHandler($event)"
                   (camerasNotFound)="camerasNotFoundHandler($event)"
                   (scanSuccess)="scanSuccessHandler($event)"
                   (scanError)="scanErrorHandler($event)"
                   (scanFailure)="scanFailureHandler($event)"
                   (scanComplete)="scanCompleteHandler($event)"
                   [device]="selectedCamera"
                   [formats]="QRScanerrAllowedFormats"
    ></zxing-scanner>
    <div style="
                display: flex;
                position: fixed;
                bottom: 0;
                width: 100%;
                flex-grow: 1;
                font-size: 20px;
                margin-bottom: -24px;
                margin-top: -10px;
      ">
      <mat-form-field style="flex-grow: 1">
        <mat-select [(value)]="selectedCamera">
<!--          <mat-option *ngFor="let camera of cameras" [value]="null">По умолчанию</mat-option>-->
          <mat-option *ngFor="let camera of cameras" [value]="camera">{{camera.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-fab color="primary" (click)="enableFlash =! enableFlash"
              [disabled]="!allowedFlash">
        <mat-icon *ngIf="enableFlash">flash_on</mat-icon>
        <mat-icon *ngIf="!enableFlash">flash_off</mat-icon>
      </button>
      <input type="file" accept="image/*" (change)="onFileChange($event)">
    </div>
  </div>
  <div *ngIf="!enabeQrScan">
    <div>
      <div class="full-width loading-panel" *ngIf="loadStatus=='loading'">
        <!--    <img class="loading-panel-829"  src="/assets/829.png" alt="Загрузка..."/>-->
        <img class="loading-panel-299" src="/assets/299.png" alt=""/>
      </div>
      <div class="full-width not-found-panel" *ngIf="loadStatus=='not-found'">
        <img class="loading-panel-sorry" src="/assets/sorry.png" alt=""/>
        <p style="margin: 20px 20px; alignment: center;">Что-то пошло не так, по QR-коду не удалось найти решение.</p>
        <p style="color: darkred">{{getBulletinError}}</p>
        <br><br><br>
        <button class="full-width" mat-raised-button color="primary" (click)="enabeQrScan=true">Сканировать следующее решение</button>
      </div>

      <div *ngIf="loadStatus=='exists'" style="overflow: auto;">
        <br><br><br>
        <h2 style="alignment: center">Решение собственника: {{fio}}</h2>
        <div *ngIf="locked" style="background: #ffd400; border-radius: 5px; padding: 10px; width: calc(100% - 40px)">
          Бюллетень заблокирован <span *ngIf="locked==='tenant'">собственником</span><span *ngIf="locked!=='tenant'">администрацией</span>
          для редактирования
        </div>
        <mat-form-field style="margin-top: -13px; margin-bottom: 5px;" class="full-width">
          <input matInput [(ngModel)]="doverka" name="doverka" rows="1" (focusout)="saveDoverka()">
          <mat-hint> (Ф.И.О. представителя и сведения о документе, подтверждающем его
            полномочия)
          </mat-hint>
        </mat-form-field>
        <table class="full-width" border="1" cellpadding="5">
          <tr>
            <th style="width: 50px;">
              №
            </th>
            <th style="width: 50px;">
              ЗА
            </th>
            <th style="width: 50px;">
              ПРОТ.
            </th>
            <th style="width: 50px;">
              ВОЗД.
            </th>
            <th style="width: 50px;">
              БРАК
            </th>
          </tr>
          <ng-container *ngIf="bulletin && bulletin.qwsts">
            <ng-container *ngFor="let question of bulletin.qwsts">
              <tr *ngIf="question.type != 'header'" [className]="question.type">
                <th style="vertical-align: top;"> {{question.number}}</th>
                <td colspan="4" style="padding: 0">
                  <ng-container *ngIf="!question.answer">
                    <button class="answer-button color-for" mat-flat-button (click)="setAnswer(question.id, 'f')">ЗА
                    </button>
                    <button class="answer-button color-against" mat-flat-button (click)="setAnswer(question.id, 'a')">
                      ПРОТ
                    </button>
                    <button class="answer-button color-abstained" mat-flat-button (click)="setAnswer(question.id, 'r')">
                      ВОЗД
                    </button>
                    <button class="answer-button color-rejection"  mat-flat-button (click)="setAnswer(question.id,  'd')">БРАК</button>
                  </ng-container>
                  <button *ngIf="question.answer == 'f'" class="answer-button reset-answer-button color-for"
                          mat-flat-button
                          (click)="setAnswer(question.id, '')">ЗА<span *ngIf="!locked">&#185;</span><br/><span
                    class="answer-user"></span></button>
                  <button *ngIf="question.answer == 'a'" class="answer-button reset-answer-button color-against"
                          mat-flat-button (click)="setAnswer(question.id, '')">ПРОТИВ<span
                    *ngIf="!locked">&#185;</span><br/><span class="answer-user"></span>
                  </button>
                  <button *ngIf="question.answer == 'r'" class="answer-button reset-answer-button color-abstained"
                          mat-flat-button (click)="setAnswer(question.id, '')">ВОЗДЕРЖАЛСЯ<span
                    *ngIf="!locked">&#185;</span><br/><span
                    class="answer-user"></span></button>
                  <button *ngIf="question.answer == 'd'" class="answer-button reset-answer-button color-rejection" mat-flat-button  (click)="setAnswer(question.id, '')">БРАК<br/><span class="answer-user">{{question.user}}</span></button>

                </td>

              </tr>
            </ng-container>
          </ng-container>
        </table>
        <br><br><br>
        <button class="full-width" mat-raised-button color="primary" (click)="closeBulletin()">Закрыть решение</button>
        <br><br><br>
        <button class="full-width" *ngIf="locked" mat-raised-button color="primary" (click)="unLockBulletin()">Разблокировать решение</button>
        <br><br><br>
        <button class="full-width" mat-raised-button color="primary" (click)="enabeQrScan=true">Сканировать следующее решение</button>
        <br><br><br>
        <br><br><br>

      </div>
      <div class="full-width no-camera" *ngIf="loadStatus=='no-camera'">
        <img class="loading-panel-sorry" src="/assets/no-camera.jpg" alt=""/>
        <p style="margin: 20px 20px; alignment: center;">Камера не обнаружена, или запрещена политикой безопасности. </p>
      </div>
    </div>
  </div>


</div>
