import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {


  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute, private router: Router,
              public dialog: MatDialog) {
    this.main.currentMatIcon = 'contact_support';
    this.main.currentHeader = 'Электронные вопросы';
  }
  // contact_support
  ngOnInit() {
  }

}
