// noinspection TypeScriptUnresolvedVariable

import {Component, Inject, OnInit} from '@angular/core';
import {MainService} from '../main.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
  selector: 'app-resident',
  templateUrl: './resident.component.html',
  styleUrls: ['./resident.component.css']
})
export class ResidentComponent implements OnInit {


  constructor( private http: HttpClient, public main: MainService, private route: ActivatedRoute,
               public dialog: MatDialog, private router: Router, private snackBar: MatSnackBar) {
    this.main.currentMatIcon = 'assignment_ind';
    this.main.currentHeader = 'Информация о жителе';
  }
  get addFiles(): any[] { return this.newNote[5] as []; }
  set addFiles(value: any[]) {if (this.newNote != null){
    this.newNote[5] = value;
  }}
  public editOwnsMode = false;
  public editOwnsModeError = '';
  public room_id = '';
  public human_id = '';
  public human: any;


  notes = [];
  phones = [];
  mails = [];
  links = [];
  newNote = [0, 'note', '', '', '', []];
  currentEditPhone = [0, 'phone', '', '', '', []];
  currentEditMail = [0, 'mail', '', '', '', []];
  currentEditLink = [0, 'link', '', '', '', []];
  editHumanFIO = false;
  humanFIO = '';
  // addFiles: any = [];
  files: any = [];
  editFile: any[];

  ngOnInit() {
    this.room_id = this.route.snapshot.queryParamMap.get('room_id');
    this.human_id = this.route.snapshot.queryParamMap.get('human_id');
    // this.resetEditEwns();
    this.getResidentData();
  }

  getDate(value: string){
    return new Date(value).toLocaleDateString();
  }



  getResidentData(){
    if (this.human_id == null) { this.human_id = ''; }
    if (this.room_id == null) { this.room_id = ''; }
    this.http.get(this.main.server + '/stuff/resident?' +
      '&room_id=' + this.room_id +
      '&is_actual_oss=' + false +
      '&human_id=' + this.human_id,

      this.main.httpOptions).subscribe(
      data => {
        this.human = data;
        this.getNotes();

      },
      err => {

      }
    );
  }


  setStatus(ossList: any[], statusKey: string) {
    this.main.setStatus( ossList, statusKey, true, (data, oss) => {
      this.human = data;
      this.getNotes();
    });
  }
  //   console.log(status_key);
  //   console.log(this.main.statuses);
  //   let action = "";
  //
  //   for(let st of  this.main.statuses){
  //     if(st.key === status_key){
  //       action = st.action;
  //       break;
  //     }
  //
  //   }
  //   console.log(action);
  //   let actions = action.split(',');
  //   let description = "";
  //   for(let act of actions ){
  //     if(act == "set-status"){
  //       this._setStatus(oss_id, human_id, status_id, description );
  //     }
  //     else if(act == "fill-votes"){
  //       this.router.navigate(['/bulletin'], { queryParams: { vote_id: vote_id } });
  //       //this.router.createUrlTree( '/bulletin?vote_id='+vote_id);
  //       //this.router.navigate(['/bulletin', { vote_id: vote_id }]);
  //       //this.router.navigate([{outlets: {discount: 'discount-info'}}]);
  //     }
  //     else if(act == "get-scan"){
  //
  //     }
  //     else if(act == "query-description"){
  //       //this.openDialog();
  //        let dialogData = new GetDescriptionDialogData();
  //        dialogData.title = "Дайте пояснения";
  //        dialogData.isEditBox = true;
  //        dialogData.buttons = "Ок|Отмена";
  //       this.dialog.open(GetDescriptionDialogComponent, {
  //         data: dialogData});
  //       if(dialogData.result == "Отмена") return;
  //       description = dialogData.editValue;
  //       //description = window.prompt("Дайте пояснения","");
  //     }
  //
  //   }
  // }
  // _setStatus(oss_id: number, human_id: number, status_id: number, description: string){
  //   if(isNaN(oss_id)) oss_id = 0;
  //   if(isNaN(status_id)) status_id = 0;
  //   if(!description) description = "";
  //   this.http.post(this.main.server + '/stuff/resident/set-status?get-human=true', {'oss_id': oss_id, 'human_id':human_id, 'status':status_id, 'description':description}, this.main.httpOptions).subscribe(
  //     data => {
  //       this.human = data
  //       this.getNotes();
  //
  //     },
  //     err => {
  //
  //     }
  //   )
  // }

  isTsnMemberChanged($event: MatSlideToggleChange | Event) {

    if (isNaN(this.human.is_tsn_member)) { this.human.is_tsn_member = 0; }
    let description = '';
    if (this.human.is_tsn_member){
      this.human.is_tsn_member = 1;
      description = 'Принят в члены ТСН';
    }
    else{
      this.human.is_tsn_member = 0;
      description = 'Отчислен из членов ТСН';
    }
    const mess = {
      human_id: this.human_id,
      value: this.human.is_tsn_member,
      description,

    };
    this.http.post(this.main.server + '/stuff/resident/set-tsn-member-status', mess, this.main.httpOptions).subscribe(
      data => {
        const anyData = data as any;

        const res = anyData.result;
        if (res === 'ok') {
          const human_id = anyData.human_id;
          const value = anyData.value;
          const result = anyData.result;
          // tslint:disable-next-line:no-shadowed-variable
          const description = anyData.description;
          this.snackBar.open(description, 'Ок', {
            verticalPosition: 'top',
            panelClass: 'ok-panel',
            duration: 5000,
          });
          this.getNotes();

        }
        else if ( res === 'exception' ){
             this.snackBar.open(anyData.exception, 'Ок', {
               verticalPosition: 'top',
               panelClass: 'exception-panel',
               duration: 5000,
            });

        }

      },
      err => {

      }
    );
  }









  getNotes(){
    let lastId = 0;
    if (this.notes.length > 0){
      lastId = this.notes[0][0];
    }
    this.http.get(this.main.server + '/stuff/resident/get-notes?' +
      'after_id=' + lastId +
      '&human_id=' + this.human_id,
      this.main.httpOptions).subscribe(
      data => {
        const anyData = data as any;
        const n =  anyData.notes;
        this.phones = anyData.phones;
        this.mails = anyData.mails;
        this.links = anyData.links;
        this.files = anyData.files;
        this.notes = n.concat(this.notes);
      },
      err => {
      }
    );
  }

  getDateTime(value: string){
    return new Date(value).toLocaleDateString();
  }

  // sendMessage() {
  //   this._sendMessage("note",this.newNote);
  // }
  sendMessage(event, message: any[]){
    event.stopPropagation();
    if (message){
      const mess = {
        id: message[0],
        type: message[1],
        human_id: this.human_id,
        content: message[4],

      };
      if (message.length > 5) {
        // @ts-ignore
        mess.files = message[5];
      }
      this.http.post(this.main.server + '/stuff/resident/add-note', mess, this.main.httpOptions).subscribe(
        data => {
          const anyData = data as any;
          const res = anyData.result;
          const id = message[0];
          if (res === 'ok') {
            message[4] = '';
            message[0] = 0;
            this.addFiles = [];
            if (id > 0) {
              this.notes = [];
              this.snackBar.open('Заметка сохранена', 'Ok', {
                verticalPosition: 'top',
                panelClass: 'ok-panel',
                duration: 5000,
              });
            }
            else{
              this.snackBar.open('Заметка добавлена', 'Ok', {
                verticalPosition: 'top',
                panelClass: 'ok-panel',
                duration: 5000,
              });
            }
            this.getNotes();

          }


        },
        err => {

        }
      );
    }
    else
    {
      this.snackBar.open('Заметка пустая', 'Ok', {
        verticalPosition: 'top',
        panelClass: 'warning-panel',
        duration: 10000,
      });
    }
  }


  saveHumanFIO() {
    if (this.humanFIO == this.human.fio){
      this.editHumanFIO = false;
      this.humanFIO = '';
      return;
    }
    const mess = {
      id: this.human.human_id,
      fio: this.humanFIO
    };
    this.http.post(this.main.server + '/stuff/resident/save-fio', mess, this.main.httpOptions).subscribe(
      data => {
        const anyData = data as any;
        const res = anyData.result;
        if (res === 'exists'){
          this.snackBar.open('ФИО собственника уже существует', 'Ok', {
            verticalPosition: 'top',
            panelClass: 'exception-panel',
            duration: 10000,
          });
        }
        if (res == 'ok') {
          this.human.fio = this.humanFIO;
          this.humanFIO = '';
          this.snackBar.open('ФИО собственника сохранено', 'Ok', {
            verticalPosition: 'top',
            panelClass: 'ok-panel',
            duration: 5000,
          });
          this.editHumanFIO = false;
        }
      },
      err => {
        this.editHumanFIO = false;
      }
    );
  }
  uploadFile(event) {
    if (event.length > 0) {

      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        if (element.size > 30000000) {
          this.snackBar.open('Файл "' + element.name + '" больше 30 мегабайт;', 'Ok', {
                      verticalPosition: 'top',
                      panelClass: 'exception-panel',
                      duration: 5000,
                    });
          continue;
        }
        const reader = new FileReader();
        const ef = [0, element.name, ''];
        reader.readAsDataURL(element);
        reader.onload = () => {
          ef[2] = [
            element.name,
            reader.result
          ];
          console.log(this.addFiles);
        };
        if (this.editFile == null){
          this.editFile = ef;
        }
        if (this.newNote[4] == ''){
          this.newNote[4] = element.name;
        }
        console.log(this.newNote);

        this.addFiles.push(ef);
      }

    }
  }
  deleteAttachment(index) {
    const file  = this.addFiles[index];
    if (this.editFile === file) {
      this.editFile = null;
    }
    this.addFiles.splice(index, 1);
  }


  // downloadFile(editFile: any[]) {
  //
  //     this.http.get<string>( this.main.server +'/stuff/files/get-file?id='+editFile[0],
  //       ).subscribe(
  //       data => {
  //         let a = document.createElement('a');
  //         a.setAttribute('href', data);
  //         a.setAttribute('download', editFile[1]);
  //         let vals = data.split(',',1);
  //         let type = vals[0].split(':', 1)[1];
  //         const arr = new Array(btoa(vals[1]));
  //         const blob = new Blob( arr, {type : type});
  //         let objectURL = URL.createObjectURL(blob);
  //         console.log(data);
  //       },
  //       err => {
  //
  //       });
  //
  // }



  // saveFiles() {
  //   this.http.post(this.main.server + '/stuff/resident/save-fio', mess, this.main.httpOptions).subscribe(
  //     data => {
  //       const res = data['result'];
  //       if(res == 'exists'){
  //         this._snackBar.open('ФИО собственника уже существует', 'Ok', {
  //           verticalPosition: 'top',
  //           panelClass: 'exception-panel',
  //           duration: 10000,
  //         });
  //       }
  //       if(res == 'ok') {
  //         this.human.fio = this.humanFIO;
  //         this.humanFIO = "";
  //         this._snackBar.open('ФИО собственника сохранено', 'Ok', {
  //           verticalPosition: 'top',
  //           panelClass: 'ok-panel',
  //           duration: 5000,
  //         });
  //         this.editHumanFIO = false;
  //       }
  //     },
  //     err => {
  //       this.editHumanFIO = false;
  //     }
  //   )
  // }
}

