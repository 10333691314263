import { Component, OnInit } from '@angular/core';
import {UserService} from '../../user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    public userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar) {

  }

// {"non_field_errors":["Unable to log in with provided credentials."]} //TODO: handle for error login

  public user = {
    username: '',
    password: ''
  };





  // DoReset() {
  //
  //   const success = this.userService.reset(this.email);
  //
  // }
  passwordType = true;

  ngOnInit() {
    this.userService.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.passwordType = true;
  }

  login() {
    console.log('form.login');
    this.userService.login(this.user.username,  this.user.password);

  }

  // refreshToken() {
  //   this.userService.refreshToken();
  // }

  logout() {
    console.log('logout #1');
    this.userService.logout();

  }
}
