<div class="example-container mat-elevation-z8">
  <div><b>Сортировка:</b>
    <button mat-raised-button [color]="sortedBy === 'fio' ? 'primary' : undefined" (click)="orderBy('fio')">ФИО</button>&nbsp;&nbsp;
    <button mat-raised-button [color]="sortedBy === 'rooms' ? 'primary' : undefined" (click)="orderBy('rooms')">Помещение</button>&nbsp;&nbsp;
    <button mat-raised-button [color]="sortedBy === 'square' ? 'primary' : undefined" (click)="orderBy('square')">Площадь</button>&nbsp;&nbsp;
    <b>Фильтр:</b>
    <button mat-raised-button [color]="unLocked ? 'primary' : undefined" (click)="unLocked = !unLocked"><mat-icon style="vertical-align: middle; color: red; cursor: help;" matTooltip="Показать НЕ заблокированные" >history_toggle_off</mat-icon></button>&nbsp;&nbsp;
    <button mat-raised-button [color]="lockedTenant ? 'primary' : undefined" (click)="lockedTenant = !lockedTenant "><mat-icon style="vertical-align: middle; color: orange; cursor: help;" matTooltip="Показать заблокированные жителем" >lock_clock</mat-icon></button>&nbsp;&nbsp;
    <button mat-raised-button [color]="lockedAdmin ? 'primary' : undefined" (click)="lockedAdmin = !lockedAdmin"><mat-icon  style="vertical-align: middle; color: green; cursor: help;"  matTooltip="Показать заблокированные администрацией" >lock</mat-icon></button>
  </div>
  <table  style="table-layout: fixed; "  cellspacing="0" cellpadding="4" border="1">
    <thead >
    <tr style="height: 50px; " >
      <th  class="col-head-1 row-head">№</th>
      <th  class="col-head-2 row-head">Собственник</th>
      <th  class="col-head-3 row-head">м2</th>
      <th  class="col-head-4 row-head">%</th>
      <th *ngFor="let col of fetchedColumns"  class="col-data row-head " [matTooltip]="col[1]"><div class="rotate"> {{col[0]}}</div></th>
<!--      <th style="width: 1px;"></th>-->
      </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let row of dataSource; let i = index" >
    <tr *ngIf="(lockedTenant && (row.locked === 'tenant')) || (lockedAdmin && (row.locked !== 'tenant' && row.locked !== '')) || (unLocked && row.locked === '' )  " [class.tr-tenant]="row.locked === 'tenant'" [class.tr-unlocked]="row.locked === ''">
      <th  class="col-head-1">{{i+1}}</th>
      <th  class="col-head-2">
        <a  routerLink="." matTooltip="Кликните для просмотра информации о жителе" [routerLink]="['/human']"  [queryParams]="{ human_id: row.id }">
          {{ row.fio }}
        </a>
        &nbsp;&nbsp;
        <a [routerLink]="['/bulletin']" [queryParams]="{ human_id: row.id, oss_id: oss_id }" ><mat-icon  [color]="row.locked === 'tenant'? 'warn': 'primary'" style="vertical-align: middle;">ballot</mat-icon></a>
        <mat-icon *ngIf="row.locked === 'tenant'" style="vertical-align: middle; color: orange; cursor: help;" matTooltip="Заблокировано жителем" >lock_clock</mat-icon>
        <mat-icon *ngIf="row.locked !== '' && row.locked !== 'tenant'" style="vertical-align: middle; color: green; cursor: help;"  matTooltip="Заблокировано администрацией" >lock</mat-icon>
        <mat-icon *ngIf="row.locked === ''" style="vertical-align: middle; color: red; cursor: help;"  matTooltip="НЕ заблокировано / не завершено" >history_toggle_off</mat-icon>
        <br>
        <span *ngIf="row.rooms">{{row.rooms}}</span>
      </th>
      <th  class="col-head-3">{{row.square.toFixed(2)}}</th>
      <th  class="col-head-4">{{(100 *  row.square/total_votes).toFixed(2)}}</th>
      <td *ngFor="let val of row.answers.split('') " class="col-data" [className]="val" ></td>
    </tr>
    </ng-container>
    </tbody>
    <tfoot>
    <tr style="height: 50px; " class="f" *ngIf="inPercentage">
      <th  colspan="4" style="text-align: right">За</th>
      <th *ngFor="let col of votes_f"  class="col-data row-head "><div class="rotate"> {{(100 *  col/total_votes).toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 100px; " class="f" *ngIf="!inPercentage">
      <th  colspan="4" style="text-align: right">За (м2)</th>
      <th *ngFor="let col of votes_f"  class="col-data row-head "><div class="rotate"> {{col.toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 50px; " class="a"  *ngIf="inPercentage" >
      <th  colspan="4" style="text-align: right">Против</th>
      <th *ngFor="let col of votes_a"  class="col-data row-head "><div class="rotate"> {{(100 *  col/total_votes).toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 100px; " class="a" *ngIf="!inPercentage" >
      <th  colspan="4" style="text-align: right">Против (м2)</th>
      <th *ngFor="let col of votes_a"  class="col-data row-head "><div class="rotate"> {{col.toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 50px; " class="r"  *ngIf="inPercentage" >
      <th  colspan="4" style="text-align: right">Воздержался</th>
      <th *ngFor="let col of votes_r"  class="col-data row-head "><div class="rotate"> {{(100 *  col/total_votes).toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 100px; " class="r" *ngIf="!inPercentage" >
      <th  colspan="4" style="text-align: right">Воздержался (м2)</th>
      <th *ngFor="let col of votes_r"  class="col-data row-head "><div class="rotate"> {{col.toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 50px; " class="d"  *ngIf="inPercentage">
      <th  colspan="4" style="text-align: right">Брак</th>
      <th *ngFor="let col of votes_d"  class="col-data row-head "><div class="rotate"> {{(100 *  col/total_votes).toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 100px; " class="d" *ngIf="!inPercentage" >
      <th  colspan="4" style="text-align: right">Брак (м2)</th>
      <th *ngFor="let col of votes_d"  class="col-data row-head "><div class="rotate"> {{col.toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 50px; "  *ngIf="inPercentage">
      <th  colspan="4" style="text-align: right">Кворум</th>
      <th *ngFor="let col of votes_summ"  class="col-data row-head "><div class="rotate"> {{(100 *  col/total_votes).toFixed(2)}}</div></th>
    </tr>
    <tr style="height: 100px; "  *ngIf="!inPercentage">
      <th  colspan="4" style="text-align: right">Кворум (м2)</th>
      <th *ngFor="let col of votes_summ"  class="col-data row-head "><div class="rotate"> {{(col).toFixed(2)}}</div></th>
    </tr>
    </tfoot>
  </table>
  <button mat-raised-button (click)="inPercentage=true" [color]="inPercentage ? 'primary' : undefined">Суммы в процентах</button><button mat-raised-button (click)="inPercentage=false" [color]="!inPercentage ? 'primary' : undefined">Суммы в м2</button>
  <ul>
    <li>Строки с не закрытым администрацией голосом не учитываются в сумме. </li>
    <li>Не закрытые голоса имеют светлый оттенок и значек <mat-icon style="vertical-align: middle; color: red; cursor: help;" matTooltip="НЕ заблокированные" >history_toggle_off</mat-icon>.</li>
    <li>Не закрытые администрацией голоса имеют светлый оттенок и значек <mat-icon style="vertical-align: middle; color: orange; cursor: help;" matTooltip="заблокированные жителем" >lock_clock</mat-icon>.</li>
    <li>Значек <mat-icon  color="primary" style="vertical-align: middle;">ballot</mat-icon> - это кнопка перехода к бюллетеню</li>
  </ul>
</div>

