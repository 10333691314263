import { Component, OnInit } from '@angular/core';
import {UserService} from '../../user.service';
import {Router, RouterLink} from '@angular/router';
import {MainService} from '../../main.service';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.css']
})
export class BurgerMenuComponent implements OnInit {

  constructor(public userService: UserService,
              private router: Router,
              public main: MainService) { }

  ngOnInit() {
    // const succ = this.mainService.isLogin;
    // if (succ) {
    //   this.mainService.getAccounts();
    //   this.mainService.getConstans();
    //   this.mainService.getCurrentAccount();
    // }
  }

  closeBurger($event: MouseEvent) {
    this.main.IsMenuOpened = false;
  }

  clickDevNull(event: MouseEvent) {
     // event.stopPropagation();
  }

  // logout() {
  //   //this.mainService.logout();
  //  // this.mainService.IsMenuOpened = false;
  //   this.router.navigate(['/']);
  // }
  //
  // addLicNumber() {
  //   //this.mainService.state = 7;
  //  // this.mainService.IsMenuOpened = false;
  //   this.router.navigate(['/']);
  // }


  scrollEvent($event: Event) {
    // alert('!');
  }

  ClearAndReload() {
    localStorage.clear();
    window.location.reload();
  }
}
