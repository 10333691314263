import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulletin-link',
  templateUrl: './bulletin-link.component.html',
  styleUrls: ['./bulletin-link.component.css']
})
export class BulletinLinkComponent implements OnInit {
  link: any;

  constructor() { }

  ngOnInit(): void {
  }

}
