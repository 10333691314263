<h3>Добавление жителя</h3>
<form class="example-form">
  <p>
    После создания жителя к нему не привязывается ни одна собственность. После создания можно перейти к форме регистрации собственности.
  </p>
  <mat-form-field class="full-width">
    <input matInput placeholder="Фамилия Имя Отчество" [(ngModel)]="data.fio" name="fio" required>
  </mat-form-field>

  <mat-form-field class="full-width">
    <input matInput placeholder="Отличительная заметка" [(ngModel)]="data.key" name="key">
  </mat-form-field>
  <span >Встречаются полные тезки. Введите отличительную особенность человека (возраст или квартира или откуда приехал),
    она вам пригодится чтобы не перепутать людей.
    Если вы уверены что он уникальный можете оставить поле пустым.</span><br/>
  <mat-error *ngIf="error">{{error}}</mat-error><br/>
  <button *ngIf="!data.human_id"  mat-raised-button color="primary" (click)="createHuman()">Создать</button>
  <button *ngIf="data.human_id" mat-raised-button color="primary" [routerLink]="['/edit-human-owns']"  [queryParams]="{ human_id: data.human_id }">Определить собственность</button>
</form>

