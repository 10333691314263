<div style=" ">
  <form>

    <!--    <h2 *ngIf="ossValue.id" style="text-align: center; vertical-align: center"><mat-icon style="vertical-align: sub; font-size: 40px; margin-right: 15px;">how_to_vote</mat-icon>Редактирование OSS</h2>-->
    <!--    <h2 *ngIf="!ossValue.id" style="text-align: center; vertical-align: center"><mat-icon style="vertical-align: sub; font-size: 40px; margin-right: 15px;">how_to_vote</mat-icon>Создание OSS</h2>-->
    <div *ngIf="ossValue.is_publish && !ossValue.is_closed"
         style="background-color: green; border-radius: 5px; padding: 5px;">Уже запущено.
    </div>
    <div *ngIf="ossValue.is_publish && ossValue.is_closed"
         style="background-color: red; border-radius: 5px; padding: 5px;">Уже запущено и закрыто.
    </div>
    <div *ngIf="!editMode">

      <h2>{{ossValue.title}}</h2>
      <div style="color: gray">id: <span>{{this.ossValue.id}}</span>; guid: <span>{{ossValue.guid}}</span></div>
      <table class="full-width">
        <tr>
          <td>
            Начало голосования
            <div>{{ossValue.start_time}}</div>
          <td>
            Окончание голосования
            <div>{{ossValue.end_time}}</div>
          </td>
        </tr>

    </table>
    <mat-checkbox disabled="" class="example-margin" [(ngModel)]="ossValue.is_tsn_member"
                  name="is_tsn_members_checkers"> Общее собрание членов ТСН
    </mat-checkbox>
    <div>{{ossValue.description}}</div>


    <table class="full-width" border="1" cellpadding="5">
      <tr>
        <th style="width: 50px;">
          №
        </th>
        <th>
          Короткое название вопроса
        </th>
        <th style="width: 70px;">
          Кворум
        </th>
      </tr>
      <tr *ngFor="let question of ossValue.bulletin.questions" [className]="question.type">
        <th style="vertical-align: top;"> {{question.number}}</th>
        <td [colSpan]="question.type == 'header'?2:1">  <!-- назвние-->
          <div [className]="question.type">
            <h3>{{question.short_question}}</h3>
            <div *ngIf="question.type != 'sub-question'">{{question.agenda}}</div>
            <div style="font-style: italic;"
                 *ngIf="question.type == 'sub-question' || question.type == 'question'">{{question.decision}}</div>

          </div>
        </td>
        <td class="quorum" *ngIf="question.type != 'header'" style="vertical-align: bottom;">  <!-- кворум-->
          <div>{{question.quorum_title}}</div>

        </td>

      </tr>
    </table>

    <button mat-raised-button color="primary" [disabled]="!(!ossValue.is_publish && !ossValue.is_closed)"
            (click)="editMode = true">Редактировать
    </button>
    <br><br>
    <app-files ownerName="oss" [readOnly]="false" [ownerId]="ossId" [files]="ossFiles"></app-files>
    <br>


  </div>
  <div *ngIf="editMode && !(ossValue.is_publish && ossValue.is_closed)">
    <table class="full-width">
      <tr>
        <td style="width: 50%">
          <mat-form-field class="full-width">
            <mat-label>МКД</mat-label>
            <mat-select [(ngModel)]="ossValue.selected_homes" multiple required
                        (selectionChange)="selectHomeValuesChanged($event)" name="select-home">
              <mat-option *ngFor="let home of homes" [value]="home.name">[{{home.name}}] {{home.address}}
                ({{home.ppd_square}} м2)
              </mat-option>
            </mat-select>
          </mat-form-field>

          </td>
          <td style="width: 50%">
            <mat-form-field class="full-width" hintLabel="Короткое название. (Максимум 45 символов)">
              <input matInput placeholder="Название" [(ngModel)]="ossValue.title" name="OssTitle" maxlength="45"
                     value="Новое OSS" required/>
              <mat-hint align="end">{{ossValue.title?.length || 0}}/45</mat-hint>
              <mat-error *ngIf="nameError">{{nameError}}</mat-error>
            </mat-form-field>
          </td>
        </tr>
      </table>
      <div>Работа с датами лучше всего поддерживается в Google Chrome</div>
      <table class="full-width">
        <tr>
          <td style="width: 50%">
            <mat-form-field class="full-width">
              <input type="datetime-local" matInput placeholder="Очная часть: начало" [(ngModel)]="ossValue.show_start"
                     (change)="changeShowStart()"
                     name="show_start" required>
              <!--            <input matInput [matDatepicker]="picker1" placeholder="Начало голосования" [(ngModel)]="ossValue.start_time" name="starttime" required>-->
              <!--            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>-->
              <!--            <mat-datepicker #picker1 startView="year" ></mat-datepicker>-->
              <mat-error *ngIf="showStartDateError">{{showStartDateError}}</mat-error>
              <mat-hint>{{"{{$.Time.show.from.time}} | {{$.Time.show.from.date}}"}}</mat-hint>
            </mat-form-field>
          </td>
          <td style="width: 50%">
            <mat-form-field class="full-width">
              <input type="datetime-local" matInput placeholder="Очная часть: окончание."
                     [(ngModel)]="ossValue.show_end" name="show_end" required>
              <!--            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>-->
              <!--            <mat-datepicker #picker2 startView="year" ></mat-datepicker>-->
              <mat-error *ngIf="showEndDateError" name="n1">{{showEndDateError}}</mat-error>
              <mat-hint>{{"{{$.Time.show.to.time}} | {{$.Time.show.to.date}}"}}</mat-hint>
            </mat-form-field>
          </td>

        </tr>
        <tr>
          <td style="width: 50%">
            <mat-form-field class="full-width">
              <input type="datetime-local" matInput placeholder="Заочная часть: начало"
                     [(ngModel)]="ossValue.back_start" name="back_start" required>
              <!--            <input matInput [matDatepicker]="picker1" placeholder="Начало голосования" [(ngModel)]="ossValue.start_time" name="starttime" required>-->
              <!--              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>-->
              <!--              <mat-datepicker #picker1 startView="year" ></mat-datepicker>-->
              <mat-error *ngIf="backStartDateError">{{backStartDateError}}</mat-error>
              <mat-hint>{{"{{$.Time.back.from.time}} | {{$.Time.back.from.date}}"}}</mat-hint>
            </mat-form-field>
          </td>
          <td style="width: 50%">
            <mat-form-field class="full-width">
              <input type="datetime-local" matInput placeholder="Заочная часть: окончание"
                     [(ngModel)]="ossValue.back_end" name="back_end" required>
              <!--              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>-->
              <!--              <mat-datepicker #picker2 startView="year" ></mat-datepicker>-->
              <mat-error *ngIf="backEndDateError" name="n1">{{backEndDateError}}</mat-error>
              <mat-hint>{{"{{$.Time.back.to.time}} | {{$.Time.back.to.date}}"}}</mat-hint>
            </mat-form-field>
          </td>

      </tr>
    </table>

    <mat-slide-toggle
      class=""
      color="Primary"
      [(ngModel)]="ossValue.is_tsn_member"

      name="oss-is-tsn-memver">
      Общее собрание членов ТСН
    </mat-slide-toggle>


    <mat-error *ngIf="isTsnMemberError" name="n1">{{isTsnMemberError}}</mat-error>

    <mat-form-field class="full-width">
        <textarea matInput placeholder="Простое описание собрания" [(ngModel)]="ossValue.description"
                  name="description"></textarea>
    </mat-form-field>
    <div style="width: 100%; display: flex; flex-direction: row;">
      <div style="flex-grow: 1;">

        <h2 style="font-weight: bold;text-align: center">РЕШЕНИЕ</h2>
        <mat-form-field class="full-width">
              <textarea matInput placeholder="Подзаголовок" style="font-weight: bold;text-align: center"
                        [(ngModel)]="context.Title" name="sub-title"></textarea>
            <mat-hint>{{"{{$.Title}}"}}</mat-hint>


          </mat-form-field>
          <table class="full-width">
            <tr>
              <td>
                <mat-form-field class="full-width">
                  <input matInput placeholder="адрес" style="font-weight: bold;text-align: left"
                         [(ngModel)]="context.Address" name="address">
                  <mat-hint>{{"{{$.Address}}"}}</mat-hint>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="full-width">
                  <input matInput [matDatepicker]="pickerHDate" placeholder="дата"
                         [(ngModel)]="LongDate" name="header_date">
                  <mat-datepicker-toggle matSuffix [for]="pickerHDate"></mat-datepicker-toggle>
                  <mat-datepicker #pickerHDate startView="year"></mat-datepicker>
                  <mat-error *ngIf="backStartDateError">{{backStartDateError}}</mat-error>
                  <mat-hint>{{"{{$.LongDate}}"}}</mat-hint>
                </mat-form-field>
              </td>
            </tr>
          </table>
        </div>
        <div style="width: max-content;">
          <img alt="QR-Code" width="120" height="120" src="/assets/qrcode-test.png"/>
        </div>

      </div>
      <div><span style="font-weight: bold">Форма проведения общего собрания:</span>
        <mat-form-field>
          <mat-select [(ngModel)]="ossValue.oss_form" required name="oss_form">
            <mat-option aria-selected="true" value="очно-заочное голосование">очно-заочное голосование</mat-option>
            <mat-option value="очное голосование">очное голосование</mat-option>
            <mat-option value="заочное голосование">заочное голосование</mat-option>
          </mat-select>
          <mat-hint>{{"{{$.OssForm}}"}}</mat-hint>
        </mat-form-field>
        , проводимое в следующем порядке:
      </div>
      <ul>
        <li *ngFor="let rule of context.Rules; let i = index">
          <mat-form-field class="full-width">
              <textarea matInput placeholder="Пункт правил проведения голосования" [name]="'rule_' + i"
                        style="text-align: justify"
                        [(ngModel)]="rule.content"></textarea>
            <mat-hint align="left">напр.: С 17час.00мин. по 17час.30мин. 06.10.2019 – очное голосование (в помещении
              подземного паркинга многоквартирного...
            </mat-hint>
          </mat-form-field>
        </li>
      </ul>
      <button mat-mini-fab (click)=" addRule();" color="primary"
              title="Добавляет пустое правило в конец">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-mini-fab (click)="removeLastRule()"
              color="warn" title="Удаляет последнее правило">
        <mat-icon>remove</mat-icon>
      </button>

    <table *ngIf="ossValue && ossValue.bulletin && ossValue.bulletin.questions" class="full-width" border="1"
           cellpadding="5">
      <tr>
        <th style="width: 50px;">
          №
        </th>
        <th>
          Короткое название вопроса
        </th>
        <th style="width: 70px;">
          Кворум
        </th>
      </tr>
      <tr *ngFor="let question of ossValue.bulletin.questions" [className]="question.type">
        <th style="vertical-align: top;">  <!-- номер-->

          <mat-form-field class="full-width" style="width: 50px;">
            <input matInput [(ngModel)]="question.number" maxLength="200" name="{{'number'+question.number}}"
                   (keyup)="numberChanged($event, question)" (change)="numberChanged($event, question)"/>
          </mat-form-field>
        </th>
        <td [colSpan]="question.type == 'header'?2:1">  <!-- назвние-->
          <div [className]="question.type">
            <mat-form-field class="full-width" style="font-weight: bold;">
              <input matInput placeholder="Название, тезис" [(ngModel)]="question.title"
                     maxLength="50"
                     name="{{'title'+question.number}}"/>
              <mat-hint align="start">Очень сокращенное обозначение повестки вопроса. напр: Выбор председателя</mat-hint>
              <mat-hint align="end">{{question.title?.length || 0}}/50</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width" style="font-weight: bold;">
              <textarea matInput placeholder="Решение коротко." [(ngModel)]="question.short_question" maxLength="100"
                        name="{{'short_question'+question.number}}"></textarea>
              <mat-hint align="start">Очень сокращенное обозначение решения. напр: Выбрать Пупкина И.В. кв.345.
              </mat-hint>
              <mat-hint align="end">{{question.short_question?.length || 0}}/100</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width" style="font-weight: bold;">
              <textarea matInput placeholder="Разъяснения." [(ngModel)]="question.description" maxLength="2000"
                        name="{{'description'+question.number}}"></textarea>
              <mat-hint align="start">Разъяснения для жителей закрывающие потенциальные вопросы от голосующих. напр.: Это необходимо для ...
              </mat-hint>
              <mat-hint align="end">{{question.description?.length || 0}}/2000</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="question.type != 'sub-question'">
              <textarea matInput placeholder="Официальная повестка" [(ngModel)]="question.agenda" maxLength="5000"
                        name="{{'agenda'+question.number}}"></textarea>
              <mat-hint align="end">{{question.agenda?.length || 0}}/5000</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width" style="font-style: italic;"
                            *ngIf="question.type == 'sub-question' || question.type == 'question'">
              <textarea matInput placeholder="Официальное решение" [(ngModel)]="question.decision" maxLength="5000"
                        name="{{'decision'+question.number}}"></textarea>
              <mat-hint align="end">{{question.decision?.length || 0}}/5000</mat-hint>
            </mat-form-field>
          </div>
        </td>
        <td class="quorum" *ngIf="question.type != 'header'" style="vertical-align: bottom;">  <!-- кворум-->
          <mat-form-field class="full-width" style="width: 70px; ">
            <mat-select [(value)]="question.quorum_title">
              <mat-option value="50%">50%</mat-option>
              <mat-option value="2/3">2/3</mat-option>
              <mat-option value="100%">100%</mat-option>
            </mat-select>
          </mat-form-field>
        </td>

      </tr>
    </table>
    <!--  <div style="display: block">-->
    <!--    <button mat-stroked-button color="primary" (click)="addQuestion()">Добавить вопрос голосования</button><br/>-->
    <!--    <button mat-stroked-button color="primary" (click)="addHeader()">Добавить вопрос-заголовок с подвопросами</button><br/>-->
    <!--    <button mat-stroked-button color="primary" (click)="addSubQuestion()">Добавить подвопрос голосования</button>-->
    <!--  </div>-->
    <div style="display: inline-flex" class="full-width">
      <button mat-raised-button color="primary" (click)="addQuestion()">
        <mat-icon>control_point</mat-icon>
        Добавить вопрос голосования
      </button>
      <button mat-raised-button color="primary" (click)="correctCalculateBulletin()">Сортировать*</button>


        <!--    <button mat-raised-button color="warn" *ngIf="ossValue.id && !ossValue.bulletin_id" (click)="createBulletin()">Создать бюллетень</button>-->
        <!--    <button mat-raised-button color="Primary" *ngIf="ossValue.id && ossValue.bulletin_id" (click)="viewBulletin()">Бюллетень</button>-->
      </div>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Скрипт построения бюллетеня
          </mat-panel-title>
          <mat-panel-description>
            Внешний вид бюллетеня. Шаблон используемый для построения бланка бюллетеня, без данных об ОСС (ОСЧ)
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div> Загрузите один из шаблонов:
          <mat-form-field class="full-width" style="width: 70px; ">
            <mat-select [(value)]="selectedTemplate" (selectionChange)="getTemplates()" required>
              <mat-option *ngFor="let name of Templates" [value]="name">{{name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>


      <textarea #definitionEditor name="definitionEditor" rows="50" [(ngModel)]="ossValue.bulletin.definition"
                style="width: 100%; font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;"></textarea>
    </mat-expansion-panel>


      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Данные для заполнения бланка бюллетеня
          </mat-panel-title>
          <mat-panel-description>
            Данные для формирования наполнения бюллетеня основанные на данном ОСС без данных о личности и его решении.
            Формируется на основании заплненных выше полей.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>обновить
          <button mat-icon-button (click)="bulletinContext = makeImplementRaw()">
            <mat-icon color="primary">autorenew</mat-icon>
          </button>
        </div>
        <textarea #contextEditor name="contextEditor" rows="50" [(ngModel)]="bulletinContext" readonly
                  (change)="changeBulletinContext()"
                  style="width: 100%; font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;"></textarea>
        <mat-error>{{bulletinContextError}}</mat-error>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Данные о личности и решении
          </mat-panel-title>
          <mat-panel-description>
            Данные для заполнения бюллетеня.
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-form-field class="example-full-width" style="width: 100%;  margin-top: -20px;font-family: inherit;"
                        required>
          <input style="font-size:x-large; font-weight: bold; " matInput [matAutocomplete]="auto"
                 [formControl]="searchValueControl" name="searchvalue">
          <!--    [displayWith]="displayFn"  [value]="searchValue"-->
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="humanSelected($event)">
            <mat-option *ngFor="let entry of humans" [value]="entry">
              <span>{{entry[1]}} - {{entry[3]}}</span>
            </mat-option>
          </mat-autocomplete>
          <mat-hint [style.text-align]="'center'">$.Owner; (Ф.И.О. собственника помещения)</mat-hint>
        </mat-form-field>
        <div style="color: gray">id: <span>{{ownerData?.human_id}}</span>; guid: <span>{{ownerData?.human_guid}}</span>
        </div>
        <mat-form-field class="full-width">
          <input matInput placeholder="Тестовые ответы" [(ngModel)]="testAnswers" name="testAnswers"
                 [maxLength]="answersCount"/>
          <mat-hint align="end">{{testAnswers.length || 0}}/{{answersCount}}</mat-hint>
          <mat-hint align="start">$.Answers; ЗА=f; ПРОТИВ=a; ВОЗДЕРЖАЛСЯ=r;</mat-hint>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input matInput placeholder="Тестовый представитель" [(ngModel)]="testDelegate" name="testDelegate"/>
          <mat-hint align="start">$.Delegate; Иванов Иван Иванович</mat-hint>
        </mat-form-field>

      <textarea #contextEditor name="argumentsEditor" rows="20" [(ngModel)]="bulletinArguments" readonly
                style="width: 100%; font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;"></textarea>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Отправленный шаблон
        </mat-panel-title>
        <mat-panel-description>
          Сформированный шаблон отправленный на сервер
        </mat-panel-description>
      </mat-expansion-panel-header>

      <textarea #contextEditor name="contextEditor" readonly rows="50" [(ngModel)]="bulletinSendTemplateResult"
                style="width: 100%; font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;"></textarea>
      <mat-error>{{bulletinContextError}}</mat-error>
    </mat-expansion-panel>


      <mat-hint>*-сортировка происходит на основе нумерации пунктов при нажатии на кнопку сортирровать, а так-же при
        добавлении нового пункта
      </mat-hint>
      <br/>
      <mat-hint>
        <mat-icon style="color: red">delete</mat-icon>
        Для удаления вопроса, удалите текст в вопросе
      </mat-hint>
      <mat-error *ngIf="commonError">{{commonError}}</mat-error>

  </div>


  <button mat-raised-button color="warn" *ngIf="!ossValue.id" (click)="createOSS()">Создать</button>
  <button mat-raised-button color="primary" *ngIf="ossValue.id" (click)="saveOSS()">Сохранить</button>

    <button mat-raised-button color="primary" (click)="generatePDF()">Создать PDF</button>
    <button mat-raised-button color="primary" (click)="downloadPDF()">Загрузить PDF</button>
    <div style="color: red">{{createPDFResult}}</div>
    <br/>
    <br/>
    <br/>
    <section>
      <div>Запустить ОСС.<span *ngIf="ossValue.is_publish && !ossValue.is_closed">Уже запущено.</span><span
        *ngIf="ossValue.is_publish && ossValue.is_closed">Уже запущено и закрыто.</span></div>
      <mat-checkbox class="example-margin" [(ngModel)]="allowPublish" name="allowPublish"
                    [disabled]="!ossValue.id || ossValue.is_publish || ossValue.is_closed || openCloseRequesting">Я хочу
        запустить ОСС
      </mat-checkbox>
      <button mat-raised-button color="primary" (click)="publishOSS()"
              *ngIf="ossValue.id && !ossValue.is_publish && !ossValue.is_closed && allowPublish && !openCloseRequesting">
        Запустить ОСС
      </button>
    </section>
    <br/>
    <br/>
    <section>
      <div>Закрыть ОСС.<span *ngIf="ossValue.is_closed">Уже закрыто</span></div>
      <mat-checkbox class="example-margin" [(ngModel)]="allowClose" name="allowClose"
                    [disabled]="!ossValue.id || !ossValue.is_publish || ossValue.is_closed || openCloseRequesting">Я
        хочу закрыть (завершить) ОСС
      </mat-checkbox>
      <button mat-raised-button color="primary" (click)="closeOSS()"
              *ngIf="ossValue.id && ossValue.is_publish && !ossValue.is_closed && allowClose && !openCloseRequesting">
        Закрыть ОСС
      </button>
    </section>
    <mat-progress-bar *ngIf="openCloseRequesting" mode="query"></mat-progress-bar>

  </form>

  <pdf-viewer class="pdf-viewer" [src]="pdfSrc" id="pdf-viewer"
              [show-borders]="true"
              [render-text]="false"
              [original-size]="true"
              [fit-to-page]="true"
              [show-all]="true"
              [autoresize]="false"
              style="display: block;"
  ></pdf-viewer>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
