import {Component, Inject, OnInit} from '@angular/core';
import {DialogData} from '../edit-human-owns.component';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../../main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-other-owners-dialog',
  templateUrl: './other-owners-dialog.component.html',
  styleUrls: ['./other-owners-dialog.component.css']
})
export class OtherOwnersDialogComponent implements OnInit {

  constructor(private http: HttpClient, public main: MainService, private route: ActivatedRoute, private router: Router, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  owns: any[];

  ngOnInit() {
    this.getOwners();
  }
    getOwners(){
      let owns = this.data.owns.map(function(n, i){
        return n[0];
      });
      this.http.post(this.main.server + '/stuff/owns/others', {others: owns, room_cad_num: this.data.cown.room__cadastral_number}, this.main.httpOptions).subscribe(
        data => {
          this.owns = data['data'];

        },
        err => {

        }
      );
    }
}
