<form style="">
  <!--  <h2 style="text-align: center; vertical-align: center; margin-bottom: 0px;"><mat-icon style="vertical-align: sub; font-size: 40px; margin-right: 15px;">ballot</mat-icon>Заполнение решения-->
  <div *ngIf="locked == 'tenant'"
       style="background: #ffd400; border-radius: 5px; padding: 10px; width: calc(100% - 40px)">
    Бюллетень закрыт собственником
  </div>
  <div *ngIf="locked && locked !== 'tenant'"
       style="background: #08ff00; border-radius: 5px; padding: 10px; width: calc(100% - 40px)">
    Бюллетень закрыт администрацией
  </div>
  <mat-form-field style="margin-top: -10px;    margin-left: 5px;    font-size: x-large; " class="full-width" required>
    <mat-select [(value)]="selectedOSSid" (selectionChange)="selectOSS($event)" name="selectoss">

      <mat-option *ngFor="let _oss of ossList" [value]="_oss.id">
        {{_oss.title}}<span *ngIf="_oss.is_tsn_member" style="margin-left: 10px; color: gray"> [членов ТСН]</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <h2 style="text-align: center;margin-top: -20px;  margin-bottom: 0px;">Решение</h2>
  <ng-container *ngIf="bulletin && bulletin.oss">
      <h3 *ngIf="bulletin.oss.is_tsn_member==0" style=" font-weight: bold;   margin-top: 0px;   margin-bottom: 0px; text-align: center;">собственника помещения в многоквартирном доме</h3>
      <h3 *ngIf="bulletin.oss.is_tsn_member==1" style=" font-weight: bold;  text-align: center;">члена ТСН в многоквартирном доме</h3>
    </ng-container>
  <h4 style="text-align: center;    margin-top: 0px;" *ngIf="bulletin && bulletin.vote_status">
    Статус: <span [style.color]="this.bulletin.vote_status.icon_color" style="margin-left: 0;"><mat-icon class="vertical-middle">{{this.bulletin.vote_status.icon}}</mat-icon>  <span class="vertical-middle"> {{this.bulletin.vote_status.title}}</span></span>
  </h4>

<!--  <table style="full-width">-->
<!--  <tr>-->
<!--    <td width="1000px">-->

<!--    </td>-->
<!--    <td>-->
<!--      <div style="display: inline-flex;  margin: 0 auto; ">-->


<!--      </div>-->
<!--    </td>-->
<!--    <td width="1000px">-->

<!--    </td>-->
<!--  </tr>-->
<!--  <tr>-->

<!--    <th colspan="3" >-->


<!--    </th>-->

<!--  </tr>-->
<!--  <tr>-->
<!--    <td width="1000px">-->

<!--    </td>-->
<!--    <td >-->
<!--    </td>-->
<!--    <td width="1000px">-->

<!--    </td>-->
<!--  </tr>-->
<!--</table>-->

  <div *ngIf="bulletin && bulletin.oss"  >Период проведения собрания c {{getDateTime(bulletin.oss.start_time)}} по {{getDateTime(bulletin.oss.end_time)}}</div>

  <div style="font-weight: normal;">Сведения о собственнике (работает поиск по номеру квартиры и по ФИО):</div>
  <mat-form-field class="example-full-width" style="width: 100%;  margin-top: -20px;font-family: inherit;" required>
    <input style="font-size:x-large; font-weight: bold; " matInput [matAutocomplete]="auto" [formControl]="searchvalue" name="searchvalue">
<!--    [displayWith]="displayFn"  [value]="searchValue"-->
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="humanSelected($event)">
      <mat-option *ngFor="let entry of humans" [value]="entry">
        <span>{{entry[1]}} - {{entry[3]}}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-hint [style.text-align]="'center'">(Ф.И.О. собственника помещения)</mat-hint>

  </mat-form-field>
    <br/>
<!--  <table style="width: 100%;">-->
<!--    <tr  style=" font-size:x-large; font-weight: bold; border-bottom: 1px black solid;  ">-->
<!--      <td style="border-bottom: 1px black solid; "><span>{{bulletin.human.fio}}</span></td>-->
<!--    </tr>-->
<!--  </table>-->
<!--  <div style="font-size:x-large; font-weight: bold; text-decoration: underline; width: 100%;">{{bulletin.human.fio}}</div>-->
  <div style="font-weight: normal;">Сведения о представителе собственника (при наличии):</div>
  <div style="font-weight: bold; ">
    <mat-form-field style="margin-top: -13px; margin-bottom: 5px; width: 100%;" class="example-full-width">
      <textarea matInput [(ngModel)]="doverka" name="doverka" rows="1" (focusout)="saveDoverka()"></textarea>
      <mat-hint [style.text-align]="'center'"> (Ф.И.О. представителя, сведения о документе, подтверждающем его полномочия)</mat-hint>
    </mat-form-field>
  </div>
  <br/>
  <div style="font-weight: normal;">Сведения о документах, подтверждающих право собственности лица, участвующего в голосовании:</div>
  <table style="width: 100%;">
    <ng-container *ngIf="bulletin && bulletin.owns">
      <tr *ngFor="let own of bulletin.owns"  style=" font-size:x-large; font-weight: bold; border-bottom: 1px black solid;  ">
        <td style="border-bottom: 1px black solid;  "><span>{{own.reg_number}}</span></td><td  style="border-bottom: 1px black solid; width: 100px;" ><span style="font-weight: normal">({{getDateTime(own.reg_date)}})</span></td>
      </tr>
    </ng-container>
    <tr *ngIf="bulletin && bulletin.human && bulletin.human.id" style=" font-size:x-large; font-weight: bold; border-bottom: 1px black solid; ">
      <td style="border-bottom: 1px black solid;  " colspan="2">
        <button mat-button color="primary" style="width: 100%;" [routerLink]="['/edit-human-owns']"  [queryParams]="{ human_id: humanId }">
        <mat-icon>business</mat-icon>
          <span>Редактировать собственности</span></button>
      </td>
    </tr>
  </table>
<br/>
  <div>Сведения о помещениях</div>
  <table class="rooms" style="width: 100%;  border-collapse: collapse; " cellspacing="0" cellpadding="5" border="1">
    <tr>
      <th>
        №<br/>
        Помещения
      </th>
      <th>
        Общая<br/>
        площадь<br/>
        помещения
      </th>
      <th>
        Доля<br/>
        собственности
      </th>
      <th>
        Площадь<br/>
        дома
      </th>
      <th>
        Количество<br/>
        голосов
      </th>
    </tr>
    <ng-container *ngIf="bulletin && bulletin.owns">
      <tr *ngFor="let own of bulletin.owns"  style=" font-size:large; font-weight: bold; border-bottom: 1px black solid;  " name="table2">
      <td>
          {{own.owner_title}}
      </td>
      <td>
          {{(own.room__square ).toFixed(2)}} м2
      </td>
      <td>

          {{own.owner_type_title}}  <span *ngIf="own.owner_type!='С'">{{own.own_part_title}}</span>
      </td>
      <td *ngIf="bulletin.owns[0] == own" [attr.rowspan]="bulletin.owns.length" name="td1">
          {{bulletin.home_square}}
      </td>
      <td *ngIf="bulletin.owns[0] == own" [attr.rowspan]="bulletin.owns.length" name="td2">
        {{(bulletin.own_square ).toFixed(2)}} м2<br/>
        {{(bulletin.own_volume * 100).toFixed(2)+'%'}}
      </td>
    </tr>
    </ng-container>

  </table>
  <div>
    <b>С</b> - совместная; <b>Д</b> - долевая; <b>Л</b> - личная (или "собственность");
  </div>
<!--  <div  *ngFor="let own of bulletin.owns"  style=" font-size:x-large; font-weight: bold; text-decoration: underline; ">-->
<!--    {{own.reg_number}} ({{getDateTime(own.reg_date)}})-->
<!--  </div>-->
  <mat-checkbox class="example-margin" [(ngModel)]="verbose" name="verbose-qwsts">раскрыть вопросы</mat-checkbox>
  <table class="full-width" border="1" cellpadding="5">
    <tr>
      <th style="width: 50px;">
        №
      </th>
      <th>
        Короткое название вопроса
      </th>
      <th style="width: 50px;">
        ЗА
      </th>
      <th style="width: 50px;">
        ПРОТ.
      </th>
      <th style="width: 50px;">
        ВОЗД.
      </th>
      <th style="width: 50px;">
        БРАК
      </th>
    </tr>
    <ng-container *ngIf="bulletin && bulletin.qwsts">
    <tr *ngFor="let question of bulletin.qwsts" [className]="question.type" >
      <th style="vertical-align: top;"> {{question.number}}</th>
      <td [colSpan]="question.type == 'header'?5:1">  <!-- назвние-->
        <div [className]="question.type">
          <div style="font-weight: bold; font-size: x-large;">{{question.title}}</div>
          <ng-container *ngIf="verbose" >
            <div *ngIf="question.type != 'sub-question'" >{{question.agenda}}</div>
            <div style="font-style: italic;" *ngIf="question.type == 'sub-question' || question.type == 'question'" >{{question.decision}}</div>
          </ng-container>
        </div>
      </td>
      <ng-container *ngIf="question.type != 'header'" >
        <td colspan="4" style="padding: 0">
          <ng-container *ngIf="!question.answer">
            <button class="answer-button color-for" mat-flat-button (click)="setAnswer(question.id, bulletin.vote.id, 'f')">ЗА</button>
            <button class="answer-button color-against"  mat-flat-button (click)="setAnswer(question.id, bulletin.vote.id, 'a')">ПРОТ</button>
            <button class="answer-button color-abstained"  mat-flat-button (click)="setAnswer(question.id, bulletin.vote.id, 'r')">ВОЗД</button>
            <button class="answer-button color-rejection"  mat-flat-button (click)="setAnswer(question.id, bulletin.vote.id, 'd')">БРАК</button>
          </ng-container>
          <button *ngIf="question.answer == 'f'" class="answer-button reset-answer-button color-for" mat-flat-button  (click)="setAnswer(question.id, bulletin.vote.id, '')">ЗА<br/><span class="answer-user">{{question.user}}</span></button>
          <button *ngIf="question.answer == 'a'" class="answer-button reset-answer-button color-against" mat-flat-button (click)="setAnswer(question.id, bulletin.vote.id, '')">ПРОТИВ<br/><span class="answer-user">{{question.user}}</span></button>
          <button *ngIf="question.answer == 'r'" class="answer-button reset-answer-button color-abstained" mat-flat-button  (click)="setAnswer(question.id, bulletin.vote.id, '')">ВОЗДЕРЖАЛСЯ<br/><span class="answer-user">{{question.user}}</span></button>
          <button *ngIf="question.answer == 'd'" class="answer-button reset-answer-button color-rejection" mat-flat-button  (click)="setAnswer(question.id, bulletin.vote.id, '')">БРАК<br/><span class="answer-user">{{question.user}}</span></button>
        </td>
      </ng-container>

    </tr>
    </ng-container>
  </table>


  <div *ngIf="!locked || locked === 'tenant'">
    <br>

    <br>
    <button class="full-width" mat-raised-button color="primary" (click)="lockBulletin()">Закрыть решение</button>
    <br>
  </div>

  <div *ngIf="locked">

    <br>
    <br>
    <br>
    <button class="full-width" mat-raised-button color="primary" (click)="unlockBulletin()">Открыть решение</button>

    <br>
  </div>

  <button mat-raised-button *ngIf="locked"  (click)="main.navigateTo(generateBulletinLink( bulletin.human.guid, [bulletin.oss.guid], true), '_blank')"  matTooltip="Открыть / скачать бюллетень" >
    <mat-icon style="color: #0400ff; vertical-align: bottom;">print</mat-icon>  Открыть / скачать бюллетень
  </button>

  <app-files [ownerName]="'vote'" [ownerId]="voteId"></app-files>
  <br><br><br><br><br><br><br><br><br><br><br><br>
</form>
