
export interface ContextOssTimePeriodItem {
  time: string;
  date: string;
}

export interface ContextOssTimePeriod {
  from: ContextOssTimePeriodItem;
  to: ContextOssTimePeriodItem;
}

export interface ContextOssTimes {
  show: ContextOssTimePeriod;
  back: ContextOssTimePeriod;
}

export interface ContextOssQuestion {
  Type: string;
  QuestionNumber: string;
  QuestionAgenda: string;
  QuestionDecision: string;
  AnswerIndex: number;

}

export interface ContextOssRoom {
  RoomName: string;
  RoomSquare: number;
  OwnSquare: number;
  RoomOwn: string;
  EGRN: string;
  EGRNDate: string;

  // RoomName: 6
  // RoomSquare: 60.5
  // RoomOwn: совместная
  // EGRN: '47:07:1044001:38198-47/010/2017-2'
  // EGRNDate: '10.02.2017'
}

export interface QrOssRoom {
  RN: string;
  RS: number;
  OS: number;
  RO: string;
  E: string;
  ED: string;

  // RoomName: 6
  // RoomSquare: 60.5
  // RoomOwn: совместная
  // EGRN: '47:07:1044001:38198-47/010/2017-2'
  // EGRNDate: '10.02.2017'
}

export interface FullContext {
  definition: any;
  implements: Context;
  arguments: Context;
}

export interface QrData {
  OG: string;
  HG: string;
  D: string;
  TOS: number;
  RS: QrOssRoom[];
  CD: string;
  OW: string;
  DL: string;
  ANS: string;
}

export interface Arguments {
  qrValue: string;
  OssGuid: string;
  HumanGuid: string;
  LongDate: string;
  TotalOwnSquare: number;
  Rooms: ContextOssRoom[];
  WebCode: string;
  Owner: string;
  Delegate: string;
  Answers: string;
}

export interface Context extends Arguments{

  OssGuid: string;
  Address: string;
  HomeSquare: number;
  TotalOwnSquare: number;
  Time: ContextOssTimes;
  Title: string;
  OssForm: string;
  Rules: Rule[];
  Questions: ContextOssQuestion[];
}

export class Rule {
  public Content = '';
}

export class Result {
  public result: string;
  public exception: string;
  public data: any;


}

export class Oss {
  public id = 0;
  public guid = '00000000-0000-0000-0000-000000000000'
  public title = null;
  public description = "";
  public start_time:string = null;
  public end_time:string = null;
  public show_start:string = null;
  public show_end:string = null;
  public back_start:string = null;
  public back_end:string = null;
  public is_tsn_member = null;
  public selected_homes = "";
  public bulletin = new Bulletin();
  public is_publish = false;
  public is_closed = false;
  oss_form = '';

}

export class Bulletin {
  public id = 0;
  //public header_date = null;
  //public address = '';
  public questions: Question[] = [];

  definition = '';
  context = '';
  public bulletin_file_id = 0;
  public agenda_file_id = 0;
  public protocol_file_id = 0;
  // sub_header = '';

}

export class Question {

  public id = 0;
  public bulletin_id = 0;
  public type = 'question';
  public title = '';
  public short_question = '';
  public description = '';
  public agenda = '';
  public decision = '';
  public order = 0;
  public number = '';
  public quorum = 0.5;
  public quorum_title = "50%";

}

export interface IHome {
  name: string;
  address: string;
  ppd_square: number;
}

export class Home implements IHome{
  address: string;
  name: string;
  ppd_square: number;

}
