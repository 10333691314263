import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {MainService, UserConfig} from './main.service';
import {main} from '@angular/compiler-cli/src/main';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from './_models/user';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from "@angular/material/snack-bar";


@Injectable()
@Injectable({ providedIn: 'root' })
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public returnUrl: string;
  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  // текущий JWT токен
  public token: string;


  // время окончания жизни токена
  public tokenExpires: Date;

  // логин пользователя
  public username: string;

  // сообщения об ошибках авторизации
  public errors: any = [];

  constructor(private route: ActivatedRoute, private http: HttpClient, public main: MainService, private router: Router,
              private snackBar: MatSnackBar) {

    let token = JSON.parse(localStorage.getItem('currentUser'));
    console.log(token);
    this.currentUserSubject = new BehaviorSubject<User>(token);
    this.currentUser = this.currentUserSubject.asObservable();

  }

  //

  get isLogin() {
    // const token = localStorage.getItem('c300token');
    if (this.token != null) {
      return true;
    } else {
      return false;
    }
  }
  // login(username, password) {
  //   console.log('login');
  //   return this.http.post<any>(`${this.main.server}/api-token-auth/`, {username, password})
  //     .pipe(map(user => {
  //       // login successful if there's a jwt token in the response
  //       if (user && user.token) {
  //         // store user details and jwt token in local storage to keep user logged in between page refreshes
  //         localStorage.setItem('currentUser', JSON.stringify(user));
  //         this.currentUserSubject.next(user);
  //         this.router.navigate([this.returnUrl]);
  //       }
  //
  //       return user;
  //     }));
  // }


  // используем http.post() для получения токена
  public login(username, password) {
    console.log('login');
    this.http.post(this.main.server + '/api-token-auth/', {username, password}, this.main.httpOptions).subscribe(
      data => {
         if(this.updateData(data['token'])){
          //console.log(this.route.snapshot.queryParams);

        // let user = (<User> Object.assign(new User(), data));
        // if (user ) {
        //   // store user details and jwt token in local storage to keep user logged in between page refreshes
        //   localStorage.setItem('currentUser', JSON.stringify(user));
        //   this.currentUserSubject.next(user);
        //   this.router.navigate([this.returnUrl]);
        // }
         this.router.navigate([this.returnUrl]);
         }

      },
      err => {
        this.errors = err['error'];
        this.snackBar.open('Неудачный вход', 'Ок', {
          verticalPosition:'top',
          panelClass:'error-panel',
          duration: 5000,
        });
      }
    );
  }

  // обновление JWT токена
  // public refreshToken() {
  //   console.log('refresh');
  //   this.http.post(this.main.server + '/api-token-refresh/', JSON.stringify({token: this.token}), this.main.httpOptions).subscribe(
  //     data => {
  //       this.updateData(data['token']);
  //     },
  //     err => {
  //       this.errors = err['error'];
  //     }
  //   );
  // }

  public logout() {
    console.log('logout');
    this.token = null;
    this.tokenExpires = null;
    this.username = null;
    this.main.username = this.username;
    //this.updateData(this.token);
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
    //this.router.navigate([this.router.url.split('?',2)[0]], {queryParams:this.route.snapshot.queryParams}, );
  }

   private updateData(token):boolean {
     //alert('!')
     this.token = token;
     if(token) {
       this.errors = [];
       const token_parts = this.token.split(/\./);
       const token_decoded = JSON.parse(window.atob(token_parts[1]));
       this.tokenExpires = new Date(token_decoded.exp * 1000);
       this.username = token_decoded.username;
       this.main.username = this.username;
       if (this.tokenExpires < new Date())
       {
         this.token = null;
         this.errors = [];
         this.tokenExpires = null;
         this.username = null;
         this.main.username = this.username;
         console.log('remove user');
         localStorage.removeItem("currentUser");
         this.main.onLoggedOut();
         this.currentUserSubject.next(token);
         return false;
       }
       let user = new User()
       user.firstName = "First Name";
       user.id = -1;
       user.lastName = "Last Name";
       user.username = this.username;
       user.token = token;
      // localStorage.setItem("jwt", this.token);
       console.log('add user');
       localStorage.setItem('currentUser', JSON.stringify(user));
       this.currentUserSubject.next(user);
       this.main.onLoggedIn();
       return true;
     }
     else{
       console.log('remove user');
       localStorage.removeItem("currentUser");
       this.main.onLoggedOut();
       return false;
     }
   }









}
