<h1>ВНУТРЕННИЕ</h1>
<h2>Реестр собственников</h2>
<p>Выберите фильтрацию и сортировку, а затем нажмите скачать</p>

<mat-form-field appearance="fill">
  <mat-label>Фильтр</mat-label>
  <mat-select [(ngModel)]="reestrOwnersMembers">
    <mat-option value="true">Только члены ТСН</mat-option>
    <mat-option value="false">Все собственники</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="fill">
  <mat-label>Сортировка</mat-label>
  <mat-select [(ngModel)]="reestrOwnersOrders">
    <mat-option value="room">Помещения</mat-option>
    <mat-option value="fio">ФИО</mat-option>
  </mat-select>
</mat-form-field>
<br>
<button mat-raised-button color="primary"
        (click)="generateDoc({type: 'reestrOwners', tsnMembers: reestrOwnersMembers==='true', order: reestrOwnersOrders })">
  <mat-icon>cloud_download</mat-icon>
  <span>Скачать</span>
</button>
<hr>
<h1>ГИС ЖКХ</h1>
<h2>ФАЙЛА ИМПОРТА СВЕДЕНИЙ О ЧЛЕНАХ ТСЖ, КООПЕРАТИВА</h2>
<p><mat-icon color="warn">warning</mat-icon><span>Загрузка может быть продолжительной!</span></p>
<button mat-raised-button color="primary"
        (click)="generateDoc({type: 'tsnMembersGisGKH'})">
  <mat-icon>cloud_download</mat-icon>
  <span>Скачать</span>
</button>

<br><br>
<br><br><br><br><br><br><br><br><br><br><br><br><br>
