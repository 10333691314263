<div style="">
<!--  <h2 *ngIf="session && session.human.fio">{{ session.human.fio}}</h2>-->
<!--  <h2 style="text-align: center; vertical-align: center"><mat-icon style="vertical-align: sub; font-size: 40px; margin-right: 15px;">business</mat-icon>Редактирование собственностей</h2>-->
  <div style="font-weight: normal;">Сведения о собственнике (работает поиск по номеру квартиры и по ФИО): </div>
  <mat-form-field class="example-full-width" style="width: 100%;  margin-top: -20px;font-family: inherit;">
    <input style="font-size:x-large; font-weight: bold; " matInput [matAutocomplete]="auto" [formControl]="searchvalue" name="searchvalue">
    <!--    [displayWith]="displayFn"  [value]="searchValue"-->
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="humanSelected($event)">
      <mat-option *ngFor="let entry of humans" [value]="entry">
        <span>{{entry[1]}} - {{entry[4]}}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-hint [style.text-align]="'center'">(Ф.И.О. собственника помещения)</mat-hint>

  </mat-form-field>
  <button  mat-raised-button color="primary" [routerLink]="['/add-human']" ><mat-icon>person_add</mat-icon><span>Добавить жителя</span> </button>
  <h3>Помещения в собственности</h3>
  <mat-error *ngIf="server_message">{{server_message}}</mat-error>
  <mat-error *ngIf="error_message"><mat-icon>warning</mat-icon>{{error_message}}</mat-error>
  <div style="width: 100%; overflow-x: auto;">
  <table  class="search_result full_width"  border="1" cellpadding="5">
    <tr>
      <th style="width: 145px;">Название<hr/>Помещение</th>
      <th style="width: 50px;">S м2</th>
      <th style="width: 50px;">Тип собств.</th>
      <th>Доля <hr/>
      s м2</th>
      <th  style="width: 50px;">Документ подтв. собственность<hr/>Дата регистрации
      </th>
    </tr>
    <ng-container *ngIf="session && session.rel_actuals">
      <tr *ngFor="let own of session.rel_actuals"   [className]="own.to_delete?'delete':own.to_create?'create':''">
        <td>
          <ng-container *ngIf="own.id>0">
            <mat-slide-toggle
              class="example-margin"
              color="Warn"
              [(ngModel)]="own.to_delete"
              name="{{ 'delete_action' + '_' + own.id}}"
              (change)="sessionChanged()"
              matTooltip="Кликните для установки/снятия отметки об изъятии помещения из собственности"
             >
              Изъять
            </mat-slide-toggle>
          </ng-container>
          <ng-container *ngIf="own.id==0 && own.to_create">
            <button mat-icon-button color="warn" (click)="removeCreated($event, own)" ><mat-icon>delete</mat-icon> удалить</button>
          </ng-container>
          <mat-form-field class="example-full-width" style="font-weight: bold; width: 145px"  matTooltip="Задайте наименование доли помещения. Обычно оно совпадает с номером помещения. В случае паркинга можно указать номер парковочного места." >
            <input matInput [(ngModel)]="own.owner_title" (change)="sessionChanged()" name="{{ 'owner_title' + '_' + own.id}}">
          </mat-form-field><br/>
          <button mat-icon-button  (click)="showOtherOwners(own)"><mat-icon style="cursor: pointer; margin-right: 10px;" [matBadge]="own.rel_owns.length+1" matBadgeColor="primary"  matTooltip="Кликните для быстрого отображения всех собственников помещения">group</mat-icon></button>
          <button mat-button color="primary" [routerLink]="['/room']"  [queryParams]="{ room_id: own.room__id }"  matTooltip="Кликните для просмотра и редактирования данных о помещении"><mat-icon>meeting_room</mat-icon>{{own.room__name}}</button>
        </td>
        <td>
          {{own.room__square}}
        </td>
        <td>
          <mat-form-field class="owner_type_selector" style="">
            <mat-select [(value)]="own.owner_type" (selectionChange)="selectOwnType($event, own)" >
              <mat-option value="Л">Личная</mat-option>
              <mat-option value="С">Совместная</mat-option>
              <mat-option value="Д">Долевая</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
<!--          <div style=" overflow-y: auto;max-height: 50px; overflow-clip: true ;  overflow-scrolling: touch; line-height: 9px; font-size: 9px;"><span *ngFor="let ro of own.rel_owns" >{{ro[1]}}, </span></div>-->
          <div>
          <mat-form-field class="example-full-width" style="font-weight: bold; width: 50px; margin-top: -15px; margin-bottom: -15px;" >
            <input matInput [(ngModel)]="own.own_part_title" (keyup)="ownPartTitleChanged($event, own)" (change)="sessionChanged()" name="{{ 'own_part_title' + '_' + own.id}}"
                   matTooltip="Введите долю помещения. Используйте два целых числа разделенных знаком дроби. Площадь пересчитается автоматически">
          </mat-form-field><br/>
            {{ own.own_part_square.toFixed(2) }}
          </div>
       </td>
        <td>
          {{own.room__cadastral_number}}
          <mat-form-field class="example-full-width reg-document-editor" >
            <textarea  class="reg-document-editor-field" matInput  rows="1" [(ngModel)]="own.reg_number"  name="{{ 'reg_number'+ '_' + own.id}}" (change)="sessionChanged()"
                       matTooltip="Данные документа подтверждающего собственность"></textarea>
          </mat-form-field>

          <mat-form-field style="margin-bottom: -15px;">
            <input matInput [matDatepicker]="picker"  [(ngModel)]="own.reg_date" name="{{ 'reg_date' + '_' + own.id}}" (change)="sessionChanged()"  matTooltip="Дата регистрации согласно документу о собственности">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="multi-year" [startAt]="own.reg_date"></mat-datepicker>
          </mat-form-field>
      </tr>

    </ng-container>
  </table>
  </div>
  <h4>Добавить помещение:</h4>
  <mat-form-field class="example-full-width" style="width: 100%;  font-family: inherit; ">
    <input style="font-size:x-large; font-weight: bold; " placeholder="Введите номер помещения или кадастровый номер" matInput [matAutocomplete]="room_auto" [formControl]="room_searchvalue" name="room_searchvalue">
    <mat-autocomplete #room_auto="matAutocomplete" (optionSelected)="roomSelected($event)">
      <mat-option *ngFor="let entry of free_rooms" [value]="entry">
        <span><span style="font-weight: bold; margin-right: 10px;">{{entry.address}}, пом: {{entry.name}}</span> <span style="font-style: italic; margin-right: 10px;">кад.№ {{entry.cadastral_number}}</span> <span style="font-weight: bold; margin-right: 10px;">своб: {{entry.free_square.toFixed(2)}} м2</span></span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <button mat-raised-button color="Primary" [disabled]="!selected_room" (click)="addRoom()">Добавить помещение</button>
<!--  <button mat-raised-button color="warn" (click)="breakSession()">Сбросить не сохраняя</button>-->
  <button mat-raised-button color="" (click)="saveSession()">Сохранить</button>
</div>
<!--<h4>Помощь</h4>-->
<!-- - Редактировать собственности может одновременно только один человек и одного собственника. Сессия редактирования сохраняется в базе,-->
<!-- и при попытке начать редактирование другого собственника, восстановится предыдущая сессия для продолжения.-->
<!--Если вы не хотите продолжать редактирование, нажмите кнопку "Сбросить не сохраняя", тогда сессия редактирования уничтожится-->
<!--и запустится новая, которую вы хотели начать<br/>-->
<!-- - В поле сведений о собственнике сверху можно написать номер помещения или часть ФИО фладельца и через секунду будет произведен поиск<br/>-->
<!-- - В левой колонке таблицы отображается иконка с количеством владельцев помещения (<mat-icon [matBadge]="33" matBadgeColor="primary" >group</mat-icon> ), если по ней кликнуть, то откроется диалоговое окно со списком всех владельцев.<br/>-->
<!-- - После ввода доли, площадь рассчитывается автоматически, одновременно проверяется чтобы в одном помещении не была превышена суммарная площадь владений.<br/>-->
<!-- - В помощь правильному расчету доли, можно посмотреть список всех собственниклв помещения.<br/>-->
<!-- - При наличии более чем одного собственника, установить тип собственности "личная" не получится<br/>-->
<!-- - При установке собственности "Личная" доля автоматически выставляется в значение "1", а площадь пересчитывается<br/>-->
<!-- - В случае собственности по договору ДДУ, вместо регистрационного номера можно указать номер договора ДДУ<br/>-->

